export const formatUrl = (url) => {
  if (!url) return "";
  let newUrl = url.trim();
  if (!url.includes("https") && !url.includes("http")) {
    return "https://" + newUrl;
  }
  return newUrl;
};

export const validatePhoneWithCountryCode = (country_phone, phone_number) => {
  if (!country_phone) return "Please select country code";
  let checkPhone = phone_number.slice(
    country_phone.length,
    phone_number.length
  );
  if (checkPhone.length < 8) {
    return "Please enter a valid phone number";
  }

  if (checkPhone.length > 13) {
    return "Please enter a valid phone number";
  }
  return "";
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export const formatDate = (date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-") +
    " " +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      // padTo2Digits(date.getSeconds()),
    ].join(":")
  );
};

export const logoutFbApi = () => {
  try {
    window.FB.api("/me/permissions", "delete", null, () => window.FB.logout());
  } catch (err) {
    console.log("facebook logout err", err);
  }
};

export const getUserSubscriptionPlan = (planId, plans) => {
  const planInfo = plans.find((plan) => plan.id === planId);

  return planInfo;
};
