const Joi = require("joi");
const AddEditTutorialSchema = Joi.object({
  id: Joi.allow(null),
  title: Joi.string().min(3).max(50).required().messages({
    "string.empty": `Please enter title`,
    "string.min": `Title must be minimum 3 characters!`,
    "string.max": `Title must be maximum 50 characters!`,
  }),
  url: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),
});

export default AddEditTutorialSchema;
