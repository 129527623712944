import React from "react";
import { Image, Modal } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import classes from "../../../assets/styles/PageComponentsStyles/Contact/GridView.module.css";
import dummyImage from "../../../assets/images/global_icons/dummyimage.svg";
import baseURL from "../../../utils/constants/baseUrl";
import { MdContentCopy } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { useState, useRef } from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { useAtom } from "jotai";
import { mobileViewCheck } from "../../../utils/store";
import ShareModal from "../../Modals/ShareModal";

const GridView = ({ contacts, handleDelete }) => {
  let dummyDiscription = "lorem ipsum #1";
  const [shareOpen, setShareOpen] = useState(false);
  const [uuid, setUuid] = useState("");
  const [show, setShow] = useState(false);
  const [mobileView] = useAtom(mobileViewCheck);

  const target = useRef(null);

  const handleShareOpen = (contact_uuid) => {
    setShareOpen(true);
    setUuid(contact_uuid);
  };

  const handleShareClose = () => {
    setShareOpen(false);
    setUuid("");
    setShow(false);
  };

  return (
    <>
      <div className={classes.gridView}>
        {contacts.length === 0 && (
          <p style={{ textAlign: "center" }}>No record found</p>
        )}

        {contacts.map((contact) => {
          return (
            <div
              className={classes.gridViewCard}
              key={`contactGrid${contact.id}`}
            >
              <div className={classes.gridViewContentWrapper}>
                <Image
                  src={
                    `${baseURL.PUBLIC_URL}${contact.profile_image}` ||
                    dummyImage
                  }
                  roundedCircle={true}
                  width="70px"
                  height="70px"
                  onError={(e) => {
                    e.currentTarget.src = dummyImage;
                  }}
                  loading="lazy"
                  decoding="async"
                />

                <div className={classes.action}>
                  <div className="dropdown">
                    <button
                      className="btn customActionDropdown dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <BsThreeDotsVertical className={classes.actionIcon} />
                    </button>
                    <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
                      {/* <li>
                        <Link className="dropdown-item" to="#">
                          View
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          className="dropdown-item"
                          to={`/contacts/viewcontact/${contact.id}`}
                        >
                          View
                        </Link>
                      </li>
                      {contact.is_manual ? (
                        <li>
                          <Link
                            className="dropdown-item"
                            to={`/contacts/${contact.contact_id}`}
                          >
                            Edit
                          </Link>
                        </li>
                      ) : null}

                      <li>
                        <span
                          className="dropdown-item"
                          onClick={() => handleShareOpen(contact.uuid)}
                        >
                          Share
                        </span>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="#"
                          style={{ color: "#E35252" }}
                          onClick={() => handleDelete(contact.contact_id)}
                        >
                          Delete
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={classes.gridViewContent}>
                  <h6 style={{ fontSize: "16px", fontWeight: "400px" }}>{`${
                    contact.first_name ? contact.first_name : ""
                  } ${contact.last_name ? contact.last_name : ""}`}</h6>
                  <p>{contact.description || ""}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {shareOpen && (
        <ShareModal
          target={target}
          shareOpen={shareOpen}
          handleShareClose={handleShareClose}
          setShowCopyPopup={setShow}
          functionType="contact"
          functionUuid={uuid}
        />
      )}

      <Overlay
        target={target.current}
        show={show}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default GridView;
