import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import classes from "../../assets/styles/CustomURLModal.module.css";
import { MdClose } from "react-icons/md";
import SecondLoader from "../../components/SecondLoader";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../pages/CheckoutForm";
const SubscriptionModal = ({
  show,
  stripePromise,
  handleCloseModal,
  selectedPackage,
  elementsOptions,
}) => {
  const [errors, setErrors] = useState({});
  const [loading, setIsLoading] = useState(false);

  // Handle Update
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleCloseModal();
          setErrors({});
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>Card Details</span>
          <div className="modalGradientCircle"></div>
          <MdClose
            onClick={() => {
              handleCloseModal();
              setErrors({});
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>
        {loading ? (
          <SecondLoader />
        ) : (
          <>
            <Modal.Body>
              <div style={{ marginTop: "-140px" }}>
                <>
                  <Elements stripe={stripePromise} options={elementsOptions}>
                    <CheckoutForm
                      selectedPackage={selectedPackage}
                      handleCloseModal={handleCloseModal}
                    />
                  </Elements>
                </>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

export default SubscriptionModal;
