import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { post } from "../../../server";
import { SEND_NOTIFICATION } from "../../../utils/constants/apiUrl";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../../components/Loader";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
import AddEditPriceSchema from "../../../utils/SchemasValidation/AddEditPriceSchema";
import ValidateSchema from "../../../helpers/ValidateSchema";
import Button from "react-bootstrap/Button";

let emptyObj = {
  title: "",
  description: "",
};

const SendNotification = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [notification, setNotification] = useState(emptyObj);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNotification({ ...notification, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(notification, AddEditPriceSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);

    let { status, message } = await post(
      SEND_NOTIFICATION,
      notification,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);
    if (status) {
      setNotification(emptyObj);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div>
          <h6> Send Notifications </h6>
          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className="row gx-5 gy-3">
              <div className="col-12">
                <label htmlFor="">Title</label>
                <input
                  type="text"
                  placeholder="Notification Title"
                  name="title"
                  value={notification.title}
                  className={
                    errors?.title ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.title || ""}</p>
              </div>
              <div className="col-12">
                <label htmlFor=""> Description </label>
                <textarea
                  name="description"
                  id=""
                  cols="100"
                  rows="5"
                  value={notification.description}
                  placeholder="Add Description"
                  onChange={handleChange}
                  className={
                    errors?.description
                      ? "globalTextArea errorInput"
                      : "globalTextArea"
                  }
                />
                <p className="errorText">{errors?.description || ""}</p>
              </div>
            </div>
            <div className="formBottom">
              <Button
                className="submitButton"
                type="submit"
                onClick={handleSubmit}
              >
                Send Notification
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <style type="text/css">
        {`
        label {
          display: block;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 10px;
        }
        }
	`}
      </style>
    </>
  );
};

export default SendNotification;
