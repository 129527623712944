import { useNavigate } from "react-router-dom";
import classes from "../assets/styles/EmptyListingBanner.module.css";
import heading from "../assets/images/home_page_images/heading.svg";
import dashedArrow from "../assets/images/home_page_images/dashedArrow.svg";
import useFunctionsCount from "../hooks/useFunctionsCount";
import functionTypes from "../utils/constants/functionTypes";
import showToast from "../helpers/showToast";

const EmptyListingBanner = ({
  headLineText,
  title,
  onClick,
  link,
  showAdd = true,
  type = null,
}) => {
  const { compareLimits, updateCount } = useFunctionsCount();
  const navigate = useNavigate();

  const handleClick = (link) => {
    const res = handleShowCustomUrlModal(type);
    if (res)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    navigate(link);
  };

  const handleShowCustomUrlModal = (type) => {
    if (type == "card") return compareLimits(functionTypes.CARD);

    return false;
  };

  return (
    <>
      <div className={classes.infoWrapper}>
        <div className={classes.contentWrapper}>
          <img src={heading} alt="" />
          <div>
            <h6>Tips</h6>
            <p>
              {`You haven't created any ${headLineText} yet, in order to get started click the ${title}  button below`}
            </p>
          </div>
        </div>
      </div>

      {showAdd ? (
        <div className={classes.arrowImgWrapper}>
          <div className={classes.arrowImg}>
            <img src={dashedArrow} alt="" />
          </div>

          <div className={classes.createBtnWrapper}>
            {link ? (
              <button onClick={() => handleClick(link)}>{title}</button>
            ) : (
              // <Link to={link}>{title}</Link>
              <button onClick={onClick}>{title}</button>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default EmptyListingBanner;
