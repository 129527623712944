import cardfunctionlogo from "../../assets/images/function_page_icons/cardfunctionlogo.svg";
import venmologo from "../../assets/images/function_page_icons/venmologo.svg";
import cashapplogo from "../../assets/images/function_page_icons/cashapplogo.svg";
import emergencycontactlogo from "../../assets/images/function_page_icons/emergencycontactlogo.png";
import customurlslogo from "../../assets/images/function_page_icons/customurlslogo.svg";
import paymenticon from "../../assets/images/function_page_icons/paymenticon.svg";
import UploadFilesIcon from "../../assets/images/function_page_icons/UploadFilesIcon.svg";

const FunctionCreateCardsData = [
  {
    id: 1,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, #22A081 0%, rgba(34, 160, 129, 0) 100%)",
    image: cardfunctionlogo,
    title: "Digital Card",
    description: "Create Your Personal & Businesss Card",
    url: "/function/cards",
  },
  // {
  //   id: 2,
  //   bgColor:
  //     "radial-gradient(52.22% 52.22% at 50% 39.76%, #0288FF 0%, rgba(2, 136, 255, 0) 100%)",
  //   image: venmologo,
  //   title: "Venmo",
  //   description: "Link Your Venmo Account",
  //   url: "/function/venmoListing",
  // },
  {
    id: 3,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, #00D632 0%, rgba(0, 214, 50, 0) 100%)",
    image: paymenticon,
    title: "Payment Service",
    description: "Link Your Payment Service Account",
    url: "/function/paymentListing",
  },
  {
    id: 4,
    bgColor:
      " radial-gradient(52.22% 52.22% at 50% 39.76%, #22A081 0%, rgba(34, 160, 129, 0) 100%)",
    image: emergencycontactlogo,
    title: "Emergency Contact",
    description: "For Emergency Purpose",
    url: "/function/emergencycontacts",
  },
  {
    id: 5,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, #22A081 0%, rgba(34, 160, 129, 0) 100%) ",
    image: customurlslogo,
    title: "Custom URL’s",
    description: "Create Your Custom URL’s",
    url: "/function/customurlslisting",
  },
  {
    id: 6,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, #22A081 0%, rgba(34, 160, 129, 0) 100%) ",
    image: UploadFilesIcon,
    title: "File Upload URL",
    description: "Create Your File",
    url: "/function/fileupload",
  },
];

export default FunctionCreateCardsData;
