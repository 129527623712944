import React from "react";
import FunctionCard from "../../components/PageComponents/Function/FunctionCard";
import classes from "../../assets/styles/Pages/Function/Function.module.css";
import HeroSection from "../../components/HeroSection";
import FunctionCreateCardsData from "../../utils/data/FunctionCreateCardsData";
import { useParams } from "react-router-dom";
const Function = () => {
  const { teamId, userId } = useParams();

  return (
    <>
      <div className="mt-3">
        <h6 className={classes.title}>Functions</h6>
      </div>
      <div className={classes.mainWrapper}>
        <HeroSection />
      </div>

      <div className={classes.boxWrapper}>
        {FunctionCreateCardsData.map((card) => {
          return (
            <FunctionCard
              key={card.id}
              {...card}
              teamId={teamId}
              userId={userId}
            />
          );
        })}
      </div>
    </>
  );
};

export default Function;
