import React, { useState, useEffect } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Settings/Profile.module.css";
import SettingsInput from "../../Forms/SettingsInput";
import { TEAMDETAIL, SETTING } from "../../../utils/constants/apiUrl";
import { get, put } from "../../../server";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../Loader";
import jobIcon from "../../../assets/images/settings_page_icons/job.svg";
import showToast from "../../../helpers/showToast";
import ValidateSchema from "../../../helpers/ValidateSchema";
import TeamDetailSchema from "../../../utils/SchemasValidation/TeamDetailSchema";

const TeamDetail = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [teamDetail, setTeamDetail] = useState({ name: "" });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTeamDetail({ ...teamDetail, [name]: value });
  };

  // Team Detail
  const getTeamDetail = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      `${TEAMDETAIL}/${
        Object.keys(loggedUser).length !== 0 ? loggedUser.teams.id : null
      }`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setTeamDetail({ ...teamDetail, name: data.name });
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  useEffect(() => {
    getTeamDetail();
  }, []);

  const validateForm = () => {
    let newErrors = ValidateSchema(teamDetail, TeamDetailSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    let { status, message, data } = await put(
      `${TEAMDETAIL}/${
        Object.keys(loggedUser).length !== 0 ? loggedUser.teams.id : null
      }`,
      teamDetail,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    showToast(status, message);

    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div className={classes.profileWrapper}>
          <h6 className={classes.title}>Team Detail Info</h6>
          <div className={classes.bioDataWrapper}>
            <div className="row gx-5 gy-3">
              <div className="col-md-4 col-12">
                <SettingsInput
                  label="Team Name"
                  type="text"
                  placeholder="Team Name"
                  name="name"
                  value={teamDetail.name}
                  handleChange={handleChange}
                  icon={jobIcon}
                  error={errors.name}
                />
              </div>
            </div>
            <div className={classes.submitBtnWrapper}>
              <button type="button" onClick={handleSubmit}>
                Update Details
              </button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default TeamDetail;
