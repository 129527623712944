import React from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Home/AddNewCard.module.css";
import addnewcard from "../../../assets/images/home_page_images/addnewcard.svg";
import useFunctionsCount from "../../../hooks/useFunctionsCount";
import functionTypes from "../../../utils/constants/functionTypes";
import showToast from "../../../helpers/showToast";
import { useNavigate } from "react-router-dom";

const AddNewCard = ({ teamId, userId, team_id, user_id }) => {
  const { compareLimits, updateCount } = useFunctionsCount();
  const navigate = useNavigate();

  const handleNavigation = () => {
    const res = compareLimits(functionTypes.CARD);
    if (res)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );

    let link =
      teamId && userId
        ? `/team/${teamId}/teamMembers/member/${userId}/function/cardfunctions`
        : `/function/cardfunctions${
            team_id && user_id ? `?team_id=${team_id}&user_id=${user_id}` : ""
          }`;
    navigate(link);
  };

  return (
    <>
      <a onClick={handleNavigation}>
        <div className={classes.cardWrapper}>
          <img src={addnewcard} alt="" />
          <p>Add New Card</p>
        </div>
      </a>
    </>
  );
};

export default AddNewCard;
