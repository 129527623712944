import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { get, post, put, deleteRecord } from "../../../server";
import { ADMIN_USERS } from "../../../utils/constants/apiUrl";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../../components/Loader";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
import AdminUserSchema from "../../../utils/SchemasValidation/AdminUserSchema";
import ValidateSchema from "../../../helpers/ValidateSchema";
import Button from "react-bootstrap/Button";
import _ from "lodash";
import PhoneInput from "react-phone-input-2";
import { validatePhoneWithCountryCode } from "../../../utils/functions";
const AddEditAdminUser = () => {
  let emptyObj = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    country_phone: "",
    password: "",
    confirm_password: "",
  };

  const { adminId } = useParams();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [adminUser, setAdminUser] = useState(emptyObj);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAdminUser({ ...adminUser, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(adminUser, AdminUserSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;

    if (adminUser.phone_number.length > adminUser.country_phone.length) {
      let resultPhone = validatePhoneWithCountryCode(
        adminUser.country_phone,
        adminUser.phone_number
      );
      if (resultPhone) {
        setErrors({
          phone_number: resultPhone,
        });
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    if (adminId) {
      let payload = _.cloneDeep(adminUser);
      // for handling backend error
      if (!payload.password) delete payload.password;
      if (!payload.confirm_password) delete payload.confirm_password;

      if (payload.phone_number && payload.country_phone.length > 0) {
        payload.phone_number = payload.phone_number.slice(
          payload.country_phone.length,
          payload.phone_number.length
        );
      } else {
        payload.country_phone = "";
      }
      if (!payload.phone_number && payload.country_phone) {
        payload.country_phone = "";
      }

      let { status, message, data } = await put(
        ADMIN_USERS + `/${adminId}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        navigate("/adminUser");
      }

      showToast(status, message);
    } else {
      if (adminUser.phone_number && adminUser.country_phone.length > 0) {
        adminUser.phone_number = adminUser.phone_number.slice(
          adminUser.country_phone.length,
          adminUser.phone_number.length
        );
      } else {
        adminUser.country_phone = "";
      }

      if (!adminUser.phone_number && adminUser.country_phone) {
        adminUser.country_phone = "";
      }
      let { status, message, data } = await post(
        ADMIN_USERS,
        adminUser,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        navigate("/adminUser");
      }
      showToast(status, message);
    }
    setLoading(false);
  };

  const loadSingleAdminUser = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      ADMIN_USERS + `/${adminId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      data = { ...data, password: "", confirm_password: "" };

      if (!data.country_phone) data.country_phone = "";

      if (data.phone_number) {
        data.phone_number = `${data.country_phone}${data.phone_number}`;
      } else {
        // for handling null
        data.phone_number = "";
      }

      setAdminUser(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (adminId) loadSingleAdminUser();
  }, []);
  return (
    <>
      {!loading ? (
        <div>
          <h6> {adminId ? "Edit" : "Add"} Admin User</h6>
          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className={classes.inputsWrapper}>
              <div>
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={adminUser.first_name}
                  className={
                    errors?.first_name
                      ? "globalInput errorInput"
                      : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.first_name || ""}</p>
              </div>

              <div>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={adminUser.last_name}
                  className={
                    errors?.last_name ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.last_name || ""}</p>
              </div>

              <div>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={adminUser.email}
                  className={
                    errors?.email ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.email || ""}</p>
              </div>

              <div>
                {/* <input
                  type="number"
                  placeholder="Phone #"
                  name="phone_number"
                  value={adminUser.phone_number}
                  className={
                    errors?.phone_number
                      ? "globalInput errorInput"
                      : "globalInput"
                  }
                  onChange={handleChange}
                /> */}

                <PhoneInput
                  country={"us"}
                  enableSearch={true}
                  countryCodeEditable={false}
                  inputClass={
                    errors.phone_number
                      ? "phoneNumberInput errorInput"
                      : "phoneNumberInput"
                  }
                  value={adminUser.phone_number}
                  onChange={(value, country, e, formattedValue) => {
                    setAdminUser({
                      ...adminUser,
                      phone_number: value,
                      country_phone: country.dialCode,
                    });
                  }}
                />
                <p className="errorText">{errors?.phone_number || ""}</p>
              </div>

              <div>
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={adminUser.password}
                  className={
                    errors?.password ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.password || ""}</p>
              </div>

              <div>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  name="confirm_password"
                  value={adminUser.confirm_password}
                  className={
                    errors?.confirm_password
                      ? "globalInput errorInput"
                      : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.confirm_password || ""}</p>
              </div>
            </div>
            <div className="formBottom">
              <Button
                className="submitButton"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AddEditAdminUser;
