import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Settings/Notification.module.css";
import { SETTING } from "../../../utils/constants/apiUrl";
import { get, put } from "../../../server";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../Loader";
import showToast from "../../../helpers/showToast";
const Notification = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);

  const [notifications, setNotifications] = useState({
    user_id: "",
    contact_email: "",
    send_push: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = e.currentTarget.checked;
    setNotifications({ ...notifications, [name]: value });
  };

  const loadNotifications = async () => {
    setLoading(true);

    let { status, message, data } = await get(
      `${SETTING}/userNotification`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setNotifications(data);
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    let { status, message, data } = await put(
      `${SETTING}/userNotification`,
      notifications,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);
    setLoading(false);
  };

  useEffect(() => {
    loadNotifications();
  }, []);
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className={classes.notificationWrapper}>
        <div className={classes.inputWrapper}>
          <div className="row">
            <div className="col-md-4 col-12">
              {/* <div className={classes.switchWrapper}>
                <p className={classes.emailTitle}>Received Email</p>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    name="contact_email"
                    checked={notifications.contact_email}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div> */}
              {/* <p
                className={classes.info}
                style={{
                  borderBottom: "1px solid #333E56",
                  paddingBottom: "15px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              </p> */}

              <div
                className={classes.switchWrapper}
                style={{ marginTop: "15px" }}
              >
                <p className={classes.emailTitle}>Received Notification</p>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    name="send_push"
                    checked={notifications.send_push}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <p className={classes.info}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              </p>
            </div>
            <div className="col-md-4 col-12"></div>
            <div className="col-md-4 col-12"></div>
          </div>
        </div>
        <div className={classes.submitBtnWrapper} onClick={handleSubmit}>
          <button type="button">Update Notification</button>
        </div>
      </div>
    </>
  );
};

export default Notification;
