import nodes from "../../assets/images/function_page_icons/nodes.svg";
import diagram from "../../assets/images/function_page_icons/diagram.svg";
import management from "../../assets/images/function_page_icons/management.svg";
import chart from "../../assets/images/function_page_icons/chart.svg";

const TeamData = [
  {
    id: 1,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, #22A081 0%, rgba(34, 160, 129, 0) 100%)",
    image: nodes,
    title: "Manage Nodes",
    description: "Manage Your Nodes",
    url: "/team/manageNodes",
    text: "Manage",
  },

  {
    id: 2,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, #00D632 0%, rgba(0, 214, 50, 0) 100%)",
    image: management,
    title: "Manage Team Members",
    description: "Manage Your Team Members",
    url: "/team/teamMembers",
    text: "Manage",
  },
  {
    id: 3,
    bgColor:
      " radial-gradient(52.22% 52.22% at 50% 39.76%, #22A081 0%, rgba(34, 160, 129, 0) 100%)",
    image: diagram,
    title: "View Hierarchy Tree",
    description: "View Your Hierarchy Tree",
    url: "/team/viewHierarchy",
    text: "View",
  },
  {
    id: 4,
    bgColor:
      " radial-gradient(52.22% 52.22% at 50% 39.76%, #22A081 0%, rgba(34, 160, 129, 0) 100%)",
    image: chart,
    title: "View Stats",
    description: "View Your Stats",
    url: "/team/stats",
    text: "View",
  },
];

export default TeamData;
