import { useEffect, useState } from "react";
import classes from "../assets/styles/Select.module.css";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { BsCheck2 } from "react-icons/bs";

const Select = ({
  placeholder = "Choose",
  options,
  value = "",
  name,
  onChange,
  labelKey = "label",
  valueKey = "value",
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handler = () => setOpen(false);

    window.addEventListener("click", handler);

    return () => {
      window.removeEventListener("click", handler);
    };
  });

  const handleClick = (e) => {
    e.stopPropagation();

    if (!disabled) {
      setOpen(!open);
    }
  };

  return (
    <>
      <div
        className={`${disabled ? classes.disabled : null} ${
          open ? classes.openSelect : null
        } ${classes.select}`}
        onClick={handleClick}
        onBlur={() => setOpen(false)}
      >
        <span className={classes.placeholder}>
          {value ? value.replaceAll("_", " ") : placeholder}
        </span>
        {open ? (
          <BiChevronUp className={classes.arrow} />
        ) : (
          <BiChevronDown className={classes.arrow} />
        )}
      </div>

      {open ? (
        <div className={classes.optionWrapper}>
          {options.map((option, index) => (
            <div
              key={`option-${option.value}-${index}`}
              className={`${classes.option} ${
                option.value === value ? classes.selected : null
              }`}
              onClick={() =>
                onChange({ target: { name, value: option[`${valueKey}`] } })
              }
            >
              {option[`${labelKey}`]}
              {option.value === value ? (
                <BsCheck2 className={classes.selectedIcon} />
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default Select;
