import baseUrl from "./baseUrl";

const API_BASE = baseUrl.BASE_URL;

const AUTH_PATH = "auth/";
const CONTACT_PATH = "contact";
const EMERGENCY_CONTACT_PATH = "emergencyContact";
const SETTING_PATH = "setting";

const VENMO_PATH = "venmo";
const CASH_APP_PATH = "cashApp";
const CUSTOME_URL_PATH = "customUrl";
const File_Upload_PATH = "fileUpload";
const Card_URL_Path = "card";
const GENERAL = "general";
const EMERGENCY_CONTACT_VCF_FILE = "general/createVCFFile/emergencyContact";
const CARD_VCF_FILE = "general/createVCFFile/card";

export const LOGIN = API_BASE + AUTH_PATH + "login";
export const ALREADY_MEMBER = API_BASE + AUTH_PATH + "alreadyMember";
export const SOCIAL_LOGIN = API_BASE + AUTH_PATH + "socialLogin";
export const SOCIAL_SIGNUP = API_BASE + AUTH_PATH + "socialSignup";
export const LOGOUT = API_BASE + AUTH_PATH + "logout";
export const FORGET_PASSWORD = API_BASE + AUTH_PATH + "forgotPassword";
export const CHECK_OTP = API_BASE + AUTH_PATH + "checkOTP";
export const RESET_PASSWORD = API_BASE + AUTH_PATH + "resetPassword";
export const GET_USER = API_BASE + AUTH_PATH + "getUser";
export const GET_USER_ACTION = API_BASE + AUTH_PATH + "getUserAction";
export const UPDATE_USER = API_BASE + AUTH_PATH + "updateUser";
export const DELETE_USER_ACCOUNT = API_BASE + GENERAL + "/deleteUserAccount";
export const DELETE_OWNER_ACCOUNT = API_BASE + GENERAL + "/deleteOwnerAccount";

export const SIGNUP = API_BASE + AUTH_PATH + "register";

export const TEAM_OWNER_SIGNUP = API_BASE + AUTH_PATH + "registerTeamOwner";
export const HIERARCHY_TREE = API_BASE + "public/tree/getHierarchyData";
export const NODE_SCAN = API_BASE + "public/tree/nodeScan";
export const PUBLIC_TEAM_MEMBER_FUNCTIONS =
  API_BASE + "public/allPublicTeamMemberFunction";
export const CREATE_NODE = API_BASE + "tree/createNode";
export const UPDATE_NODE = API_BASE + "tree/updateNodeName";
export const REASSIGN_NODE = API_BASE + "tree/reassignNode";
export const DELETE_NODE = API_BASE + "tree/deleteNode";
export const ATTACH_MEMBER = API_BASE + "tree/attachMemberNode";
export const REMOVE_MEMBER = API_BASE + "tree/removeMemberNode";
export const TEAM_STATS = API_BASE + "general/getTeamStats";

export const CHANGE_MEMBER_PARENT = API_BASE + "tree/changeMemberParent";
export const GET_MEMBER_FUNCTIONS = API_BASE + "tree/getMemberFunctions";
export const MANAGE_MEMEBER_ACTIVE_FUNCTION =
  API_BASE + "tree/manageHierarchyMemberFunction";
export const REMOVE_ACTIVE_FUNCTION =
  API_BASE + "tree/removeHierarchyMemberFunction/";

export const INVITATION = API_BASE + "invitation";
export const SEND_INVITATION = API_BASE + "invitation/sendInvitation";
export const RESEND_INVITATION = API_BASE + "invitation/reSendInvitation";
export const ALLTEAMOWNERS = API_BASE + "admin/team/getAllTeamOwner";
export const TEAMDETAIL = API_BASE + "team";
export const TEAM_MEMBER_STATUS = API_BASE + "team/TeamMember/status";
export const ADD_TEAM_MEMBER = API_BASE + "team/TeamMember";
export const PENDING_MEMBER_PAYMENT =
  API_BASE + "team/getPendingTeamMemberPayment";
export const PAY_MEMBER_PAYMENT = API_BASE + "team/payMemberPayment";
export const ASSIGN_MEMBER_ROLE = API_BASE + "team/TeamMember/assignMemberRole";

export const CONTACT = API_BASE + CONTACT_PATH;
export const ADMIN_USERS = API_BASE + "userAdmin";
export const SETTING = API_BASE + SETTING_PATH;
export const PAGES = API_BASE + SETTING_PATH + "/page";

export const VENMO_URL = API_BASE + VENMO_PATH;
export const CASH_APP_URL = API_BASE + CASH_APP_PATH;
export const CUSTOME_URL = API_BASE + CUSTOME_URL_PATH;
export const FILE_UPLOAD = API_BASE + File_Upload_PATH;
export const Card_URL = API_BASE + Card_URL_Path;
export const DUPLICATE_Card_URL = API_BASE + Card_URL_Path + "/duplicate";

export const EMERGENCY_CONTACT = API_BASE + EMERGENCY_CONTACT_PATH;
export const USER_EMERGENCY_CONTACT = API_BASE + EMERGENCY_CONTACT_PATH;

export const HANDLE_FUNCTION_STATUS =
  API_BASE + GENERAL + "/changeFunctionStatus";

export const HANDLE_STATUS = API_BASE + GENERAL + "changeStatus";
export const USER = API_BASE + "admin/user";
export const PRICING = API_BASE + SETTING_PATH + "/pricing";
export const TUTORIAL = API_BASE + SETTING_PATH + "/tutorial";
export const CONTACT_US = API_BASE + SETTING_PATH + "/contactUs";
export const HOME_DASHBOARD = API_BASE + GENERAL + "/homeDashboard";
export const IMAGE_UPLOAD = API_BASE + GENERAL + "/imageUpload";
export const SEND_NOTIFICATION = API_BASE + GENERAL + "/sendPushNotification";
export const USER_NOTIFICATION = API_BASE + GENERAL + "/userNotification";
export const APP_SETTING = API_BASE + "appSetting";
export const SHARE_CARD = API_BASE + "share/card";
export const SHARE_FUNCTIONS = API_BASE + "share/";
export const SHARE_CONTACT = API_BASE + "share/contact";
export const SHARE_EMERGENCY_CONTACT = API_BASE + "share/emergencyContact";
export const DELETE_USER_NFCS = API_BASE + "userNfc/deleteAllUserNfc";
export const USERNFC = API_BASE + "userNfc";
export const PAYMENT = API_BASE + "payment";
export const TRANSACTION = API_BASE + "transaction";
export const HOMEDASHBOARD = API_BASE + GENERAL + "/homeDashboardV1";
export const EMERGENCY_CONTACT_VCF_FILE_URL =
  API_BASE + EMERGENCY_CONTACT_VCF_FILE;

export const PACKAGES_LISTING = API_BASE + "purchase/getAllPackages";
export const PACKAGE = API_BASE + "purchase";

export const CARD_VCF_FILE_URL = API_BASE + CARD_VCF_FILE;
