import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/signup_icons/logo.svg";
import google from "../assets/images/signup_icons/google.png";
import facebook from "../assets/images/signup_icons/face.svg";
import personicon from "../assets/images/signup_icons/personicon.svg";
import emailicon from "../assets/images/signup_icons/email.svg";
import lockicon from "../assets/images/signup_icons/lock.svg";
import classes from "../assets/styles/Signup.module.css";
import FormInput from "../components/Forms/FormInput";
import {
  SIGNUP,
  SOCIAL_LOGIN,
  SOCIAL_SIGNUP,
  TEAM_OWNER_SIGNUP,
} from "../utils/constants/apiUrl";
import { post } from "../server";
import { useAtom } from "jotai";
import { loggedInUser, isOwnerPackage } from "../utils/store";
import { useNavigate, useLocation } from "react-router-dom";
import {
  SocialUserSignupSchema,
  SocialTeamOwnerSignupSchema,
} from "../utils/SchemasValidation/SignupSchema";
import ValidateSchema from "../helpers/ValidateSchema";
import showToast from "../helpers/showToast";
import Loader from "../components/Loader";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import _ from "lodash";
import { logoutFbApi } from "../utils/functions";
import useFunctionsCount from "../hooks/useFunctionsCount";

const SocialSignup = () => {
  let emptyObj = {
    first_name: "",
    last_name: "",
    email: "",
  };

  let navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const uuid = searchParams.get("uuid");
  const team_id = searchParams.get("team_id");

  const first_name = searchParams.get("first_name");
  const last_name = searchParams.get("last_name");
  const socialEmail = searchParams.get("socialEmail");
  const isTeamOwner = searchParams.get("is_team_owner");
  const token = searchParams.get("token");
  const provider_type = searchParams.get("provider_type");

  const { userActions } = useFunctionsCount();

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [isOwnerPackageActive, setIsOwnerPackageActive] =
    useAtom(isOwnerPackage);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState(emptyObj);

  useEffect(() => {
    if (first_name) {
      if (isTeamOwner === "false") {
        setUser({
          ...user,
          first_name: first_name,
          last_name: last_name,
          email: socialEmail,
        });
      } else {
        setUser({
          ...user,
          first_name: first_name,
          last_name: last_name,
          email: socialEmail,
          team_name: "",
        });
      }
    }
  }, [first_name]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const validateForm = () => {
    if (isTeamOwner === "false") {
      let newErrors = ValidateSchema(user, SocialUserSignupSchema);
      setErrors(newErrors);
      if (Object.keys(newErrors).length) return false;
      return true;
    } else {
      let newErrors = ValidateSchema(user, SocialTeamOwnerSignupSchema);
      setErrors(newErrors);
      if (Object.keys(newErrors).length) return false;
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    setIsOwnerPackageActive(false);

    setLoading(true);
    if (isTeamOwner === "false") {
      let payload = _.cloneDeep(user);
      payload.token = token;
      payload.provider_type = provider_type;
      if (uuid && team_id) {
        payload.uuid = uuid;
        payload.team_id = team_id;
      }
      let { status, message, data } = await post(SOCIAL_SIGNUP, payload);
      if (status) {
        setLoggedUser(data);
        userActions(data);
        navigate("/");
      }
      showToast(status, message);
    } else {
      let payload = _.cloneDeep(user);
      payload.token = token;
      payload.provider_type = provider_type;
      let { status, message, data } = await post(SOCIAL_SIGNUP, payload);
      if (status) {
        setLoggedUser(data);
        userActions(data);
        navigate("/subscription");
      }

      if (!status) {
        showToast(status, message);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <section className={classes.page}>
        {!loading ? (
          <div className="container">
            <header>
              <nav className={classes.navbar}>
                <ul className="d-flex justify-content-between align-items-center">
                  <li>
                    <img src={logo} alt="logo" className={classes.logo} />
                  </li>
                  <ul className={classes.list}>
                    <li className="">
                      <Link to="/signup">
                        <button className={classes.signBtn}>Sign Up</button>
                      </Link>
                    </li>
                    <li>
                      <Link to="/login">
                        <button className={classes.signBtn}>Sign in</button>
                      </Link>
                    </li>
                  </ul>
                </ul>
              </nav>
            </header>

            <div className={classes.accountWrapper}>
              <div className={classes.headingsWrapper}>
                <h1 className={classes.heading}>Create account</h1>
                <h4 className={classes.subHeading}>Create your free account</h4>
              </div>
              <div className={classes.createAccountBox}>
                <form>
                  {isTeamOwner === "true" ? (
                    <div className={classes.teamNameinputGap}>
                      <FormInput
                        type="text"
                        name="team_name"
                        placeholder="Team Name"
                        value={user.team_name}
                        handleChange={handleChange}
                        error={errors?.team_name}
                        icon={personicon}
                        autofocus={true}
                      />
                    </div>
                  ) : null}

                  <div className={classes.inputsGap}>
                    <FormInput
                      // label="First Name"
                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      value={user.first_name}
                      handleChange={handleChange}
                      error={errors?.first_name}
                      icon={personicon}
                      disabled={first_name ? true : false}
                    />
                  </div>
                  <div className={classes.inputsGap}>
                    <FormInput
                      // label="Last Name"
                      type="text"
                      placeholder="Last Name"
                      name="last_name"
                      value={user.last_name}
                      handleChange={handleChange}
                      error={errors?.last_name}
                      icon={personicon}
                      disabled={last_name ? true : false}
                    />
                  </div>

                  <div className={classes.inputsGap}>
                    <FormInput
                      // label="Email"
                      type="text"
                      name="email"
                      placeholder="Email Address"
                      value={user.email}
                      handleChange={handleChange}
                      error={errors?.email}
                      icon={emailicon}
                      disabled={socialEmail.length > 0 ? true : false}
                    />
                  </div>

                  <div className={classes.submitBtnWrapper}>
                    <button
                      className={classes.submitBtn}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </section>
    </>
  );
};

export default SocialSignup;
