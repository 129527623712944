import { useEffect, useState } from "react";
import classes from "../../assets/styles/Pages/Help/Tutorial.module.css";
import { Link } from "react-router-dom";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { BsFillPlayFill } from "react-icons/bs";
import { get } from "../../server";
import { TUTORIAL } from "../../utils/constants/apiUrl";
import showToast from "../../helpers/showToast";
import { loggedInUser } from "../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../components/Loader";
import baseURL from "../../utils/constants/baseUrl";
import tutorial_image from "../../assets/images/help/tutorial_image.png";
import noImage from "../../assets/images/no_image.jpg";

const Tutorial = () => {
  const [loading, setLoading] = useState(false);
  const [tutorials, setTutorials] = useState([]);

  const [loggedUser] = useAtom(loggedInUser);

  useEffect(() => {
    fetchTutorials();
  }, []);

  const fetchTutorials = async () => {
    setLoading(true);

    let { status, message, data } = await get(
      TUTORIAL,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setTutorials(data);
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  const handleScrollLeft = () => {
    document.getElementById("videoWrapper").scrollLeft -= 300;
  };

  const handleScrollRight = () => {
    document.getElementById("videoWrapper").scrollLeft += 300;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="helpBackLinkWrapper">
        <Link to="/help">Back</Link>
      </div>
      <div className={classes.helpHeader}>
        <div className="row flex-md-row flex-column-reverse gy-4">
          {/* Tutorial top left section */}
          <div className="col-sm-6">
            <h3 className="helpHeading">Tutorials</h3>
            <p className="helpSubHeading">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et
              exercitationem quam repudiandae necessitatibus
            </p>

            <p className="helpDetail">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et
              exercitationem quam repudiandae necessitatibus Lorem, ipsum dolor
              sit amet consectetur adipisicing elit. Et exercitationem quam
              repudiandae necessitatibus
            </p>
          </div>

          {/*Right Section Image */}
          <div className="col-sm-6">
            <img src={tutorial_image} alt="" className="helpImage" />
          </div>
        </div>
      </div>

      {/* Second Section */}

      <div className={classes.helpbottom}>
        <div className={classes.videoHeader}>
          <span className="">Videos</span>
          <div>
            <FiChevronLeft
              className={classes.videoArrowBtn}
              onClick={handleScrollLeft}
            />
            <FiChevronRight
              className={classes.videoArrowBtn}
              onClick={handleScrollRight}
            />
          </div>
        </div>

        <div className={classes.videoWrapper} id="videoWrapper">
          {tutorials.map((tutorial, index) => (
            <div key={index}>
              <a href={tutorial.url} target="_blank" rel="noreferrer">
                <div className={classes.imageWrapper}>
                  <img
                    src={
                      tutorial.image
                        ? `${baseURL.PUBLIC_URL}${tutorial.image}`
                        : noImage
                    }
                    alt="tutorial"
                    onError={(e) => {
                      e.currentTarget.src = noImage;
                    }}
                  />
                  <BsFillPlayFill />
                </div>
              </a>

              <p className={classes.title}>{tutorial.title}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Tutorial;
