const Joi = require("joi");

const LoginSchema = Joi.object({
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),
  password: Joi.string().min(8).required().messages({
    "string.empty": `Please enter password`,
    "string.min": `Password must be minimum 8 characters!`,
  }),
});

export default LoginSchema;
