import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import classes from "../../assets/styles/CustomURLModal.module.css";
import { MdClose } from "react-icons/md";
import SecondLoader from "../../components/SecondLoader";

const ManageTeamMemberModal = ({
  data,
  setSelectedNode,
  handleSaveSelectedNodes,
  handleCloseModal,
  show,
  selectedNode,
  loading,
  unlistedMembers,
  isAllNodes,
}) => {
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedNode(value);
  };

  //   // handle Validation
  //   const validateForm = () => {
  //     let newErrors = ValidateSchema(data, AddPaymentSchema);
  //     setErrors(newErrors);
  //     if (Object.keys(newErrors).length) return false;
  //     return true;
  //   };

  const handleAddUrl = async (e) => {
    // if (!validateForm()) return;
    handleSaveSelectedNodes(selectedNode);
  };

  // Handle Update
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleCloseModal();
          setErrors({});
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>
            {unlistedMembers
              ? "Unassigned Members"
              : isAllNodes
              ? "All Nodes"
              : "Nodes without sub nodes"}
          </span>
          <div className="modalGradientCircle"></div>
          <MdClose
            onClick={() => {
              handleCloseModal();
              setErrors({});
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>
        {loading ? (
          <SecondLoader />
        ) : (
          <>
            <Modal.Body>
              <div style={{ marginTop: "-140px" }}>
                <div>
                  <label
                    htmlFor=""
                    className={classes.label}
                    style={{ fontSize: "12px" }}
                  >
                    {unlistedMembers ? "Members" : "Nodes"}
                  </label>
                  <div className="input-group globalInputWithIcon">
                    <select
                      style={{ border: "0" }}
                      className="form-select customSelect"
                      onChange={handleChange}
                    >
                      <option value="">
                        Select {unlistedMembers ? "Member" : "Node"}
                      </option>
                      {unlistedMembers
                        ? data.map((member, index) => {
                            return (
                              <option
                                key={`${index}=${member.id}`}
                                value={member.id}
                              >
                                {`${member.user_data.first_name} ${member.user_data.last_name}`}
                              </option>
                            );
                          })
                        : data.map((node, index) => {
                            return (
                              <option
                                key={`${index}=${node.id}`}
                                value={node.id}
                              >
                                {node.name}
                              </option>
                            );
                          })}
                    </select>
                  </div>
                  <span
                    className="errorText"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "14px",
                    }}
                  >
                    {errors.payment_type}
                  </span>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className={classes.btnWrapper}>
                <Button
                  type="button"
                  onClick={() => {
                    handleCloseModal();
                    setErrors({});
                  }}
                  className={classes.cancelBtn}
                >
                  Cancel
                </Button>

                <Button
                  type="button"
                  onClick={handleAddUrl}
                  className={classes.saveBtn}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default ManageTeamMemberModal;
