import React from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Settings/Subscription.module.css";

import basicicon from "../../../assets/images/settings_page_icons/basicicon.svg";
import proicon from "../../../assets/images/settings_page_icons/proicon.svg";
import premiumicon from "../../../assets/images/settings_page_icons/premiumicon.svg";
import { Link } from "react-router-dom";
import {
  loggedInUser,
  subscriptionPackages,
  subscribedPackage,
} from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../Loader";
import { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import playstore from "../../../assets/images/home_page_images/playstore.svg";
import appstore from "../../../assets/images/home_page_images/appstore.svg";
import { AiFillCheckCircle } from "react-icons/ai";

const Subscription = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [purchasePackages, setPurchasePackages] = useAtom(subscriptionPackages);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [subscriptions] = useState(purchasePackages);
  const [loading, setLoading] = useState(false);
  const [activeKeys, setActiveKeys] = useState([]);

  const handleTabSelect = (subscriptionIndex, key) => {
    setActiveKeys((prevActiveKeys) => {
      // Create a copy of the previous activeKeys array
      const updatedActiveKeys = [...prevActiveKeys];
      // Update the active key for the corresponding subscription
      updatedActiveKeys[subscriptionIndex] = key;
      return updatedActiveKeys;
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={classes.cardsWrapper}>
      {subscriptions.length !== 0
        ? subscriptions
            .filter((opt) => opt.package_type == "personal")
            .map((singlePackage, index) => (
              <div className={classes.card} key={`pricingCard${index}`}>
                <button className={classes.subscriptionType}>
                  {purchasedPackage.id === singlePackage.id ? (
                    <>
                      {singlePackage.package_name} &nbsp;
                      <AiFillCheckCircle />
                    </>
                  ) : (
                    <>{singlePackage.package_name}</>
                  )}
                </button>
                <div className={classes.tabsWrapper}>
                  <Tabs
                    defaultActiveKey="monthly"
                    id={`uncontrolled-tab-example-${index}`}
                    className="customSubscriptionTabs"
                    transition={false}
                    activeKey={activeKeys[index]}
                    onSelect={(k) => handleTabSelect(index, k)}
                  >
                    <Tab eventKey="monthly" title="Monthly">
                      <div className={classes.imgWrapper}>
                        <img
                          src={
                            singlePackage.package_name === "Starter"
                              ? basicicon
                              : singlePackage.package_name === "Premium"
                              ? proicon
                              : premiumicon
                          }
                          alt=""
                        />
                      </div>
                      <h3 className={classes.title}>
                        {singlePackage.package_name === "Starter"
                          ? "Free"
                          : singlePackage.package_name === "Enterprise"
                          ? "Custom Price"
                          : `$${singlePackage.price_monthly}`}
                      </h3>
                      <ul className={classes.list}>
                        {/*  Card Section */}
                        {singlePackage.limit_card_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Personal/Business Card</p>
                              <p>{singlePackage.limit_card_create}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Personal/Business Card</p>
                              <p>{singlePackage.limit_card_create}</p>
                            </div>
                          </li>
                        )}

                        {/* Connection */}
                        {singlePackage.limit_connections_save == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Connections</p>
                              <p>{singlePackage.limit_connections_save}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Connections</p>
                              <p>{singlePackage.limit_connections_save}</p>
                            </div>
                          </li>
                        )}

                        {/* Payments */}
                        {singlePackage.limit_payment_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Payments cards</p>
                              <p>{singlePackage.limit_payment_create}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Payments cards</p>
                              <p>{singlePackage.limit_payment_create}</p>
                            </div>
                          </li>
                        )}

                        {/* Emergency Contact */}
                        {singlePackage.limit_emergency_contact_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Emergency Contacts</p>
                              <p>
                                {singlePackage.limit_emergency_contact_create}
                              </p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Emergency Contacts</p>
                              <p>
                                {singlePackage.limit_emergency_contact_create}
                              </p>
                            </div>
                          </li>
                        )}

                        {/* Custom Url */}
                        {singlePackage.limit_custom_url_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Custom URL</p>
                              <p>{singlePackage.limit_custom_url_create}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Custom URL</p>
                              <p>{singlePackage.limit_custom_url_create}</p>
                            </div>
                          </li>
                        )}

                        {/* File Upload Url */}
                        {singlePackage.limit_file_upload_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>File Upload URL</p>
                              <p>{singlePackage.limit_file_upload_create}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>File Upload URL</p>
                              <p>{singlePackage.limit_file_upload_create}</p>
                            </div>
                          </li>
                        )}

                        {/*Custom Button */}
                        {singlePackage.enable_custom_buttons == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Custom Button</p>
                              <p>
                                {singlePackage.enable_custom_buttons
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Custom Button</p>
                              <p>
                                {singlePackage.enable_custom_buttons
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </li>
                        )}

                        {/*Email Notification Button */}
                        {/* {singlePackage.enable_email_notifications == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Email Notification</p>
                              <p>
                                {singlePackage.enable_email_notifications
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Email Notification</p>
                              <p>
                                {singlePackage.enable_email_notifications
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </li>
                        )} */}
                      </ul>
                      {singlePackage.id == 4 ? (
                        <div className={classes.btnWrapper}>
                          <Link to={`/PackageContactForm/${singlePackage.id}`}>
                            <button className={classes.btn}>Contact Us</button>
                          </Link>
                        </div>
                      ) : (
                        <>
                          {/* <div
                            style={{
                              borderTop: "1px solid #333e56",
                              marginTop: "10px",
                            }}
                          > */}
                          <div className={classes.imagesWrapper}>
                            <div>
                              <a
                                href="https://play.google.com/store/apps"
                                target="_blank"
                              >
                                <img src={playstore} alt="" />
                              </a>
                            </div>
                            <div>
                              <a
                                href="https://www.apple.com/app-store/"
                                target="_blank"
                              >
                                <img src={appstore} alt="" />
                              </a>
                            </div>
                          </div>
                          {/* </div> */}
                        </>
                      )}
                    </Tab>
                    <Tab eventKey="yearly" title="Yearly">
                      <div className={classes.imgWrapper}>
                        <img
                          src={
                            singlePackage.package_name === "Starter"
                              ? basicicon
                              : singlePackage.package_name === "Premium"
                              ? proicon
                              : premiumicon
                          }
                          alt=""
                        />
                      </div>
                      <h3 className={classes.title}>
                        {singlePackage.package_name === "Starter"
                          ? "Free"
                          : singlePackage.package_name === "Enterprise"
                          ? "Custom Price"
                          : `$${singlePackage.price_yearly}`}
                      </h3>
                      <ul className={classes.list}>
                        {/*  Card Section */}
                        {singlePackage.limit_card_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Personal/Business Card</p>
                              <p>{singlePackage.limit_card_create}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Personal/Business Card</p>
                              <p>{singlePackage.limit_card_create}</p>
                            </div>
                          </li>
                        )}

                        {/* Connection */}
                        {singlePackage.limit_connections_save == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Connections</p>
                              <p>{singlePackage.limit_connections_save}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Connections</p>
                              <p>{singlePackage.limit_connections_save}</p>
                            </div>
                          </li>
                        )}

                        {/* Payments */}
                        {singlePackage.limit_payment_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Payments cards</p>
                              <p>{singlePackage.limit_payment_create}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Payments cards</p>
                              <p>{singlePackage.limit_payment_create}</p>
                            </div>
                          </li>
                        )}

                        {/* Emergency Contact */}
                        {singlePackage.limit_emergency_contact_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Emergency Contacts</p>
                              <p>
                                {singlePackage.limit_emergency_contact_create}
                              </p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Emergency Contacts</p>
                              <p>
                                {singlePackage.limit_emergency_contact_create}
                              </p>
                            </div>
                          </li>
                        )}

                        {/* Custom Url */}
                        {singlePackage.limit_custom_url_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Custom URL</p>
                              <p>{singlePackage.limit_custom_url_create}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Custom URL</p>
                              <p>{singlePackage.limit_custom_url_create}</p>
                            </div>
                          </li>
                        )}

                        {/* File Upload Url */}
                        {singlePackage.limit_file_upload_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>File Upload URL</p>
                              <p>{singlePackage.limit_file_upload_create}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>File Upload URL</p>
                              <p>{singlePackage.limit_file_upload_create}</p>
                            </div>
                          </li>
                        )}

                        {/*Custom Button */}
                        {singlePackage.enable_custom_buttons == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Custom Button</p>
                              <p>
                                {singlePackage.enable_custom_buttons
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Custom Button</p>
                              <p>
                                {singlePackage.enable_custom_buttons
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </li>
                        )}

                        {/*Email Notification Button */}
                        {/* {singlePackage.enable_email_notifications == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "#535f79" }}
                            >
                              <p>Email Notification</p>
                              <p>
                                {singlePackage.enable_email_notifications
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Email Notification</p>
                              <p>
                                {singlePackage.enable_email_notifications
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </li>
                        )} */}
                      </ul>
                      {singlePackage.id == 4 ? (
                        <div className={classes.btnWrapper}>
                          <Link to={`/PackageContactForm/${singlePackage.id}`}>
                            <button className={classes.btn}>Contact Us</button>
                          </Link>
                        </div>
                      ) : (
                        <>
                          <div className={classes.imagesWrapper}>
                            <div>
                              <a
                                href="https://play.google.com/store/apps"
                                target="_blank"
                              >
                                <img src={playstore} alt="" />
                              </a>
                            </div>
                            <div>
                              <a
                                href="https://www.apple.com/app-store/"
                                target="_blank"
                              >
                                <img src={appstore} alt="" />
                              </a>
                            </div>
                          </div>
                        </>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            ))
        : null}
    </div>
  );
};

export default Subscription;
