import React, { useState } from "react";
import notification from "../assets/images/navbar_icons/notification.svg";
import arrow from "../assets/images/navbar_icons/arrow.svg";
import classes from "../assets/styles/Navbar.module.css";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import dummyimage from "../assets/images/global_icons/dummyimage.svg";
import { FiSettings } from "react-icons/fi";
import { BiHelpCircle } from "react-icons/bi";
import { IoIosLogOut } from "react-icons/io";
import { BsPerson } from "react-icons/bs";
import { BiGroup } from "react-icons/bi";
import {
  loggedInUser,
  teamIDCheck,
  accountType,
  subscribedPackage,
} from "../utils/store";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import baseUrl from "../utils/constants/baseUrl";
import AllNotifications from "./AllNotifications";
import showToast from "../helpers/showToast";
import useLogout from "../hooks/useLogout";

const Navbar = ({ handleShowSidebar }) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);
  const [showNotification, setShowNotification] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const logout = useLogout();

  const withTeamIdData = loggedUser?.secret_code?.filter(
    (item) => item.team_id !== null
  );

  const handleSwitchAccount = () => {
    if (personalAccount) {
      const isSuspended = loggedUser?.team_members?.some(
        (member) => member.check_status === "suspended"
      );
      if (isSuspended) {
        showToast(
          false,
          "Your account has been suspended by admin, please contact support for further queries"
        );
      } else {
        let teamID = withTeamIdData[0]?.team_id;
        let isTeamPackage = loggedUser?.package_details.filter(
          (obj) => obj.team_id == teamID
        );

        let endDate = new Date(isTeamPackage[0]?.end_date);
        let startDate = new Date();
        if (isTeamPackage.length <= 0 || startDate >= endDate) {
          return showToast(
            false,
            "You can not switch to team mode because your team is not subscribed to any package"
          );
        }
        let teamPackage = loggedUser?.package_details?.find(
          (obj) => obj.team_id == teamID
        );
        setPurchasedPackage(teamPackage);
        setUserTeamId(withTeamIdData[0]?.team_id);
        setPersonalAccount(false);
        navigate("/");
      }
    } else {
      let teamPackage = loggedUser?.package_details?.find(
        (obj) => obj.team_id == null
      );

      setPurchasedPackage(teamPackage);
      setUserTeamId(null);
      setPersonalAccount(true);
      navigate("/");
    }
  };

  // const checkCustomer = loggedUser?.role?.includes("customer");
  const checkCustomer =
    !loggedUser?.role?.some(
      (role) => role === "customer" || role === "team_owner"
    ) === false;
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
  };

  const handleNotificationClick = (e) => {
    e.stopPropagation();
    setShowNotification(!showNotification);
  };

  return (
    <>
      <section className={classes.navbarSection}>
        <div className="container-fluid">
          <header>
            <nav>
              <ul className={classes.navItemsWrapper}>
                <li className={classes.menuIconBtnListItem}>
                  <button
                    className={classes.navIconBtns}
                    onClick={handleShowSidebar}
                  >
                    <BiMenu />
                  </button>
                </li>
                <li>
                  <ul className={classes.iconsWrapper}>
                    {checkCustomer && (
                      <li style={{ position: "relative" }}>
                        <button
                          className={classes.navIconBtns}
                          onClick={handleNotificationClick}
                        >
                          <img src={notification} alt="Notification Icon" />
                        </button>
                        {showNotification && (
                          <AllNotifications
                            showNotification={showNotification}
                            setShowNotification={setShowNotification}
                          />
                        )}
                      </li>
                    )}
                    <li>
                      <div className="dropdown">
                        <button
                          style={{ background: "#19202e" }}
                          className={`btn custom-dropdown-toggle dropdown- ${classes.dropdownBtn}`}
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <Image
                            src={baseUrl.PUBLIC_URL + loggedUser.profile_image}
                            width="30px"
                            height="30px"
                            loading="lazy"
                            decoding="async"
                            roundedCircle={true}
                            className={classes.imageBackground}
                            onError={(e) => {
                              e.currentTarget.src = dummyimage;
                            }}
                          />
                          <img
                            src={arrow}
                            alt="Arrow icon"
                            className={classes.arrowIcon}
                          />
                        </button>
                        <ul
                          className="customActionDropdownMenu dropdown-menu"
                          style={{ minWidth: "260px" }}
                        >
                          <div className={classes.profileWrapper}>
                            <Image
                              src={
                                baseUrl.PUBLIC_URL + loggedUser.profile_image
                              }
                              width="65px"
                              loading="lazy"
                              decoding="async"
                              height="65px"
                              roundedCircle={true}
                              className={classes.imageBackground}
                              onError={(e) => {
                                e.currentTarget.src = dummyimage;
                              }}
                            />
                            <div>
                              <h6>
                                {`${loggedUser?.first_name || ""} ${
                                  loggedUser?.last_name || ""
                                }
                             `}
                              </h6>
                              <p>{loggedUser?.email || ""}</p>
                              {checkCustomer && !userTeamId && (
                                <div className={classes.btnWrapper}>
                                  <Link to="/packages">
                                    <button>
                                      {purchasedPackage?.package_name}
                                    </button>
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                          {checkCustomer && (
                            <>
                              <li>
                                <Link className="dropdown-item" to="/settings">
                                  <div className={classes.iconsWrapper}>
                                    <div>
                                      <FiSettings />
                                    </div>
                                    <p>Settings</p>
                                  </div>
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/help">
                                  <div className={classes.iconsWrapper}>
                                    <div>
                                      <BiHelpCircle />
                                    </div>
                                    <p>Help</p>
                                  </div>
                                </Link>
                              </li>
                              {loggedUser?.secret_code?.length === 2 &&
                                !loggedUser?.role.includes("team_owner") && (
                                  <li onClick={handleSwitchAccount}>
                                    <span className="dropdown-item">
                                      <div className={classes.iconsWrapper}>
                                        <div>
                                          {personalAccount ? (
                                            <BiGroup />
                                          ) : (
                                            <BsPerson />
                                          )}
                                        </div>
                                        <p>
                                          Switch to
                                          {personalAccount
                                            ? " Team"
                                            : " Personal"}
                                        </p>
                                      </div>
                                    </span>
                                  </li>
                                )}
                            </>
                          )}
                          <li onClick={() => handleLogout()}>
                            <span className="dropdown-item">
                              <div className={classes.iconsWrapper}>
                                <div>
                                  <IoIosLogOut style={{ color: "#E35252" }} />
                                </div>
                                <p style={{ color: "#E35252" }}>Log out</p>
                              </div>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <div className={classes.profileWrapper}>
                    <div className="dropdown">
                      <button
                        className={`btn custom-dropdown-toggle dropdown- ${classes.dropdownBtn}`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={notification} alt="Notification Icon" />
                      </button>
                      <ul className="customActionDropdownMenu dropdown-menu">
                        <li>Here are data</li>
                      </ul>
                    </div>
                  </div>
                </li> */}
              </ul>
            </nav>
          </header>
        </div>
      </section>
    </>
  );
};

export default Navbar;
