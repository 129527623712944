import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddCashAppSchema from "../../utils/SchemasValidation/AddCashAppSchema";
import AddPaymentSchema from "../../utils/SchemasValidation/AddPaymentSchema";
import ValidateSchema from "../../helpers/ValidateSchema";
import classes from "../../assets/styles/CustomURLModal.module.css";
import { MdClose } from "react-icons/md";
import FormInput from "../Forms/FormInput";
import SecondLoader from "../SecondLoader";
import Joi from "joi";

const activeFunctionSchema = Joi.object({
  function_type: Joi.string().required().messages({
    "string.empty": `Please select function type`,
  }),
  function_id: Joi.number().required().messages({
    "number.base": `Please select a function `,
  }),
});

const ManageActiveFunctionModal = ({
  loading,
  data,
  setSelectedData,
  handleSaveShowModal,
  handleCloseModal,
  show,
  selectedData,
}) => {
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSelectedData({ ...selectedData, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(selectedData, activeFunctionSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleAddUrl = async (e) => {
    if (!validateForm()) return;
    handleSaveShowModal(selectedData);
  };

  // Handle Update
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleCloseModal();
          setErrors({});
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>Manage Active Function</span>
          <div className="modalGradientCircle"></div>
          <MdClose
            onClick={() => {
              handleCloseModal();
              setErrors({});
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>
        {loading ? (
          <SecondLoader />
        ) : (
          <>
            <Modal.Body>
              <div style={{ marginTop: "-140px" }}>
                <div>
                  <label
                    htmlFor=""
                    className={classes.label}
                    style={{ fontSize: "12px" }}
                  >
                    Function Type
                  </label>
                  <div className="input-group globalInputWithIcon">
                    <select
                      style={{ border: "0" }}
                      className="form-select customSelect"
                      name="function_type"
                      value={selectedData.function_type}
                      onChange={handleChange}
                    >
                      <option value="">Select Function Type</option>
                      <option value="card">Cards</option>
                      <option value="custom_url">Custom Urls</option>
                      <option value="emergency_contact">
                        Emergency Contacts
                      </option>
                      <option value="file_upload">File Uploads</option>
                      <option value="payment">Payments</option>
                    </select>
                  </div>
                  <span
                    className="errorText"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "14px",
                    }}
                  >
                    {errors.function_type}
                  </span>
                </div>
                <div>
                  <label
                    htmlFor=""
                    className={classes.label}
                    style={{ fontSize: "12px" }}
                  >
                    Select Function
                  </label>
                  <div className="input-group globalInputWithIcon">
                    <select
                      style={{ border: "0" }}
                      className="form-select customSelect"
                      name="function_id"
                      value={selectedData.function_id}
                      onChange={handleChange}
                    >
                      <option>Select Any Function</option>

                      {data.length > 0 &&
                        data.map((item, index) => {
                          if (
                            selectedData.function_type === "card" ||
                            selectedData.function_type === "emergency_contact"
                          ) {
                            return (
                              <option key={index} value={item.id}>{`${
                                item.first_name
                              } ${item.last_name || ""} ${
                                selectedData.function_type === "card"
                                  ? `(${
                                      item.card_type === "business"
                                        ? "Business"
                                        : "Personal"
                                    })`
                                  : ""
                              }`}</option>
                            );
                          } else {
                            return (
                              <option key={index} value={item.id}>
                                {`${item.title}  ${
                                  selectedData.function_type === "payment"
                                    ? `(${
                                        item.payment_type === "venmo"
                                          ? "Venmo"
                                          : item.payment_type ===
                                            "custom_payment"
                                          ? "Custom Payment"
                                          : "Cash App"
                                      })`
                                    : ""
                                }`}
                              </option>
                            );
                          }
                        })}
                    </select>
                  </div>
                  <span
                    className="errorText"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "14px",
                    }}
                  >
                    {errors.function_id}
                  </span>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className={classes.btnWrapper}>
                <Button
                  type="button"
                  onClick={() => {
                    handleCloseModal();
                    setErrors({});
                  }}
                  className={classes.cancelBtn}
                >
                  Cancel
                </Button>

                <Button
                  type="button"
                  onClick={handleAddUrl}
                  className={classes.saveBtn}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default ManageActiveFunctionModal;
