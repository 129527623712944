const handlePhoneNumber = (payload) => {
  if (payload.phone_number) {
    payload.phone_number = payload.phone_number.slice(
      payload.country_phone.length - 1,
      payload.phone_number.length
    );
    
    payload.country_phone = payload.country_phone.slice(
      1,
      payload.country_phone.length
    );
  }
  else {
    payload.country_phone = "";
  }
}

export default handlePhoneNumber;