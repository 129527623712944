import React from "react";
import classes from "../../assets/styles/PublicNodes.module.css";
import { Image } from "react-bootstrap";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";
import baseURL from "../../utils/constants/baseUrl";
import { SHARE_QR } from "../../utils/constants/common";
import showToast from "../../helpers/showToast";

const QRNode = ({
  node,
  onNodeClick,
  nodeData,
  checkHierarchyTreeIDExists,
}) => {
  const treeIdExists = checkHierarchyTreeIDExists(node);

  const handleClick = () => {
    if (node?.team_member) {
      if (node.function_type !== null) {
        window.open(
          `${SHARE_QR}${
            node.function_type === "cash_app"
              ? "cashApp"
              : node.function_type === "custom_payment"
              ? "customPayment"
              : node.function_type === "custom_url"
              ? "customUrl"
              : node.function_type === "file_upload"
              ? "fileUpload"
              : node.function_type === "emergency_contact"
              ? "emergencyContact"
              : node.function_type
          }/${node?.function?.uuid}`,
          "_blank"
        );
      } else {
        showToast(false, "No active function found against this team member");
      }
    } else {
      onNodeClick(node);
    }
  };

  return (
    <div className={classes.nodeBox} onClick={handleClick}>
      <div className="d-flex align-items-center gap-2">
        {node.team_member ? (
          <>
            <div className={classes.teamMemberImage}>
              <Image
                src={
                  node.team_member.user.profile_image
                    ? `${baseURL.PUBLIC_URL}${node.team_member.user.profile_image}`
                    : dummyImage
                }
                alt={"Team Member Image"}
                roundedCircle
              />
            </div>
            <div>
              <p className={classes.nodeName}>{`${
                node.team_member.user.first_name || ""
              } ${node.team_member.user.last_name || ""}`}</p>

              <p className={classes.nodeDetail}>
                {node.team_member.user.email}
              </p>
            </div>
          </>
        ) : (
          <>
            {treeIdExists ? (
              <>
                <div className={classes.avatars}>
                  {node.children.slice(0, 3).map((memberData, index) => {
                    return (
                      <div
                        className={classes.avatarItem}
                        key={`${index} = ${memberData.id}`}
                      >
                        <Image
                          className={classes.avatar}
                          src={
                            memberData.team_member.user.profile_image
                              ? `${baseURL.PUBLIC_URL}${memberData.team_member.user.profile_image}`
                              : dummyImage
                          }
                          alt={"Team Member Image"}
                          roundedCircle
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  <p className={classes.nodeName}>{node.name}</p>
                  <p className={classes.nodeDetail}>
                    Members: {node.children.length}
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className={classes.nodeImage}>{node?.name?.charAt(0)}</div>

                <div>
                  <p className={classes.nodeName}>{node.name}</p>

                  <p className={classes.nodeDetail}>
                    {node.children.length === 0 && !node.team_member
                      ? "Members"
                      : "Sub Nodes"}
                    : {node.children.length}
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
    // <div className={classes.nodeBox} onClick={handleClick}>
    //   <div className="d-flex align-items-center gap-2">
    //     {node.image ? (
    //       <div className={classes.teamMemberImage}>
    //         <Image src={node.image} alt={node.name} roundedCircle />
    //       </div>
    //     ) : (
    //       <div className={classes.nodeImage}>{node.name.charAt(0)}</div>
    //     )}
    //     <div>
    //       <p className={classes.nodeName}>{node.name}</p>
    //       {node.email ? (
    //         <p className={classes.nodeDetail}>{node.email}</p>
    //       ) : null}
    //     </div>
    //   </div>
    // </div>
  );
};

export default QRNode;
