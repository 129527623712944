import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { get, post, put, deleteRecord } from "../../../server";
import { PRICING } from "../../../utils/constants/apiUrl";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../../components/Loader";
import showToast from "../../../helpers/showToast";
import { Link, useParams, useNavigate } from "react-router-dom";
import AddEditPriceSchema from "../../../utils/SchemasValidation/AddEditPriceSchema";
import ValidateSchema from "../../../helpers/ValidateSchema";
import Button from "react-bootstrap/Button";

const AddEditPricing = () => {
  let emptyObj = {
    title: "",
    description: "",
    amount: "",
  };

  const { priceId } = useParams();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [price, setPrice] = useState(emptyObj);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPrice({ ...price, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(price, AddEditPriceSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    if (priceId) {
      let { status, message, data } = await put(
        PRICING + `/${priceId}`,
        price,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        navigate("/pricing");
      }
      showToast(status, message);
    } else {
      let { status, message, data } = await post(
        PRICING,
        price,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        navigate("/pricing");
      }
      showToast(status, message);
    }
    setLoading(false);
  };

  const loadSinglePricing = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      PRICING + `/${priceId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setPrice(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (priceId) loadSinglePricing();
  }, []);
  return (
    <>
      {!loading ? (
        <div>
          <h6> {priceId ? "Edit" : "Add"} Subscription</h6>
          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className="row gx-5 gy-3">
              <div className="col-md-6 col-12">
                <label htmlFor="">Title</label>
                <input
                  type="text"
                  placeholder="Subscription Title"
                  name="title"
                  value={price.title}
                  className={
                    errors?.title ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.title || ""}</p>
              </div>
              <div className="col-md-6 col-12">
                <label htmlFor="">Amount</label>
                <input
                  type="number"
                  placeholder="Enter Amount"
                  name="amount"
                  value={price.amount}
                  className={
                    errors?.amount ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.amount || ""}</p>
              </div>
              <div className="col-12">
                <label htmlFor="">Description</label>
                <textarea
                  name="description"
                  id=""
                  cols="100"
                  rows="10"
                  value={price.description}
                  placeholder="Add Description"
                  onChange={handleChange}
                  className={
                    errors?.description
                      ? "globalTextArea errorInput"
                      : "globalTextArea"
                  }
                />
                <p className="errorText">{errors?.description || ""}</p>
              </div>
            </div>
            <div className="formBottom">
              <Button
                className="submitButton"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <style type="text/css">
        {`
        label {
          display: block;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 10px;
        }
        }
	`}
      </style>
    </>
  );
};

export default AddEditPricing;
