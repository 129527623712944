import React, { useState, useEffect } from "react";
import classes from "../../assets/styles/Stats.module.css";
import nfcscannedIcon from "../../assets/images/function_page_icons/nfcscanned.svg";
import qrcodescanned from "../../assets/images/function_page_icons/qrcodescanned.svg";
import qr from "../../assets/images/function_page_icons/qr.svg";
import { useAtom } from "jotai";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import { get } from "../../server";
import Loader from "../../components/Loader";
import showToast from "../../helpers/showToast";
import { TEAM_STATS } from "../../utils/constants/apiUrl";
import StatsDetail from "./StatsDetail";

const Stats = () => {
  const [loggedUser] = useAtom(loggedInUser);
  const [userTeamId] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(false);
  const [statsData, setStatsData] = useState({});
  const [isNFCStatsActive, setIsNFCStatsActive] = useState(true);
  const [isQRCodeStatsActive, setIsQRCodeStatsActive] = useState(false);
  const [isFunctionsStatsActive, setIsFunctionsStatsActive] = useState(false);

  const handleNFCStats = () => {
    setIsQRCodeStatsActive(false);
    setIsFunctionsStatsActive(false);

    setIsNFCStatsActive(!isNFCStatsActive);
  };
  const handleQRCodeStats = () => {
    setIsNFCStatsActive(false);
    setIsFunctionsStatsActive(false);
    setIsQRCodeStatsActive(!isQRCodeStatsActive);
  };
  const handleFunctionsStats = () => {
    setIsNFCStatsActive(false);
    setIsQRCodeStatsActive(false);
    setIsFunctionsStatsActive(!isFunctionsStatsActive);
  };

  const getStats = async () => {
    setLoading(true);

    let { status, message, data } = await get(
      `${TEAM_STATS}/${userTeamId || null}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setStatsData(data);
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  useEffect(() => {
    getStats();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="mt-3">
        <h6 className={classes.title}>Stats</h6>
      </div>

      <div className={classes.boxWrapper}>
        <div
          className={`d-flex align-items-center gap-2 ${classes.mainWrapper}`}
        >
          <div
            className={classes.statsBoxWrapper}
            onClick={handleNFCStats}
            style={{
              border: isNFCStatsActive
                ? "2px solid #22a081"
                : "2px solid #333e56",
            }}
          >
            <div>
              <img src={nfcscannedIcon} alt="Icon" width="50px" height="50px" />
            </div>
            <div>
              <h6 className={classes.counter}>
                {statsData.totalTeamNfcStatCount}
              </h6>
              <h6 className={classes.totalTite}>Total NFC's Scanned</h6>
            </div>
          </div>

          <div
            className={classes.statsBoxWrapper}
            onClick={handleQRCodeStats}
            style={{
              border: isQRCodeStatsActive
                ? "2px solid #22a081"
                : "2px solid #333e56",
            }}
          >
            <div>
              <img src={qr} alt="Icon" width="50px" height="50px" />
            </div>
            <div>
              <h6 className={classes.counter}>
                {statsData.totalTeamQRStatCount}
              </h6>
              <h6 className={classes.totalTite}>Total Team QR Codes Scanned</h6>
            </div>
          </div>

          <div
            className={classes.statsBoxWrapper}
            onClick={handleFunctionsStats}
            style={{
              border: isFunctionsStatsActive
                ? "2px solid #22a081"
                : "2px solid #333e56",
            }}
          >
            <div>
              <img src={qrcodescanned} alt="Icon" width="50px" height="50px" />
            </div>
            <div>
              <h6 className={classes.counter}>
                {statsData.totalFunctionQRStatCount}
              </h6>
              <h6 className={classes.totalTite}>Total QR Functions Scanned</h6>
            </div>
          </div>
        </div>

        {isNFCStatsActive ? (
          <StatsDetail data={statsData.totalTeamNfcStat} isNFC={true} />
        ) : null}
        {isQRCodeStatsActive ? (
          <StatsDetail data={statsData.totalTeamQRStat} isQRCode={true} />
        ) : null}
        {isFunctionsStatsActive ? (
          <StatsDetail data={statsData.totalFunctionQRStat} />
        ) : null}
      </div>
    </>
  );
};

export default Stats;
