import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import classes from "../assets/styles/DashboardLayout.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { mobileViewCheck } from "../utils/store";
const DashboardLayout = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [mobileView, setMobileView] = useAtom(mobileViewCheck);

  const resizeFunction = () => {
    if (window.innerWidth >= 900) {
      setMobileView(false);
    } else {
      setMobileView(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeFunction);

    if (window.innerWidth >= 960) {
      setMobileView(false);
    } else {
      setMobileView(true);
    }

    return function cleanup() {
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);

  const navigate = useNavigate();
  // if (Object.keys(loggedUser).length > 0) {
  //   navigate("/");
  // }

  const handleShowSidebar = () => {
    if (isSidebarCollapsed) {
      let collapsdSideMenu = document.querySelector(".collapsedSidebar");
      collapsdSideMenu.classList.add("show");
      setShowBackDrop(true);
      // setShowBackDrop((preValue) => !preValue);
    } else {
      let sideMenu = document.querySelector(".sidebar");
      sideMenu.classList.add("show");
      setShowBackDrop(true);
      // setShowBackDrop((preValue) => !preValue);
    }
  };

  const handleHideSidebar = () => {
    if (isSidebarCollapsed) {
      let collapsdSideMenu = document.querySelector(".collapsedSidebar");
      collapsdSideMenu.classList.remove("show");
      setShowBackDrop(false);
      // setShowBackDrop((preValue) => !preValue);
    } else {
      let sideMenu = document.querySelector(".sidebar");
      sideMenu.classList.remove("show");
      setShowBackDrop(false);

      // setShowBackDrop((preValue) => !preValue);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          position: "relative",
        }}
      >
        <Sidebar
          isSidebarCollapsed={isSidebarCollapsed}
          setIsSidebarCollapsed={setIsSidebarCollapsed}
          handleHideSidebar={handleHideSidebar}
        />
        <div
          className={isSidebarCollapsed ? classes.collapsedMain : classes.main}
        >
          <Navbar
            isSidebarCollapsed={isSidebarCollapsed}
            setShowBackDrop={setShowBackDrop}
            handleShowSidebar={handleShowSidebar}
          />
          <div
            className={showBackDrop ? "backdrop open" : "backdrop"}
            onClick={handleHideSidebar}
          ></div>
          <div
            className="container-fluid py-4 px-3"
            style={{ minHeight: "calc(100vh - 60px)" }}
          >
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DashboardLayout;
