const Joi = require("joi");

const PasswordSchema = Joi.object({
  current_password: Joi.string().required().messages({
    "string.empty": `Please enter valid current password`,
  }),

  password: Joi.string().min(8).required().messages({
    "string.empty": `Please enter valid new password`,
    "string.min": `New password must be 8 characters long!`,
  }),

  confirm_password: Joi.string().required().valid(Joi.ref("password")).messages({
    "string.empty": `Please enter valid confirm password`,
    "any.only": `Confirm password must match new password`,
  }),
});

export default PasswordSchema;
