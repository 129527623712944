import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { get } from "../../../server";
import { PRICING, PACKAGE } from "../../../utils/constants/apiUrl";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../../components/Loader";
import showToast from "../../../helpers/showToast";
import { Link, useParams, useNavigate } from "react-router-dom";
import AddEditPriceSchema from "../../../utils/SchemasValidation/AddEditPriceSchema";
import ValidateSchema from "../../../helpers/ValidateSchema";
import Button from "react-bootstrap/Button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddEditPackageContact = () => {
  let emptyObj = {
    title: "",
    description: "",
    email: "",
  };

  const { contactId } = useParams();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [packageContact, setPackageContact] = useState(emptyObj);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPackageContact({ ...packageContact, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(packageContact, AddEditPriceSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!validateForm()) return;

  //   setLoading(true);
  //   if (contactId) {
  //     let { status, message, data } = await put(
  //       PRICING + `/${contactId}`,
  //       packageContact,
  //       Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //     );
  //     if (status) {
  //       navigate("/pricing");
  //     }
  //     showToast(status, message);
  //   } else {
  //     let { status, message, data } = await post(
  //       PRICING,
  //       packageContact,
  //       Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //     );
  //     if (status) {
  //       navigate("/pricing");
  //     }
  //     showToast(status, message);
  //   }
  //   setLoading(false);
  // };

  const loadPackageContact = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      `${PACKAGE}/getPackageContactForm/${contactId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setPackageContact(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (contactId) loadPackageContact();
  }, []);
  return (
    <>
      {!loading ? (
        <div>
          <h6> View Package Contact </h6>
          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className="row gx-5 gy-3">
              <div className="col-md-6 col-12">
                <label htmlFor="">Title</label>
                <input
                  type="text"
                  readOnly
                  placeholder="Subscription Title"
                  name="title"
                  value={packageContact.title}
                  className={
                    errors?.title ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.title || ""}</p>
              </div>
              <div className="col-md-6 col-12">
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  placeholder="Enter email"
                  name="email"
                  value={packageContact.email}
                  className={
                    errors?.email ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.email || ""}</p>
              </div>
              <div className="col-12">
                <label htmlFor="">Description</label>
                <textarea
                  name="description"
                  id=""
                  readOnly
                  cols="100"
                  rows="10"
                  value={packageContact.description}
                  placeholder="Add Description"
                  onChange={handleChange}
                  className={
                    errors?.description
                      ? "globalTextArea errorInput"
                      : "globalTextArea"
                  }
                />
                <p className="errorText">{errors?.description || ""}</p>
              </div>
            </div>
            {/* <div className="formBottom">
              <Button
                className="submitButton"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div> */}
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <style type="text/css">
        {`
        label {
          display: block;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 10px;
        }
        }
	`}
      </style>
    </>
  );
};

export default AddEditPackageContact;
