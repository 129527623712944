import React from "react";
import Form from "react-bootstrap/Form";
import classes from "../../assets/styles/Forms/FormInput.module.css";
import { BsFillPersonFill } from "react-icons/bs";
const FormInput = ({
  dateTypeInput,
  icon,
  label,
  type,
  placeholder,
  name,
  value,
  handleChange,
  error,
  iconOnClick = null,
  autofocus = false,
  disabled = false,
  socialProfiles = null,
  ...props
}) => {
  return (
    <>
      <div style={{ textAlign: "start !important" }}>
        {label ? <label className={classes.label}>{label}</label> : null}
        <div
          className={
            error
              ? "input-group globalInputWithIcon errorInput"
              : "input-group  globalInputWithIcon"
          }
        >
          {icon ? (
            <span className="input-group-text">
              <img
                src={icon}
                alt=""
                onClick={iconOnClick}
                style={iconOnClick ? { cursor: "pointer" } : null}
              />
            </span>
          ) : null}

          <input
            type={type ? type : "text"}
            className={`${error ? "form-control inputError" : "form-control"} ${
              dateTypeInput ? "customDateIcon" : ""
            }`}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            // autoComplete="off"
            autoComplete={`new-${name}`}
            autoFocus={autofocus}
            readOnly={disabled}
            {...props}
          />
        </div>
        <p className="errorText">{error || ""}</p>
      </div>
    </>
  );
};

export default FormInput;
