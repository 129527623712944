import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAtom } from "jotai";
import { loggedInUser, isOwnerPackage } from "../utils/store";

const CheckTeamOwnerPackage = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [isOwnerPackageActive, setIsOwnerPackageActive] =
    useAtom(isOwnerPackage);
  const location = useLocation();

  return loggedUser?.role?.includes("team_owner") &&
    isOwnerPackageActive == false ? (
    <Navigate to="/paymentNotPaid" state={{ from: location }} replace />
  ) : loggedUser?.id ? (
    ""
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default CheckTeamOwnerPackage;
