import React, { useState, useEffect, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import DataTable, { createTheme } from "react-data-table-component";
import { useAtom } from "jotai";
import classes from "../../assets/styles/Contact.module.css";
import {
  INVITATION,
  SEND_INVITATION,
  RESEND_INVITATION,
} from "../../utils/constants/apiUrl";
import { get, post, deleteRecord } from "../../server";
import showToast from "../../helpers/showToast";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import Loader from "../../components/Loader";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import FormInput from "../../components/Forms/FormInput";
import searchIcon from "../../assets/images/global_icons/searchicon.svg";
import InvitationsAddModal from "../../components/Modals/InvitationsAddModal";
import _ from "lodash";

const emptyInvitationData = {
  email: "",
};
const Invitations = () => {
  const [loggedUser] = useAtom(loggedInUser);
  const [userTeamId] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyInvitationData);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleResendNotification = async (uid) => {
    setLoading(true);
    let payload = {};
    payload.uuid = uid;

    let { status, message } = await post(
      RESEND_INVITATION,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    setLoading(false);
    showToast(status, message);
  };

  const handleSaveShowModal = async () => {
    setModalLoading(true);

    let payload = _.cloneDeep(selectedData);
    payload.team_id = userTeamId || null;

    let { status, message } = await post(
      SEND_INVITATION,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      await getInvitations();
    }
    setModalLoading(false);
    showToast(status, message);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setSelectedData({});
    setShowModal(false);
  };

  const getInvitations = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      INVITATION,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setInvitations(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getInvitations();
  }, []);

  const filteredItems = useMemo(() => {
    const filtered = invitations.filter(
      (item) =>
        `${item.email || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.is_signup == 1 ? "yes" : "no" || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, invitations]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: #21293B",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Email",
      cell: (row) => (
        <a
          href={`mailto:${row.email}`}
          className="globalDetail"
          style={{ display: "block" }}
        >
          {row.email}
        </a>
      ),
    },
    {
      name: "Signup Status",
      cell: (row) => (
        <p className="globalDetail">{row.is_signup === 0 ? "No" : "Yes"}</p>
      ),
    },

    {
      name: "Action",
      width: "20%",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <span
                className="dropdown-item"
                onClick={() => handleResendNotification(row?.uuid)}
              >
                Resend Invitation
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </span>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${INVITATION}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await getInvitations();
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div className="">
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center gap-3">
              <p className="pageTitle">
                Invitations <span>({invitations.length})</span>
              </p>

              <div>
                <button className="primary_button" onClick={handleOpenModal}>
                  Send Invitation
                </button>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-2">
              <FormInput
                icon={searchIcon}
                placeholder="Search..."
                value={filterText}
                handleChange={handleFilter}
              />
            </div>
          </div>

          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              theme="solarized"
              pagination
            />
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="invitation"
          />
        </div>
      ) : (
        <Loader />
      )}

      <InvitationsAddModal
        show={showModal}
        data={selectedData}
        setSelectedData={setSelectedData}
        handleSaveShowModal={async () => {
          await handleSaveShowModal(selectedData);
          handleCloseModal();
        }}
        handleCloseModal={handleCloseModal}
        loading={modalLoading}
      />
    </>
  );
};

export default Invitations;
