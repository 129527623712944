import React, { useState, useEffect } from "react";
import classes from "../../assets/styles/Pages/Help/Faqs.module.css";
import Accordion from "react-bootstrap/Accordion";
import { SETTING } from "../../utils/constants/apiUrl";
import { get } from "../../server";
import showToast from "../../helpers/showToast";
import Loader from "../../components/Loader";
import faq_image from "../../assets/images/help/faq_image.png";
import { Link } from "react-router-dom";
const Faqs = () => {
  const [loading, setLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);

  const loadFaqs = async () => {
    setLoading(true);
    let { status, message, data } = await get(SETTING + "/faq");
    if (status) {
      if (data.length > 0) {
        setFaqs(data);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadFaqs();
  }, []);
  return (
    <>
      <div className="helpBackLinkWrapper">
        <Link to="/help">Back</Link>
      </div>
      <div className={classes.helpHeader}>
        <div className="row flex-md-row flex-column-reverse gy-4">
          {/* Faqs top left section */}
          <div className="col-sm-6">
            <h3 className="helpHeading">FAQ’s</h3>
            <p className="helpSubHeading">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et
              exercitationem quam repudiandae necessitatibus
            </p>
            <p className="helpDetail">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et
              exercitationem quam repudiandae necessitatibus Lorem, ipsum dolor
              sit amet consectetur adipisicing elit. Et exercitationem quam
              repudiandae necessitatibus
            </p>
          </div>

          {/*Right Section Image */}
          <div className="col-sm-6">
            <div style={{ position: "relative" }}>
              <img src={faq_image} alt="" className="helpImage" />
            </div>
          </div>
        </div>
      </div>

      {/* Second Section */}
      {!loading ? (
        <div className={classes.helpbottom}>
          <div className={classes.mainWrapper}>
            <Accordion>
              {faqs.map((faq, index) => {
                return (
                  <>
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{faq.question}</Accordion.Header>
                      <Accordion.Body>{faq.answer}</Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}
            </Accordion>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <style type="text/css">
        {`
        .accordion-button{
          background-color: #19202E;
          color: #ffffff;
          border: 0px solid;
          line-height: 24px;
        }
        
        .accordion-button.collapsed{
          background-color: #19202E;
          color: #535F79;
          border: 0px solid;
          font-weight: 600;
        }
        .accordion-button:focus{
          box-shadow:unset
        }
        .accordion-button:not(.collapsed) {
          background-color: #19202E;
          color: #fff;
          border: 0px solid;
          font-weight: 600;
        }

        .accordion-collapse {
          background-color: #19202E;
          color: #ffffff;
          font-size: 14px;
          font-weight: 600;
        }

        .accordion{
          --bs-accordion-border-color: none;
          --bs-accordion-bg:none;
          --bs-accordion-btn-icon:url(BsChevronDown)
        }
        .accordion-item{
          border: 1px solid #333E56 !important ;
          border-radius: 10px !important;
          padding: 10px 0px;
          margin-bottom: 20px;
          background-color: #19202E;
        }

        .accordion-body{
          color:#ffffff;
          font-size: 14px;
          line-height: 21px;
        }

        .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23535F79'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
	`}
      </style>
    </>
  );
};

export default Faqs;
