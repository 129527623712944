import React, { useEffect, useState } from "react";
import classes from "../../assets/styles/PublicNodes.module.css";
import { useParams } from "react-router-dom";
import { get } from "../../server";
import { HIERARCHY_TREE } from "../../utils/constants/apiUrl";
import { loggedInUser } from "../../utils/store";
import { useAtom } from "jotai";
import showToast from "../../helpers/showToast";
import Loader from "../../components/Loader";
import QRNode from "./QRNode";

const PublicTeamNode = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [teamData, setTeamData] = useState({ team: {}, node: [] });

  const { teamId } = useParams();

  const [selectedNode, setSelectedNode] = useState(null);
  const [parentNodes, setParentNodes] = useState([]);

  function checkHierarchyTreeIDExists(data) {
    const childrenArray = data?.children;
    return childrenArray?.some((child) =>
      child.hasOwnProperty("hierarchy_tree_id")
    );
  }

  const handleNodeClick = (node) => {
    if (node.children.length === 0 && !node.team_member) {
      showToast(
        false,
        "This node neither has any child node nor any team member"
      );
    } else {
      setParentNodes((prevNode) => [...prevNode, selectedNode]);
      setSelectedNode(node);
    }
  };

  const handleBackClick = () => {
    if (parentNodes.length > 0) {
      const previousParentNode = parentNodes.pop(); // Remove the last element from the parentNodes array
      setSelectedNode(previousParentNode); // Set the previous parent node as the selectedNode
      setParentNodes([...parentNodes]); // Update the parentNodePath array
    } else {
      setSelectedNode(null); // If parentNodePath is empty, set selectedNode to null (indicating the root level)
    }
  };

  // Define a recursive function to traverse the node array
  function traverseNode(node, teamMembers) {
    // Loop through each node object
    for (let i = 0; i < node?.length; i++) {
      // Get the current node object
      let currentNode = node[i];
      // Loop through each team member object
      for (let j = 0; j < teamMembers.length; j++) {
        // Get the current team member object
        let currentTeamMember = teamMembers[j];
        // Compare the id of the node object with the hierarchy tree id of the team member object
        if (currentNode.id === currentTeamMember.hierarchy_tree_id) {
          // Push the team member object into the children key of the node object
          currentNode?.children?.push(currentTeamMember);
        }
      }
      // Check if the node object has any children
      if (currentNode?.children?.length > 0) {
        // Call the function recursively on the children array
        traverseNode(currentNode.children, teamMembers);
      }
    }
  }

  const getPublicNodes = async () => {
    setLoading(true);

    let { status, message, data } = await get(
      `${HIERARCHY_TREE}/${teamId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      // Call the function on the node array and the team members array
      traverseNode(data.node, data.team_members);
      setTeamData({ ...teamData, team: data.team, node: data.node });
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  useEffect(() => {
    getPublicNodes();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="py-5">
        <div className={classes.mainWrapper}>
          <h6 className={classes.title}>Public Node</h6>
          <div className={classes.profileCardWrapper}>
            <h6 className={classes.teamName}>{teamData?.team?.name}</h6>
            {selectedNode ? (
              <>
                <div className={classes.backBtnWrapper}>
                  <button onClick={handleBackClick}>Back</button>
                </div>
                <h6 className={classes.companyName}>
                  {selectedNode.name} ({selectedNode.children.length})
                </h6>

                <div className={classes.childNodes}>
                  {selectedNode.children.map((childNode) => (
                    <QRNode
                      key={childNode.id}
                      node={childNode}
                      onNodeClick={handleNodeClick}
                      nodeData={teamData}
                      checkHierarchyTreeIDExists={checkHierarchyTreeIDExists}
                    />
                  ))}
                </div>
              </>
            ) : (
              <>
                {teamData?.node?.map((node) => (
                  <QRNode
                    key={node.id}
                    node={node}
                    onNodeClick={handleNodeClick}
                    nodeData={teamData}
                    checkHierarchyTreeIDExists={checkHierarchyTreeIDExists}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default PublicTeamNode;
