import React, { useState } from "react";
// import playstore from "../../../assets/images/home_page_images/playstore.svg";
import playstore from "../assets/images/home_page_images/playstore.svg";
import appstore from "../assets/images/home_page_images/appstore.svg";
import { ReactComponent as Hero } from "../assets/images/home_page_images/heroimage.svg";
import classes from "../assets/styles/HeroSection.module.css";

import remove from "../assets/images/home_page_images/remove.svg";

import { Link } from "react-router-dom";
const HeroSection = () => {
  const [removeHeroSection, setRemoveHeroSection] = useState(false);
  const handleRemoveHeroSection = () => {
    setRemoveHeroSection(true);
  };

  return (
    <>
      <div
        className={
          removeHeroSection
            ? classes.removeHeroSectionWrapper
            : classes.heroSectionWrapper
        }
      >
        <div className="row flex-md-row flex-column-reverse p-relative gy-md-0 gy-3">
          <div className="col-md-6 col-12">
            <div>
              <h5 className={classes.title}>CO-N-Tact Mobile App</h5>
              <p className={classes.description}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className={classes.imagesWrapper}>
              <div>
                <a href="https://play.google.com/store/apps" target="_blank">
                  <img src={playstore} alt="" />
                </a>
              </div>
              <div>
                <a href="https://www.apple.com/app-store/" target="_blank">
                  <img src={appstore} alt="" />
                </a>
              </div>
            </div>
            <div className={classes.downloadBtnWrapper}>
              <Link to="#">
                <button className={classes.downloadBtn}>Download App</button>
              </Link>
              <Link to="#">
                <button className={classes.cancelBtn}>Learn More</button>
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className={classes.heroImgWrapper}>
              <Hero />
              {/* <img src={hero} alt="" className={classes.heroImage} /> */}
            </div>
          </div>
        </div>
        <img
          src={remove}
          alt=""
          className={classes.heroSectionRemoveBtn}
          onClick={handleRemoveHeroSection}
        />
      </div>
    </>
  );
};

export default HeroSection;
