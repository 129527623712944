import classes from "../assets/styles/AddNewEmergencyContact.module.css";
import addcontact from "../assets/images/home_page_images/addcontact.svg";
import EmergencyAddEditModal from "./Modals/EmergencyAddEditModal";
import useFunctionsCount from "../hooks/useFunctionsCount";
import functionTypes from "../utils/constants/functionTypes";
import showToast from "../helpers/showToast";

const AddNewEmergencyContact = ({
  show,
  loading,
  handleSaveShowModal,
  selectedData,
  setSelectedData,
  handleOpenModel,
  handleCloseModal,
}) => {
  const { compareLimits, updateCount } = useFunctionsCount();
  const handleShowEmergencyContactModal = () => {
    const isLimitExceeded = compareLimits(functionTypes.EMERGENCY_CONTACT);
    if (isLimitExceeded)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    handleOpenModel();
  };

  return (
    <>
      <div
        className={classes.addContentWrapper}
        onClick={handleShowEmergencyContactModal}
      >
        <img src={addcontact} alt="" />
        <p>Add New</p>
      </div>

      <EmergencyAddEditModal
        loading={loading}
        show={show}
        data={selectedData}
        setSelectedData={setSelectedData}
        handleSaveShowModal={handleSaveShowModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default AddNewEmergencyContact;
