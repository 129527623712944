import React from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { MdContentCopy } from "react-icons/md";
import classes from "../../assets/styles/ShareModal.module.css";
import { SHARE_QR } from "../../utils/constants/common";

const ShareModal = ({
  target,
  shareOpen,
  handleShareClose,
  setShowCopyPopup,
  functionType,
  functionUuid,
  url,
}) => {
  return (
    <>
      <Modal
        show={shareOpen}
        onHide={handleShareClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span>Share</span>
          <MdClose onClick={handleShareClose} />
        </Modal.Header>

        <Modal.Body
          style={{ margin: "0 0px 20px 0px" }}
          className={`d-flex justify-content-center flex-column align-items-center`}
        >
          <div className={classes.linkWrapper}>
            <span>
              {url ? url : `${SHARE_QR}${functionType}/${functionUuid}`}
            </span>
            <div ref={target}>
              <MdContentCopy
                onClick={() => {
                  setShowCopyPopup(true);
                  setTimeout(() => {
                    setShowCopyPopup(false);
                  }, 2000);
                  navigator.clipboard.writeText(
                    url ? url : `${SHARE_QR}${functionType}/${functionUuid}`
                  );
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareModal;
