import React, { useEffect, useMemo, useRef, useState } from "react";
import classes from "../assets/styles/EditCardFunctions.module.css";
import FormInput from "../components/Forms/FormInput";
import baseURL from "../utils/constants/baseUrl";
import { IoDocumentTextSharp, IoShareSocial } from "react-icons/io5";
import { FiMail, FiTrash2 } from "react-icons/fi";
import { MdLocationOn } from "react-icons/md";
import {
  BsGlobe,
  BsImageFill,
  BsFillPersonFill,
  BsFillTelephoneFill,
} from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { TbBuildingCommunity } from "react-icons/tb";
import ImageUpload from "../components/ImageUpload";
import Joi from "joi";
import {
  CardBackGroundImages,
  Colors,
  AddMoreCustomUrls,
} from "../utils/data/CardFunctionsData";
import PhoneInput from "react-phone-input-2";
import Modal from "react-bootstrap/Modal";
import "react-phone-input-2/lib/material.css";
import { useDropzone } from "react-dropzone";
import { SketchPicker } from "react-color";
import _ from "lodash";
import Card from "../components/PageComponents/Function/Card";
import { get, put } from "../server";
import { Card_URL } from "../utils/constants/apiUrl";
import { loggedInUser, teamIDCheck, subscribedPackage } from "../utils/store";
import { useAtom } from "jotai";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import showToast from "../helpers/showToast";
import AddEditCardSchema from "../utils/SchemasValidation/AddEditCardSchema";
import ValidateSchema from "../helpers/ValidateSchema";
import uploadImage from "../helpers/uploadImage";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import socialMediaLinksSchema from "../utils/SchemasValidation/ModalSocialMediaLinksSchema";
import { ReactComponent as PersonIcon } from "../assets/images/edit_card_contact_icons/person.svg";
import { ReactComponent as LocationIcon } from "../assets/images/edit_card_contact_icons/location.svg";
import { ReactComponent as BioIcon } from "../assets/images/edit_card_contact_icons/bio.svg";
import { ReactComponent as PhoneIcon } from "../assets/images/edit_card_contact_icons/phone.svg";
import { ReactComponent as ShareIcon } from "../assets/images/edit_card_contact_icons/share.svg";
import { ReactComponent as WebsiteURLIcon } from "../assets/images/edit_card_contact_icons/website_url.svg";
import { ReactComponent as CompanyBuildingIcon } from "../assets/images/edit_card_contact_icons/company_building.svg";
import { ReactComponent as EmailIcon } from "../assets/images/edit_card_contact_icons/email.svg";
import { ReactComponent as BackgroundIcon } from "../assets/images/edit_card_contact_icons/background.svg";
import { formatUrl, validatePhoneWithCountryCode } from "../utils/functions";
import pictureicon from "../assets/images/function_page_icons/picIcon.svg";
import Loveicon from "../assets/images/home_page_images/love.svg";
import companylogo from "../assets/images/function_page_icons/companylogo.svg";
import handlePhoneNumber from "../helpers/handlePhoneNumber";
import crossicon from "../assets/images/global_icons/crossicon.svg";
import Select from "../components/Select";
import CropImageModal from "../components/Modals/CropImageModal";

const EditCardFunctions = () => {
  const [cardFunction, setCardFunction] = useState({
    card_type: "",
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    company_position: "",
    logo: "",
    background_image: "",
    profile_image: "",
    color: "",
    country_phone: "",
    phone_number: "",
    description: "",
    address: "",
    country: "",
    city: "",
    state: "",
    website_url: "",
    card_social_media: [],
    pronouns: "",
    card_phone: [],
    team_id: null,
    user_id: null,
  });

  const [value, setValue] = useState();
  const [errors, setErrors] = useState({});
  const [mediaLinkErrors, setMediaLinkErrors] = useState([]);

  const [loading, setLoading] = useState(false);
  const [mediaOpen, setMediaOpen] = useState(false);
  const [uploadImageOpen, setUploadImageOpen] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [cardViewOpen, setCardViewOpen] = useState(true);
  const [customMediaLinkOpen, setCustomMediaLinkOpen] = useState(false);

  const [modalMediaLinks, setModalMediaLinks] = useState([]);

  const [customColor, setCustomColor] = useState(false);
  const [customBackgroundImage, setCustomBackgroundImage] = useState(false);

  const [imageUploadKey, setImageUploadKey] = useState("");

  const [preview, setPreview] = useState(null);
  const [result, setResult] = useState(null);
  const [fileData, setFileData] = useState("");
  const [crop, setCrop] = useState();
  const [showModal, setShowModal] = useState(false);
  const [cropType, setCropType] = useState("");

  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);

  const [customPronoun, setCustomPronoun] = useState("");

  const [customLabel, setCustomLabel] = useState([
    {
      index_no: "",
      label: "",
    },
  ]);

  const [customMediaLink, setCustomMediaLink] = useState("");
  const [customMediaLinkError, setCustomMediaLinkError] = useState({});
  const [pronounCheckBox, setPronounCheckBox] = useState(false);

  const profileRef = useRef(null);
  const logoRef = useRef(null);

  const navigate = useNavigate();
  const { teamId, userId, id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");

  const handleCloseModal = () => {
    setCrop();
    setShowModal(false);
  };

  useEffect(() => {
    if (result) {
      if (cropType == "profile") {
        setCardFunction({ ...cardFunction, profile_image: fileData });
      } else {
        setCardFunction({ ...cardFunction, logo: fileData });
      }

      setCropType("");
    }
  }, [result]);

  const handleCustomPronounChange = (e) => {
    setCustomPronoun(e.target.value);
  };

  const pronounsOptions = [
    { value: "she/her", label: "She/Her" },
    { value: "he/him", label: "He/Him" },
    { value: "they/them", label: "They/Them" },
    { value: "custom", label: "Custom" },
  ];

  const handlePhoneTypeChange = (e, index) => {
    if (
      e.target.value === "custom" &&
      purchasedPackage &&
      purchasedPackage.id == 1
    ) {
      showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
      return;
    }

    const newArray = cardFunction.card_phone.map((item, i) => {
      if (index === i) {
        return { ...item, phone_type: e.target.value };
      } else {
        return item;
      }
    });
    setCardFunction({
      ...cardFunction,
      card_phone: newArray,
    });
  };

  // handle phone type input change
  const handlePhoneNumberChange = (value, country, index) => {
    const newArray = cardFunction.card_phone.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          phone_number: value,
          country_phone: `${country.dialCode}`,
        };
      } else {
        return item;
      }
    });
    setCardFunction({
      ...cardFunction,
      card_phone: newArray,
    });
  };

  const handleCustomLabel = (e, index) => {
    const newArray = cardFunction.card_phone.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          custom_label: e.target.value,
        };
      } else {
        return item;
      }
    });
    setCardFunction({
      ...cardFunction,
      card_phone: newArray,
    });
  };

  const addPhoneFormField = () => {
    setCardFunction({
      ...cardFunction,
      ...cardFunction.card_phone.push({
        phone_type: "",
        country_phone: "",
        phone_number: "",
        custom_label: "",
      }),
    });
  };

  const removePhoneFormField = (i) => {
    let removeCardPhone = [...cardFunction.card_phone];
    removeCardPhone.splice(i, 1);
    setCardFunction({ ...cardFunction, card_phone: removeCardPhone });
    setErrors({});
  };

  const filteredMediaLinks = useMemo(() => {
    const filteredLinks = _.cloneDeep(AddMoreCustomUrls);

    const newFilteredLinks = [];
    filteredLinks.forEach((item) => {
      const found = modalMediaLinks.find(
        (single) => single.media_type === item.name
      );

      if (!found || item.name === "custom") {
        newFilteredLinks.push(item);
      }
    });

    return newFilteredLinks;
  }, [modalMediaLinks]);

  useEffect(() => {
    fetchSingleCard();
  }, []);

  useEffect(() => {
    if (cardFunction.pronouns) {
      setPronounCheckBox(true);
    }
  }, [cardFunction.pronouns]);

  const fetchSingleCard = async () => {
    setLoading(true);
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }

    let { status, message, data } = await get(
      `${Card_URL}/${id}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      let card_social_media = [];

      data.cardSocialMedia.forEach((item) => {
        let foundIcon = AddMoreCustomUrls.find(
          (single) => single.name === item.media_type
        );

        card_social_media.push({
          media_type: item.media_type,
          url: item.url,
          icon: item.media_type === "custom" ? "custom" : foundIcon?.icon,
          custom_label: item.custom_label,
        });
      });

      const bgFound = CardBackGroundImages.find(
        (item) => data.background_image == item.img
      );

      if (!bgFound) {
        setCustomBackgroundImage(true);
      }

      let newCardPhone = [];
      data.cardPhone.forEach((singlePhone, index) => {
        const customOrNot = ["home", "office"].includes(singlePhone.phone_type);

        newCardPhone.push({
          country_phone: singlePhone.country_phone,
          phone_number: singlePhone.phone_number
            ? `${singlePhone.country_phone ? singlePhone.country_phone : ""}${
                singlePhone.phone_number
              }`
            : "",
          phone_type: customOrNot ? singlePhone.phone_type : "custom",
          custom_label: customOrNot ? "" : singlePhone.phone_type,
        });
      });

      const customPronounsOrNot = [
        null,
        "",
        "she/her",
        "he/him",
        "they/them",
      ].includes(data.pronouns);

      if (!customPronounsOrNot) {
        setCustomPronoun(data.pronouns);
      } else {
        setCustomPronoun("");
      }

      if (data.pronouns) {
        setPronounCheckBox(true);
      }

      setCardFunction({
        card_type: data.card_type,
        first_name: data.first_name ? data.first_name : "",
        last_name: data.last_name ? data.last_name : "",
        email: data.email ? data.email : "",
        company: data.company ? data.company : "",
        company_position: data.company_position ? data.company_position : "",
        logo: data.logo ? data.logo : "",
        background_image: data.background_image ? data.background_image : "",
        profile_image: data.profile_image ? data.profile_image : "",
        color: data.color ? data.color : "",
        country_phone: data.country_phone ? `+${data.country_phone}` : "",
        phone_number: data.phone_number
          ? `${data.country_phone ? data.country_phone : ""}${
              data.phone_number
            }`
          : "",
        description: data.description ? data.description : "",
        address: data.address ? data.address : "",
        country: data.country ? data.country : "",
        city: data.city ? data.city : "",
        state: data.state ? data.state : "",
        website_url: data.website_url ? data.website_url : "",
        card_social_media: card_social_media,
        pronouns: customPronounsOrNot ? data.pronouns : "custom",
        card_phone: newCardPhone,
        team_id: data.team_id ? data.team_id : null,
        user_id: data.user_id ? data.user_id : null,
      });
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  const handleImageChange = (files) => {
    const selectedFile = files[0];
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

    if (selectedFile.size > maxSizeInBytes) {
      showToast(
        false,
        "The selected image size exceeds the maximum limit of 10MB."
      );
      return;
    }

    setCardFunction({ ...cardFunction, [imageUploadKey]: selectedFile });

    if (imageUploadKey === "profile_image") {
      setPreview(URL.createObjectURL(selectedFile));
      setShowModal(true);
      setCropType("profile");
    }
    if (imageUploadKey === "logo") {
      setPreview(URL.createObjectURL(selectedFile));
      setShowModal(true);
      setCropType("logo");
    }

    if (imageUploadKey === "background_image") {
      setCustomBackgroundImage(true);
    }

    handleUploadImageClose();
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleImageChange,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const handleMediaOpen = () => {
    setModalMediaLinks(_.cloneDeep(cardFunction.card_social_media));
    setMediaOpen(true);
  };

  const handleMediaClose = () => {
    setModalMediaLinks([]);
    setMediaLinkErrors({});
    setMediaOpen(false);
  };

  const handleColorPickerOpen = () => setColorPickerOpen(true);

  const handleColorPickerClose = () => setColorPickerOpen(false);

  const handleUploadImageOpen = (key) => {
    if (
      key === "background_image" &&
      purchasedPackage &&
      purchasedPackage.id == 1
    ) {
      showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
      return;
    }

    setUploadImageOpen(true);
    setImageUploadKey(key);
  };

  const handleUploadImageClose = () => {
    setUploadImageOpen(false);
    setImageUploadKey("");
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (
      name === "pronouns" &&
      value === "custom" &&
      purchasedPackage &&
      purchasedPackage.id == 1
    ) {
      setCardFunction({ ...cardFunction, pronouns: "" });
      showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
      return;
    }

    setCardFunction({ ...cardFunction, [name]: value });
  };

  const handleMediaChange = (e) => {
    if (
      e.target.value === "custom" &&
      purchasedPackage &&
      purchasedPackage.id == 1
    ) {
      showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
      return;
    }

    if (e.target.value === "custom") {
      setMediaOpen(false);
      setCustomMediaLinkOpen(true);
    }

    const found = AddMoreCustomUrls.find(
      (item) => item.name === e.target.value
    );

    let newSocialMediaLinks = _.cloneDeep(modalMediaLinks);

    newSocialMediaLinks.push({
      media_type: found.name,
      url: "",
      icon: found.name === "custom" ? "custom" : found.icon,
      custom_label: "",
    });

    setModalMediaLinks(newSocialMediaLinks);
  };

  const validateCustomMediaLink = () => {
    let newErrors = ValidateSchema(
      { customMediaLink },
      Joi.object({
        customMediaLink: Joi.string().max(50).required().messages({
          "string.empty": `Please enter Custom Media Name`,
          "string.max": `Custom Media Name must be maximum 50 characters!`,
        }),
      })
    );

    setCustomMediaLinkError(newErrors);

    if (Object.keys(newErrors).length) return false;

    return true;
  };

  const handleCustomMediaNameSave = () => {
    if (!validateCustomMediaLink()) return;

    let newSocialMediaLinks = _.cloneDeep(modalMediaLinks);

    newSocialMediaLinks[newSocialMediaLinks.length - 1].custom_label =
      customMediaLink;

    setModalMediaLinks(newSocialMediaLinks);
    setCustomMediaLinkOpen(false);
    setCustomMediaLink("");
    setMediaOpen(true);
  };

  const handleCustomMediaModalClose = () => {
    setCustomMediaLinkOpen(false);

    let newSocialMediaLinks = _.cloneDeep(modalMediaLinks);
    newSocialMediaLinks.splice(newSocialMediaLinks.length - 1, 1);
    setModalMediaLinks(newSocialMediaLinks);

    setMediaOpen(true);
  };

  const handleModalUrlChange = (e, index) => {
    let newSocialMediaLinks = _.cloneDeep(modalMediaLinks);

    newSocialMediaLinks[index].url = formatUrl(e.target.value);

    setModalMediaLinks(newSocialMediaLinks);
  };

  const handleMedialSave = () => {
    if (!validateMediaModalForm()) return;

    setCardFunction({ ...cardFunction, card_social_media: modalMediaLinks });
    handleMediaClose();
  };

  const handleModalMediaDelete = (index) => {
    let newSocialMediaLinks = _.cloneDeep(modalMediaLinks);
    newSocialMediaLinks.splice(index, 1);
    setModalMediaLinks(newSocialMediaLinks);
  };

  const validateForm = () => {
    let newErrors = ValidateSchema(cardFunction, AddEditCardSchema);

    setErrors(newErrors);

    if (Object.keys(newErrors).length) return false;

    return true;
  };

  const validateMediaModalForm = () => {
    let newErrors = ValidateSchema(modalMediaLinks, socialMediaLinksSchema);

    setMediaLinkErrors(newErrors);

    if (Object.keys(newErrors).length) return false;

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    // Validate phone number
    setErrors({});
    if (cardFunction.pronouns === "custom" && customPronoun.length > 20) {
      setErrors({
        custom_pronouns: "Custom pronouns can be max 20 characters long",
      });
      return;
    }

    for (let index = 0; index < cardFunction.card_phone.length; index++) {
      const data = cardFunction.card_phone[index];
      if (data.phone_type || data.phone_number) {
        if (data.phone_type === "") {
          setErrors({
            [`phone_number${index}`]: "Please select phone type",
          });
          return;
        }

        if (data.phone_number === "") {
          setErrors({
            [`phone_number${index}`]: "Please enter phone number",
          });
          return;
        }

        if (data.phone_number.length > data.country_phone.length) {
          let resultPhone = validatePhoneWithCountryCode(
            data.country_phone,
            data.phone_number
          );
          if (resultPhone) {
            setErrors({
              [`phone_number${index}`]: resultPhone,
            });
            return false;
          }
        }

        if (data.phone_type === "custom" && data.custom_label === "") {
          setErrors({
            [`lable${index}`]: "Please enter custom phone",
          });
          return;
        }

        if (data.phone_type === "custom" && data.custom_label.length > 20) {
          setErrors({
            [`lable${index}`]: "Custom Label can be max 20 characters long",
          });
          return;
        }
      }
    }

    setLoading(true);

    let payload = _.cloneDeep(cardFunction);

    // Handle website Url
    payload.website_url = formatUrl(payload.website_url);

    if (typeof payload.background_image == "object") {
      const { status, message, data } = await uploadImage(
        "card_background",
        payload.background_image,
        loggedUser?.token
      );

      if (status) {
        payload.background_image = data;
      } else {
        showToast(status, message);
        setLoading(false);
        return;
      }
    }

    if (typeof payload.profile_image == "object") {
      const { status, message, data } = await uploadImage(
        "card_profile",
        payload.profile_image,
        loggedUser?.token
      );

      if (status) {
        payload.profile_image = data;
      } else {
        showToast(status, message);
        setLoading(false);
        return;
      }
    }

    if (typeof payload.logo == "object" && payload.card_type === "business") {
      const { status, message, data } = await uploadImage(
        "card_logo",
        payload.logo,
        loggedUser?.token
      );

      if (status) {
        payload.logo = data;
      } else {
        showToast(status, message);
        setLoading(false);
        return;
      }
    }

    if (payload.card_type === "personal") {
      payload.logo = "";
    }

    payload.card_social_media.forEach((item) => {
      delete item.icon;
    });

    if (payload.card_phone.length > 0) {
      let cardPhoneArr = payload.card_phone.map((item, index) => {
        if (item.phone_type === "custom") {
          return {
            ...item,
            phone_type: item.custom_label ? item.custom_label : "custom",
            phone_number: item.phone_number.slice(
              item.country_phone.length,
              item.phone_number.length
            ),
          };
        } else {
          return {
            ...item,
            phone_number: item.phone_number.slice(
              item.country_phone.length,
              item.phone_number.length
            ),
          };
        }
      });

      cardPhoneArr.forEach((object) => {
        delete object["custom_label"];
      });

      cardPhoneArr = cardPhoneArr.filter(function (el) {
        return el.phone_number != "";
      });

      payload.card_phone = cardPhoneArr;
    }

    if (payload.pronouns === "custom") {
      payload.pronouns = customPronoun;
    }

    if (payload.pronouns === "custom" && customPronoun === "") {
      payload.pronouns = "";
    }

    let { status, message, data } = await put(
      `${Card_URL}/${id}`,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (teamId && userId) {
        navigate(-1);
      } else {
        navigate(
          `/function/cards${
            team_id && user_id ? `?team_id=${team_id}&user_id=${user_id}` : ""
          }`
        );
      }
    }

    showToast(status, message);

    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }
  const handleShowSidebar = () => {
    let cardView = document.querySelector(".cardView");
    cardView.classList.add("showCardView");
  };

  const handleHideCardView = () => {
    let cardView = document.querySelector(".cardView");
    cardView.classList.remove("showCardView");
  };

  const handleGoBack = () => {
    navigate(-2);
  };

  return (
    <>
      <div className={classes.outerWrapper}>
        <div className="col-md-8 col-12">
          <div className="row">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className={classes.title}>
                {teamId && userId ? (
                  <Link onClick={handleGoBack} style={{ color: "#ffffff" }}>
                    Functions
                  </Link>
                ) : (
                  <Link to="/function" style={{ color: "#ffffff" }}>
                    Functions
                  </Link>
                )}

                {" > Cards"}
              </h6>
              <div className="view">
                <button onClick={handleShowSidebar}>View Card</button>
              </div>
            </div>
          </div>

          <div className={classes.mainWrapper}>
            <div className="row gy-3">
              <div className="col-xl-6 col-12">
                <div className="row mb-2">
                  <span className={classes.imageHeaderLabel}>Your Photo</span>
                </div>

                <div className="row align-items-center">
                  <div className="col-4">
                    <ImageUpload
                      imageLink={true}
                      imageUrl={cardFunction.profile_image}
                      width="72px"
                      height="72px"
                      enableClick={false}
                      dummyImage={pictureicon}
                    />
                  </div>

                  <div className="col-8">
                    <button
                      className={classes.uploadBtn}
                      // onClick={() => profileRef.current.click()}
                      onClick={() => handleUploadImageOpen("profile_image")}
                    >
                      Upload New
                    </button>
                    <input
                      accept="image/*"
                      type="file"
                      name="profile_image"
                      ref={profileRef}
                      style={{ display: "none" }}
                      onChange={(e) =>
                        setCardFunction({
                          ...cardFunction,
                          profile_image: e.target.files[0],
                        })
                      }
                    />
                    <button
                      className={classes.removeImgBtn}
                      onClick={() =>
                        setCardFunction({ ...cardFunction, profile_image: "" })
                      }
                    >
                      Remove Profile Picture
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-12">
                {cardFunction.card_type === "business" ? (
                  <>
                    <div className="row mb-2">
                      <span className={classes.imageHeaderLabel}>Logo</span>
                    </div>

                    <div className="row">
                      <div className="col-4">
                        <ImageUpload
                          imageLink={true}
                          imageUrl={cardFunction.logo}
                          width="72px"
                          height="72px"
                          enableClick={false}
                          dummyImage={companylogo}
                        />
                      </div>

                      <div className="col-8 align-items-center">
                        <button
                          className={classes.uploadBtn}
                          onClick={() => handleUploadImageOpen("logo")}
                        >
                          Upload New
                        </button>
                        <input
                          accept="image/*"
                          type="file"
                          name="logo"
                          ref={logoRef}
                          style={{ display: "none" }}
                          onChange={(e) =>
                            setCardFunction({
                              ...cardFunction,
                              logo: e.target.files[0],
                            })
                          }
                        />
                        <button
                          className={classes.removeImgBtn}
                          onClick={() =>
                            setCardFunction({ ...cardFunction, logo: "" })
                          }
                        >
                          Remove Company Logo
                        </button>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <hr className={classes.divider} />

            <div className="row">
              <div className="col-12">
                <div className={classes.colorWrapper}>
                  {Colors.map((color) => {
                    const { id, bgColor } = { ...color };
                    return (
                      <div
                        key={`color${id}`}
                        style={{ backgroundColor: bgColor }}
                        className={`${classes.colorDiv} ${
                          cardFunction.color == bgColor
                            ? classes.selectedColorOption
                            : null
                        }`}
                        onClick={() =>
                          setCardFunction({ ...cardFunction, color: bgColor })
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className={classes.sectionHeader}>
                  <div className={classes.sectionIconHeader}>
                    <BackgroundIcon />
                  </div>
                  <span>Background</span>
                </div>
                <div className={classes.backgroundWrapper}>
                  {CardBackGroundImages.map((image) => {
                    const { id, img } = { ...image };
                    return (
                      <img
                        key={`backgroundImage${id}`}
                        src={`${baseURL.PUBLIC_URL}${img}`}
                        alt=""
                        className={
                          cardFunction.background_image == img
                            ? classes.selectedOption
                            : null
                        }
                        onClick={() => {
                          if (!customBackgroundImage) {
                            setCardFunction({
                              ...cardFunction,
                              background_image: img,
                            });
                          }
                        }}
                      />
                    );
                  })}

                  {cardFunction.background_image && customBackgroundImage ? (
                    <div className={classes.customBackgroundWrapper}>
                      <img
                        src={
                          cardFunction.background_image
                            ? typeof cardFunction.background_image === "object"
                              ? `${URL.createObjectURL(
                                  cardFunction.background_image
                                )}`
                              : `${baseURL.PUBLIC_URL}${cardFunction.background_image}`
                            : ""
                        }
                        alt=""
                        className={classes.selectedOption}
                      />
                      <div className={classes.bgImgClearBtnWrapper}>
                        <MdClose
                          className={classes.bgImgClearBtn}
                          onClick={() => {
                            setCardFunction({
                              ...cardFunction,
                              background_image: CardBackGroundImages[0].img,
                            });
                            setCustomBackgroundImage(false);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={classes.emptyBackgroundImage}
                      onClick={() => handleUploadImageOpen("background_image")}
                    >
                      <span>+</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12">
                <div className="mb-4">
                  <div className={classes.sectionHeader}>
                    <div className={classes.sectionIconHeader}>
                      <PersonIcon />
                    </div>
                    <span>Full Name</span>
                  </div>
                  <FormInput
                    type="text"
                    name="first_name"
                    placeholder="First name"
                    value={cardFunction.first_name}
                    handleChange={handleChange}
                    error={errors.first_name}
                  />
                  <FormInput
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    value={cardFunction.last_name}
                    handleChange={handleChange}
                    error={errors.last_name}
                  />
                </div>
                <div className="mb-4">
                  <div>
                    <div
                      className={classes.sectionHeader}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div className={classes.sectionIconHeader}>
                          <PersonIcon />
                        </div>
                        <span>Pronouns</span>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          name="pronoun_check"
                          checked={pronounCheckBox}
                          onChange={(e) => {
                            setPronounCheckBox(e.target.checked);

                            if (!e.target.checked) {
                              setCardFunction({
                                ...cardFunction,
                                pronouns: "",
                              });
                              setCustomPronoun("");
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <Select
                        placeholder="Choose your pronouns"
                        options={pronounsOptions}
                        value={cardFunction.pronouns}
                        name="pronouns"
                        disabled={!pronounCheckBox}
                        onChange={handleChange}
                      />
                    </div>
                    {cardFunction.pronouns === "custom" && (
                      <>
                        <div
                          className={
                            errors.custom_pronouns
                              ? "input-group globalInputWithIcon errorInput"
                              : "input-group  globalInputWithIcon"
                          }
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <input
                            placeholder="Please Enter Custom Pronoun"
                            className={
                              errors.custom_pronouns
                                ? "form-control inputError"
                                : "form-control"
                            }
                            value={customPronoun}
                            onChange={handleCustomPronounChange}
                          />
                        </div>
                        <p className="errorText">
                          {errors.custom_pronouns || ""}
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <div className={classes.sectionHeader}>
                    <div className={classes.sectionIconHeader}>
                      <BioIcon />
                    </div>
                    <span>About me</span>
                  </div>
                  <textarea
                    // className={classes.textareaInput}
                    className={
                      errors.description
                        ? "globalTextArea errorInput"
                        : "globalTextArea"
                    }
                    type="text"
                    placeholder="Description"
                    name="description"
                    value={cardFunction.description}
                    onChange={handleChange}
                  ></textarea>
                </div>

                {/* <div className="mb-4">
                  <div className={classes.sectionHeader}>
                    <div className={classes.sectionIconHeader}>
                      <LocationIcon />
                    </div>
                    <span>Location</span>
                  </div>
                  <FormInput
                    type="text"
                    name="city"
                    placeholder="City"
                    value={cardFunction.city}
                    handleChange={handleChange}
                    error={errors.city}
                  />
                  <FormInput
                    type="text"
                    name="state"
                    placeholder="State"
                    value={cardFunction.state}
                    handleChange={handleChange}
                    error={errors.state}
                  />
                  <FormInput
                    type="text"
                    name="country"
                    placeholder="Country"
                    value={cardFunction.country}
                    handleChange={handleChange}
                    error={errors.country}
                  />
                </div> */}
              </div>

              <div className="col-md-6 col-12">
                {cardFunction.card_type === "business" ? (
                  <div className="mb-4">
                    <div className={classes.sectionHeader}>
                      <div className={classes.sectionIconHeader}>
                        <CompanyBuildingIcon />
                      </div>
                      <span>Company & Job Position</span>
                    </div>
                    <FormInput
                      type="text"
                      name="company"
                      placeholder="Company name"
                      value={cardFunction.company}
                      handleChange={handleChange}
                      error={errors.company}
                    />
                    <FormInput
                      type="text"
                      name="company_position"
                      placeholder="Position name"
                      value={cardFunction.company_position}
                      handleChange={handleChange}
                      error={errors.company_position}
                    />
                  </div>
                ) : null}

                {/* <div className="mb-4">
                  <div className={classes.sectionHeader}>
                    <div className={classes.sectionIconHeader}>
                      <PhoneIcon />
                    </div>
                    <span>Contact Number</span>
                  </div>
                  <PhoneInput
                    country={"us"}
                    onlyCountries={["us"]}
                    inputClass={
                      errors.phone_number
                        ? "phoneNumberInput errorInput"
                        : "phoneNumberInput"
                    }
                    value={cardFunction.phone_number}
                    onChange={(value, country, e, formattedValue) => {
                      setCardFunction({
                        ...cardFunction,
                        phone_number: value,
                        country_phone: `+${country.dialCode}`,
                      });
                    }}
                  />
                  <span className="errorText">{errors.phone_number}</span>
                </div> */}

                <div className="mb-4">
                  <div className={classes.sectionHeader}>
                    <div className={classes.sectionIconHeader}>
                      <EmailIcon />
                    </div>
                    <span>Email Address</span>
                  </div>
                  <FormInput
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={cardFunction.email}
                    handleChange={handleChange}
                    error={errors.email}
                  />
                </div>

                <div className="mb-4">
                  <div className={classes.sectionHeader}>
                    <div className={classes.sectionIconHeader}>
                      <WebsiteURLIcon />
                    </div>
                    <span>Website</span>
                  </div>
                  <FormInput
                    type="text"
                    name="website_url"
                    placeholder="Url"
                    value={cardFunction.website_url}
                    handleChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <div className={classes.sectionHeader}>
                    <div className={classes.sectionIconHeader}>
                      <LocationIcon />
                    </div>
                    <span>Location</span>
                  </div>
                  <FormInput
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={cardFunction.address}
                    handleChange={handleChange}
                    error={errors.address}
                  />
                  <FormInput
                    type="text"
                    name="city"
                    placeholder="City"
                    value={cardFunction.city}
                    handleChange={handleChange}
                    error={errors.city}
                  />
                  <FormInput
                    type="text"
                    name="state"
                    placeholder="State"
                    value={cardFunction.state}
                    handleChange={handleChange}
                    error={errors.state}
                  />
                  <FormInput
                    type="text"
                    name="country"
                    placeholder="Country"
                    value={cardFunction.country}
                    handleChange={handleChange}
                    error={errors.country}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className={classes.sectionHeader}>
                  <div className={classes.sectionIconHeader}>
                    <PhoneIcon />
                  </div>
                  <span>Contact Number</span>
                </div>
                <div style={{ maxWidth: "387px" }}>
                  {cardFunction.card_phone &&
                    cardFunction.card_phone?.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className={classes.phoneInputOuterWrapper}
                        >
                          <div
                            className={`d-flex align-items-center gap-1 ${classes.phoneInputMainWrapper}`}
                          >
                            <div
                              className={`d-flex align-items-center ${
                                errors[`phone_number${index}`]
                                  ? "phoneInputWrapper errorInput"
                                  : "phoneInputWrapper "
                              } ${classes.phoneInputMainWrapper}`}
                            >
                              <div
                                className="input-group globalInputWithIcon addCardSelect"
                                style={{ maxWidth: "100px" }}
                              >
                                <select
                                  style={{ border: "0" }}
                                  className="form-select customSelect"
                                  onChange={(e) =>
                                    handlePhoneTypeChange(e, index)
                                  }
                                  value={element.phone_type}
                                >
                                  <option value="">Select</option>
                                  <option value="home">Home</option>
                                  <option value="office">Office</option>
                                  <option value="custom">Custom</option>
                                </select>
                              </div>
                              <div className="addPhoneInput">
                                <PhoneInput
                                  country={"us"}
                                  enableSearch={true}
                                  countryCodeEditable={false}
                                  inputClass={
                                    errors[`phone_number${index}`]
                                      ? "phoneNumberInput errorInput"
                                      : "phoneNumberInput "
                                  }
                                  value={element.phone_number}
                                  onChange={(value, country) =>
                                    handlePhoneNumberChange(
                                      value,
                                      country,
                                      index
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className={classes.removePhonutBtnWrapper}>
                              <button
                                onClick={() => removePhoneFormField(index)}
                              >
                                <FiTrash2 />
                              </button>
                            </div>
                          </div>
                          <p className="errorText">
                            {errors[`phone_number${index}`]}
                          </p>
                          {element.phone_type === "custom" && (
                            <div
                              className={classes.formInputWrapper}
                              style={{ marginTop: "0px" }}
                            >
                              <label
                                className={classes.inputLabel}
                                style={{ fontSize: "14px" }}
                              >
                                Custom Label
                              </label>
                              <FormInput
                                type="text"
                                name={errors[`lable${index}`]}
                                placeholder={`Enter Phone Label`}
                                value={element.custom_label}
                                error={errors[`lable${index}`]}
                                onChange={(e) => handleCustomLabel(e, index)}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}

                  <div>
                    <div className={classes.addPhonutBtnWrapper}>
                      <button onClick={() => addPhoneFormField()}>
                        Add Phone
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className={classes.sectionHeader}>
                  <div className={classes.sectionIconHeader}>
                    <ShareIcon />
                  </div>
                  <span>Social Media</span>
                </div>
                <div className={classes.socialMediaWrapper}>
                  {cardFunction.card_social_media.map((single, index) => (
                    <div
                      key={`socialMediaLink${index}`}
                      className={classes.addedSocialLinks}
                    >
                      {single.icon === "custom"
                        ? single.custom_label.charAt(0)
                        : single.icon}
                    </div>
                  ))}
                  <div
                    onClick={handleMediaOpen}
                    className={classes.addMoreSocialLinks}
                  >
                    +
                  </div>
                </div>
                <div className={classes.submitBtnWrapper}>
                  <button onClick={handleSubmit} className={classes.submitBtn}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12 cardView">
          <Card
            data={{
              ...cardFunction,
              pronouns:
                cardFunction.pronouns === "custom"
                  ? customPronoun
                  : cardFunction.pronouns,
            }}
            handleClose={handleHideCardView}
          />
        </div>
      </div>

      <Modal
        show={customMediaLinkOpen}
        onHide={handleCustomMediaModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.mediaModalHeader}>
          <span>Custom Media Link Name</span>
          <img
            src={crossicon}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleCustomMediaModalClose}
          />
          {/* <GrClose onClick={handleUploadImageClose} /> */}
        </Modal.Header>

        <Modal.Body className={classes.mediaModalBody}>
          <FormInput
            type="text"
            placeholder="Custom Link Name"
            value={customMediaLink}
            handleChange={(e) => setCustomMediaLink(e.target.value)}
            error={customMediaLinkError.customMediaLink}
          />
        </Modal.Body>

        <Modal.Footer className={classes.mediaModalFooter}>
          <button onClick={handleCustomMediaNameSave}>Confirm</button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={mediaOpen}
        onHide={handleMediaClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.mediaModalHeader}>
          <span>Social Links</span>
          <img
            src={crossicon}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleMediaClose}
          />
          {/* <GrClose onClick={handleMediaClose} /> */}
        </Modal.Header>

        <Modal.Body className={classes.mediaModalBody}>
          <p>
            Add your social links that will be visible on the homepage of your
            blog.
          </p>

          {/* <select
            className={classes.select}
            value=""
            onChange={handleMediaChange}
          >
            <option value="" selected disabled>
              Add Social Link
            </option>
            {filteredMediaLinks.map((single, index) => (
              <option value={single.name} key={`mediaOption${index}`}>
                {single.name}
              </option>
            ))}
          </select> */}
          <div className="mt-4">
            <Select
              placeholder="Add Social Link"
              options={filteredMediaLinks}
              onChange={(e) => {
                handleMediaChange(e);
              }}
              labelKey="name"
              valueKey="name"
            />
          </div>

          {modalMediaLinks.map((single, index) => (
            <>
              <div
                className={classes.socialMediaModalLink}
                key={`cardModalLink${index}`}
                style={
                  mediaLinkErrors[`${index}.url`]
                    ? { borderColor: "#e35252" }
                    : null
                }
              >
                <div className={classes.socialMediaModalLinkIcon}>
                  {single.icon === "custom"
                    ? single.custom_label.charAt(0)
                    : single.icon}
                </div>
                <input
                  defaultValue={single.url}
                  placeholder="Url"
                  onChange={(e) => handleModalUrlChange(e, index)}
                ></input>
                <FiTrash2
                  onClick={() => handleModalMediaDelete(index)}
                  className={classes.socialLinkDeleteIcon}
                />
              </div>
              {mediaLinkErrors[`${index}.url`] ? (
                <div style={{ textAlign: "start" }}>
                  <span className="errorText">
                    {mediaLinkErrors[`${index}.url`]}
                  </span>
                </div>
              ) : null}
            </>
          ))}
        </Modal.Body>

        <Modal.Footer className={classes.mediaModalFooter}>
          <button onClick={handleMedialSave}>Save</button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={uploadImageOpen}
        onHide={handleUploadImageClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.mediaModalHeader}>
          <span>Upload</span>
          <AiOutlineClose onClick={handleUploadImageClose} />
        </Modal.Header>

        <Modal.Body className={classes.mediaModalBody}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div
              className={`d-flex justify-content-center align-items-center flex-column ${classes.imageUpload}`}
            >
              <BsImageFill />
              <span>
                Drop your Images here, or <span>Browse</span>
              </span>
              <span>Support JPG, PNG, Only</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={colorPickerOpen}
        onHide={handleColorPickerClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.mediaModalHeader}>
          <span>Color Picker</span>
          <AiOutlineClose onClick={handleColorPickerClose} />
        </Modal.Header>

        <Modal.Body
          className={`d-flex justify-content-center ${classes.mediaModalBody}`}
        >
          <SketchPicker
            color={cardFunction.color}
            onChangeComplete={(color) => {
              setCardFunction({ ...cardFunction, color: color.hex });
              setCustomColor(true);
            }}
          />
        </Modal.Body>
      </Modal>

      <style type="text/css">
        {`
        
        .phoneNumberInput {
      font-size: 14px !important;
}
.addPhoneInput .react-tel-input .form-control{
  padding: 18.5px 5px 18.5px 52px;
  border: 0 !important;
}

      .react-tel-input .selected-flag{
      padding: 0 0 0 12px;
}
.customSelect.form-select{
  padding: 9px 14px;

}
.phoneInputWrapper{
    border: 2px solid #333e56;
    border-radius: 25px;
}
.phoneInputWrapper.errorInput {
  border: 2px solid #e35252 !important;
}
.phoneInputWrapper:focus-within {
  border: 2px solid #22a081;
}
.addCardSelect.globalInputWithIcon{
  border: 0 !important;
  border-radius: 0;
  border-right: 2px solid #333e56 !important;
}
	`}
      </style>

      {preview && (
        <CropImageModal
          show={showModal}
          image={preview}
          handleCloseModal={handleCloseModal}
          result={result}
          setResult={setResult}
          setFileData={setFileData}
          crop={crop}
          setCrop={setCrop}
        />
      )}
    </>
  );
};

export default EditCardFunctions;
