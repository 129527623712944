import { useState, useEffect } from "react";
import {
  USERNFC,
  EMERGENCY_CONTACT_VCF_FILE_URL,
} from "../../../utils/constants/apiUrl";
import { get, post } from "../../../server/index";
import showToast from "../../../helpers/showToast";
import Loader from "../../Loader";
import Card from "../Function/Card";
import { AddMoreCustomUrls } from "../../../utils/data/CardFunctionsData";
import personicon from "../../../assets/images/global_icons/personicon.png";
import { Link, useParams } from "react-router-dom";
import { Image } from "react-bootstrap";
import FormInput from "../../Forms/FormInput";
import baseURL from "../../../utils/constants/baseUrl";
import classes from "../../../assets/styles/Pages/Function/ViewEmergencyContact.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import dummyImage from "../../../assets/images/global_icons/dummyimage.png";
import contacticon from "../../../assets/images/function_page_icons/contacticon.svg";
import playstore from "../../../assets/images/footer_icons/playstore.svg";
import appstore from "../../../assets/images/footer_icons/appstore.svg";
import _ from "lodash";
import formatPhoneNumber from "../../../helpers/formatPhoneNumber";
const PublicUserNFC = () => {
  const [loading, setLoading] = useState(false);
  const [userNFC, setUserNFC] = useState({});
  const [VCFData, setVCFData] = useState({});
  const [functionType, setFunctionType] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);

  const { uuid } = useParams();

  const [showFullText, setShowFullText] = useState(false);
  const displayText = showFullText
    ? userNFC?.description
    : userNFC?.description?.length > 60
    ? userNFC?.description?.slice(0, 60) + "..."
    : userNFC?.description;

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const fetchUserNFC = async () => {
    setLoading(true);

    let { status, message, data } = await get(`${USERNFC}/${uuid}`);

    setVCFData(data.data);
    if (status) {
      if (
        [
          "cash_app",
          "venmo",
          "custom_url",
          "file_upload",
          "custom_payment",
        ].includes(data.function_type)
      ) {
        window.location.replace(data.data.url_path);
      } else {
        let function_type = data.function_type;
        data = data.data;

        setFunctionType(function_type);

        if (function_type === "card") {
          let card_social_media = [];

          data.cardSocialMedia.forEach((item) => {
            let foundIcon = AddMoreCustomUrls.find(
              (single) => single.name === item.media_type
            );

            card_social_media.push({
              media_type: item.media_type,
              url: item.url,
              icon: item.media_type === "custom" ? "custom" : foundIcon?.icon,
              custom_label: item.custom_label,
            });
          });

          setUserNFC({
            card_type: data.card_type,
            first_name: data.first_name ? data.first_name : "",
            last_name: data.last_name ? data.last_name : "",
            email: data.email ? data.email : "",
            company: data.company ? data.company : "",
            company_position: data.company_position
              ? data.company_position
              : "",
            logo: data.logo ? data.logo : "",
            background_image: data.background_image
              ? data.background_image
              : "",
            profile_image: data.profile_image ? data.profile_image : "",
            color: data.color ? data.color : "",
            country_phone: data.country_phone ? `+${data.country_phone}` : "",
            phone_number: data.phone_number
              ? `${data.country_phone ? data.country_phone : ""}${
                  data.phone_number
                }`
              : "",
            description: data.description ? data.description : "",
            address: data.address ? data.address : "",
            country: data.country ? data.country : "",
            city: data.city ? data.city : "",
            state: data.state ? data.state : "",
            website_url: data.website_url ? data.website_url : "",
            pronouns: data.pronouns ? data.pronouns : "",
            card_social_media: card_social_media,
            card_phone: data.cardPhone,
          });
        } else if (function_type === "emergency_contact") {
          // let newObj = _.cloneDeep(data);

          // if (newObj.phone_number) {
          //   newObj.phone_number = `${
          //     newObj.country_phone ? newObj.country_phone : ""
          //   }${newObj.phone_number}`;
          // }

          // if (newObj.country_phone) {
          //   newObj.country_phone = `+${newObj.country_phone}`;
          // }

          setUserNFC(data);
        }
      }
    } else {
      // error message
      showToast(status, message);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchUserNFC();
  }, []);

  const handleVcfFile = async () => {
    let { status, message, data } = await post(
      EMERGENCY_CONTACT_VCF_FILE_URL + `/${VCFData.uuid}`,
      VCFData
    );
    if (status) {
      const link = document.createElement("a");
      link.download = "temp.vcf";
      link.href = data.url;
      link.click();
    } else {
      showToast(status, message);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {functionType === "card" ? (
        <div
          className="py-5"
          style={{
            backgroundColor: "#19202e",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="col-lg-4 col-md-5 col-12">
            <Card
              data={userNFC}
              inViewMode={true}
              showCountryCodeSeparate={true}
            />
          </div>
        </div>
      ) : functionType === "emergency_contact" ? (
        <div className="container">
          <div className="py-5">
            <div className={classes.mainWrapper}>
              <h6 className={classes.title}>Emergency Contact </h6>
              <div className={classes.cardWrapper}>
                <div className="">
                  <div className={classes.profileCardWrapper}>
                    <div className={classes.imgWrapper}>
                      <Image
                        className="globalIMageFit"
                        src={
                          userNFC.image
                            ? `${baseURL.PUBLIC_URL}${userNFC.image}`
                            : dummyImage
                        }
                        alt="img"
                        roundedCircle={true}
                        onLoad={() => setImageLoaded(true)}
                      />
                    </div>

                    <div className={classes.detailWrapper}>
                      <h6>{`${userNFC.first_name || ""} ${
                        userNFC.last_name || ""
                      }`}</h6>

                      <p>{`+${userNFC.country_phone}${formatPhoneNumber(
                        userNFC.phone_number
                      )}`}</p>
                      <p className={classes.description}>
                        {displayText}

                        {userNFC?.description?.length > 60 && (
                          <button onClick={toggleText}>
                            {showFullText ? "Show Less" : "Read More"}
                          </button>
                        )}
                      </p>
                    </div>
                    <div
                      className={` text-center ${classes.savePhonebookBtnWrapper}`}
                    >
                      <a
                        href={`tel: ${`+${userNFC.country_phone}${userNFC.phone_number}`}`}
                      >
                        <button>Call Now</button>
                      </a>
                      <button onClick={handleVcfFile}>
                        <span>
                          <img src={contacticon} alt="" />
                        </span>
                        Save Contact
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.cardFooter}>
                <div className="d-flex align-items-center gap-3 justify-content-center">
                  <a href="https://play.google.com/store/apps" target="_blank">
                    <img src={playstore} alt="" />
                  </a>
                  <a href="https://www.apple.com/app-store/" target="_blank">
                    <img src={appstore} alt="" />
                  </a>
                </div>
                <p>
                  © {new Date().getFullYear()} Contact, All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PublicUserNFC;
