import React, { useState, useEffect, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { useAtom } from "jotai";
import classes from "../../../assets/styles/Contact.module.css";
import dummyImage from "../../../assets/images/no_image.jpg";
import { TUTORIAL } from "../../../utils/constants/apiUrl";
import { get, deleteRecord } from "../../../server";
import showToast from "../../../helpers/showToast";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import Image from "react-bootstrap/Image";
import baseUrl from "../../../utils/constants/baseUrl";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";

const AllTutorial = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [tutorial, setTutorial] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filterText, setFilterText] = useState("");

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const loadTutorial = async () => {
    setLoading(true);
    let { status, message, data } = await get(TUTORIAL);
    if (status) {
      if (data.length > 0) {
        setTutorial(data);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadTutorial();
  }, []);

  const filteredItems = useMemo(() => {
    const filtered = tutorial.filter(
      (item) =>
        `${item.title || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.url || ""}`.toLowerCase().includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, tutorial]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: #21293B",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Image",
      width: "10%",
      cell: (row) => (
        <Image
          className="globalIMageBorder"
          src={baseUrl.PUBLIC_URL + row.image || dummyImage}
          roundedCircle={true}
          width="50px"
          height="50px"
          onError={(e) => {
            e.currentTarget.src = dummyImage;
          }}
        />
      ),
    },
    {
      name: "Title",
      width: "25%",
      cell: (row) => <div className={"globalDetail"}>{row.title}</div>,
    },
    {
      name: "Url",
      width: "25%",
      cell: (row) => (
        <a
          href={row.url}
          className={"globalDetail"}
          target="_blank"
          style={{ display: "block" }}
        >
          {row.url}
        </a>
      ),
    },
    {
      name: "App Walk Through",
      width: "20%",
      cell: (row) => (
        <div className={"globalDetail"}>
          {row.start_up_video === 1 ? "Yes" : "No"}
        </div>
      ),
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link className="dropdown-item" to={`addEditTutorial/${row.id}`}>
                Edit
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${TUTORIAL}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await loadTutorial();
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Tutorials <span>({tutorial.length})</span>
              </p>

              <div className="d-flex align-items-center justify-content-between gap-2">
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />

                <Link to="/tutorial/addEditTutorial">
                  <button className="primary_button">Add</button>
                </Link>
              </div>
            </div>
          </div>

          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              theme="solarized"
            />
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="tutorial"
          />
        </div>
      ) : (
        <Loader />
      )}

      <style type="text/css">
        {`
	.profileWrapper {
		display: flex;
		align-items: center;
		gap: 20px;
	}
	.profileWrapper img {
		border: 2px solid #535f79;
	}
	`}
      </style>
    </>
  );
};

export default AllTutorial;
