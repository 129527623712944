import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SecondLoader from "../SecondLoader";
import classes from "../../assets/styles/CustomURLModal.module.css";
import { MdClose } from "react-icons/md";
import _ from "lodash";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import showToast from "../../helpers/showToast";

const CropImageModal = ({
  show,
  handleCloseModal,
  image,
  setResult,
  result,
  setFileData,
  crop,
  setCrop,
}) => {
  const [imageRef, setImageRef] = useState(null);

  const cropImageNow = () => {
    if (!imageRef || !crop || !crop.width || !crop.height) {
      handleCloseModal();
      // showToast(false, "Please select an area to crop image");
      return;
    }

    const canvas = document.createElement("canvas");
    const scaleX = imageRef.naturalWidth / imageRef.width;
    const scaleY = imageRef.naturalHeight / imageRef.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      imageRef,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    let base64Image = canvas.toDataURL("image/jpeg");
    setResult(base64Image);
    const file = base64ToFile(base64Image, "cropped-image.jpg");
    setFileData(file);
    handleCloseModal();
  };

  function base64ToFile(base64Image, fileName) {
    const byteString = atob(base64Image.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: "image/jpeg" });

    return new File([blob], fileName, { type: "image/jpeg" });
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleCloseModal();
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>Crop Image</span>
          <div className="modalGradientCircle"></div>
          <MdClose
            onClick={() => {
              handleCloseModal();
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>

        <Modal.Body>
          <div style={{ marginTop: "-140px" }}>
            <div className=" w-100 text-center">
              {image && (
                <>
                  <ReactCrop
                    crop={crop}
                    onChange={setCrop}
                    aspect={1}
                    src={image}
                  >
                    <img
                      src={image}
                      className="img-fluid "
                      alt="Image Preview"
                      onLoad={(e) => {
                        setImageRef(e.target);
                      }}
                    />
                  </ReactCrop>
                </>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className={classes.btnWrapper}>
            <Button
              type="button"
              onClick={() => {
                handleCloseModal();
              }}
              className={classes.cancelBtn}
            >
              Don't Crop
            </Button>

            <Button
              type="button"
              className={classes.saveBtn}
              onClick={cropImageNow}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CropImageModal;
