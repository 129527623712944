const Joi = require("joi");
const PackageContactFormSchema = Joi.object({
  id: Joi.allow(null),
  title: Joi.string().min(3).max(50).required().messages({
    "string.empty": `Please enter title`,
    "string.min": `Title must be minimum 50 characters!`,
    "string.max": `Title must be maximum 50 characters!`,
  }),
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),
  description: Joi.string().min(20).required().messages({
    "string.empty": `Please enter description`,
    "string.min": `Description must be minimum 20 characters!`,
  }),
});

export default PackageContactFormSchema;
