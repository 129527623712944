import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/Pages/Function/CustomURLSListing.module.css";
import ShowEmergencyFunctions from "../../components/PageComponents/Function/ShowEmergencyFunctions";
import Loader from "../../components/Loader";
import { useAtom } from "jotai";
import { get, deleteRecord, post, put } from "../../server";
import { EMERGENCY_CONTACT } from "../../utils/constants/apiUrl";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import { toast } from "react-toastify";
import showToast from "../../helpers/showToast";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import EmergencyAddEditModal from "../../components/Modals/EmergencyAddEditModal";
import addcontact from "../../assets/images/home_page_images/addcontact.svg";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import FormInput from "../../components/Forms/FormInput";
import searchIcon from "../../assets/images/global_icons/searchicon.svg";
import _ from "lodash";
import uploadImage from "../../helpers/uploadImage";
import EmptyListingBanner from "../../components/EmptyListingBanner";
import handlePhoneNumber from "../../helpers/handlePhoneNumber";
import useFunctionsCount from "../../hooks/useFunctionsCount";
import functionTypes from "../../utils/constants/functionTypes";

const EmergencyContactsListing = () => {
  let emptyEmergencyContactData = {
    isNew: true,
    id: "",
    first_name: "",
    last_name: "",
    country_phone: "",
    phone_number: "",
    image: "",
    description: "",
  };

  const navigate = useNavigate();
  const { teamId, userId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");

  const [loading, setLoading] = useState(true);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyEmergencyContactData);
  const [filterText, setFilterText] = useState("");
  const { compareLimits, updateCount } = useFunctionsCount();

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  // Handle main modal
  const handleShowModal = (obj) => {
    let newObj = _.cloneDeep(obj);

    if (newObj && Object.keys(newObj).length) {
      if (newObj.phone_number) {
        newObj.phone_number = `${
          newObj.country_phone ? newObj.country_phone : ""
        }${newObj.phone_number}`;
      }

      if (newObj.country_phone) {
        newObj.country_phone = `+${newObj.country_phone}`;
      }

      setSelectedData(newObj);
    }

    setShowModal(true);
  };

  const handleShowEmergencyContactModal = () => {
    const isLimitExceeded = compareLimits(functionTypes.EMERGENCY_CONTACT);
    if (isLimitExceeded)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    setShowModal(true);
  };

  const handleSaveShowModal = async (newData) => {
    setLoading(true);

    let payload = _.cloneDeep(newData);

    handlePhoneNumber(payload);

    if (payload.image && typeof payload.image == "object") {
      const { status, message, data } = await uploadImage(
        "emergency_contact",
        payload.image,
        loggedUser?.token
      );

      if (status) {
        payload.image = data;
      } else {
        showToast(status, message);
        setLoading(false);
        return;
      }
    }

    let result = {};
    if (selectedData.id) {
      result = await put(
        EMERGENCY_CONTACT + `/${selectedData.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (teamId && userId) {
        payload.team_id = teamId;
        payload.user_id = userId;
      }
      if (team_id && user_id) {
        payload.team_id = team_id;
        payload.user_id = user_id;
      }
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        EMERGENCY_CONTACT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      setLoading(false);

      let emergencyContactsArr = _.cloneDeep(emergencyContacts);

      // On Update
      if (!selectedData.id) {
        updateCount(functionTypes.EMERGENCY_CONTACT, functionTypes.ADD);
      }
      if (selectedData.id) {
        const index = emergencyContacts.findIndex(
          (el) => el.id == selectedData.id
        );
        emergencyContactsArr[index] = data;
      } else {
        emergencyContactsArr.unshift(data);
      }

      setEmergencyContacts(emergencyContactsArr);
      handleCloseModal();
    } else {
      setLoading(false);
    }
    showToast(status, message);
  };

  const handleCloseModal = () => {
    setSelectedData(emptyEmergencyContactData);
    setShowModal(false);
  };

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);

    setLoading(true);
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }
    let { status, message } = await deleteRecord(
      `${EMERGENCY_CONTACT}/${deleteId}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    showToast(status, message);

    if (status) {
      await getEmergencyContacts();
      updateCount(functionTypes.EMERGENCY_CONTACT, functionTypes.DELETE);
      setLoading(false);
    }

    setLoading(false);
  };

  // Get all custom urls
  const getEmergencyContacts = async () => {
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }

    if (userId || user_id) {
      queryParams += `${queryParams ? "&" : "?"}user_id=${userId || user_id}`;
    }

    let { status, message, data } = await get(
      `${EMERGENCY_CONTACT}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setEmergencyContacts(data);
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEmergencyContacts();
  }, [userTeamId]);

  const filteredItems = useMemo(() => {
    const filtered = emergencyContacts.filter(
      (item) =>
        `${item.first_name || ""} ${item.last_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.country_phone ? `+${item.country_phone}` : ""}${
          item.phone_number || ""
        }`.includes(filterText)
    );

    return filtered;
  }, [filterText, emergencyContacts]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className={classes.mainWrapper}>
            <h6 className={classes.title}>
              <Link onClick={handleGoBack} style={{ color: "#ffffff" }}>
                Functions
              </Link>
              {` > Emergency Contacts`}
            </h6>

            {emergencyContacts.length !== 0 ? (
              <FormInput
                icon={searchIcon}
                placeholder="Search..."
                value={filterText}
                handleChange={handleFilter}
              />
            ) : null}
          </div>

          {emergencyContacts.length === 0 ? (
            <EmptyListingBanner
              headLineText="emergency contacts"
              title="Add Contact"
              onClick={handleShowEmergencyContactModal}
              showAdd={
                loggedUser.role.includes("customer") ||
                loggedUser.role.includes("team_owner")
              }
            />
          ) : (
            <div className={classes.customURLSWrapper}>
              <div>
                <div className={classes.addWrapper}>
                  {(Object.keys(loggedUser).length !== 0 &&
                    loggedUser.role.includes("customer")) ||
                  loggedUser.role.includes("team_owner") ? (
                    <span onClick={handleShowEmergencyContactModal}>
                      <div className={classes.addContentWrapper}>
                        <img src={addcontact} alt="" />
                        <p>Add New</p>
                      </div>
                    </span>
                  ) : null}
                </div>
                <ShowEmergencyFunctions
                  loading={loading}
                  show={showModal}
                  emergencyContacts={filteredItems}
                  handleShowModal={handleShowModal}
                  handleCloseModal={handleCloseModal}
                  handleDelete={handleDelete}
                  teamId={teamId}
                  userId={userId}
                  team_id={team_id}
                  user_id={user_id}
                />
              </div>
            </div>
          )}

          {/* Emergency Contact modal */}
          {showModal && (
            <EmergencyAddEditModal
              show={showModal}
              data={selectedData}
              setSelectedData={setSelectedData}
              handleSaveShowModal={handleSaveShowModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div>
      )}

      {/* confirmation modal */}

      <ConfirmationModal
        show={showConfirmationModal}
        handleCloseModal={handleCloseConfirmationModal}
        handleConfirmDelete={handleConfirmDelete}
        confirmTitle="emergency contact"
      />
    </>
  );
};

export default EmergencyContactsListing;
