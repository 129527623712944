const Joi = require("joi");

const TeamDetailSchema = Joi.object({
  id: Joi.allow(null),

  name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter team name`,
    "string.min": `Team name must be minimum 1 characters!`,
    "string.max": `Team name must be maximum 25 characters!`,
  }),
});

export default TeamDetailSchema;
