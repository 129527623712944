import React, { useEffect, useState, useMemo } from "react";
import classes from "../../assets/styles/Pages/Settings/Pages.module.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { get, post, deleteRecord } from "../../server";
import { PAGES } from "../../utils/constants/apiUrl";
import { loggedInUser } from "../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../components/Loader";
import showToast from "../../helpers/showToast";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import FormInput from "../../components/Forms/FormInput";
import searchIcon from "../../assets/images/global_icons/searchicon.svg";
import DataTable, { createTheme } from "react-data-table-component";

const Pages = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filterText, setFilterText] = useState("");

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${PAGES}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await loadPages();
      setLoading(false);
    }
    setLoading(false);
  };

  const loadPages = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      PAGES,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0) setPages(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPages();
  }, []);

  const filteredItems = useMemo(() => {
    const filtered = pages.filter(
      (item) =>
        `${item.title || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.description || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, pages]);
  createTheme(
    "solarized",
    {
      background: {
        default: "background: #21293B",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Page Title",
      width: "30%",
      cell: (row) => <p className="globalDetail">{row.title}</p>,
    },
    {
      name: "Description",
      width: "50%",
      cell: (row) => <div className={"globalDetail"}>{row?.description}</div>,
    },

    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.actionsWrapper}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical style={{ color: "#535F79" }} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link
                className="dropdown-item"
                to={`/pages/addnewpage/${row.id}`}
              >
                Edit
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Pages <span>({pages.length})</span>
              </p>

              <div className="d-flex align-items-center justify-content-between gap-2">
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />

                <Link to="/pages/addnewpage">
                  <button className="primary_button">Add</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="listingWrapper mainWrapper">
            <DataTable
              columns={columns}
              data={filteredItems}
              theme="solarized"
              responsive
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}

      {/* confirmation modal */}

      <ConfirmationModal
        show={showConfirmationModal}
        handleCloseModal={handleCloseConfirmationModal}
        handleConfirmDelete={handleConfirmDelete}
        confirmTitle="page"
      />
    </>
  );
};

export default Pages;
