import React from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import classes from "../../assets/styles/QRCodeModal.module.css";
import { GiShare } from "react-icons/gi";
import { QRCode } from "react-qrcode-logo";
import qrCodeBorder from "../../assets/images/qr-code-border.svg";
import { SHARE_QR } from "../../utils/constants/common";

const QRCodeModal = ({
  target,
  qrOpen,
  handleQRClose,
  setShowCopyPopup,
  handleDownload,
  qrCodeId,
  functionType,
  functionUuid,
  url,
}) => {
  return (
    <>
      <Modal
        show={qrOpen}
        onHide={handleQRClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <div className="modalGradientCircle"></div>
          <MdClose onClick={handleQRClose} className="modalCloseBtn" />
        </Modal.Header>

        <Modal.Body
          style={{ padding: "0" }}
          className={`d-flex justify-content-center flex-column align-items-center`}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ width: "fit-content", marginTop: "-120px" }}
          >
            <div className={classes.qrWrapper}>
              <img src={qrCodeBorder} />

              <QRCode
                value={url ? url : `${SHARE_QR}${functionType}/${functionUuid}`}
                id={qrCodeId ? qrCodeId : "qr-code"}
              />
            </div>
            <h5 style={{ margin: "10px" }}>QR Code</h5>
            <p
              style={{
                lineHeight: "18px",
                marginBottom: "5px",
                textAlign: "center",
              }}
            >
              Share or Download Your
              <br />
              QR Code
            </p>

            <button
              className={classes.shareBtn}
              ref={target}
              onClick={() => {
                setShowCopyPopup(true);
                setTimeout(() => {
                  setShowCopyPopup(false);
                }, 2000);
                navigator.clipboard.writeText(
                  url ? url : `${SHARE_QR}${functionType}/${functionUuid}`
                );
              }}
            >
              <GiShare />
              Share
            </button>
          </div>
          <div className={`customModalBtnWrapper ${classes.btnWrapper}`}>
            <button onClick={handleDownload}>Download QR</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QRCodeModal;
