const Joi = require("joi");
const AddEditPriceSchema = Joi.object({
  id: Joi.allow(null),
  title: Joi.string().min(3).max(50).required().messages({
    "string.empty": `Please enter title`,
    "string.min": `Title must be minimum 3 characters!`,
    "string.max": `Title must be maximum 50 characters!`,
  }),
  description: Joi.string().min(5).required().messages({
    "string.empty": `Please enter description`,
    "string.min": `Description must be minimum 5 characters!`,
  }),
});

export default AddEditPriceSchema;
