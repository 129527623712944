const Joi = require("joi");

const AddEditCardSchema = Joi.object({
  first_name: Joi.string().max(25).trim().required().messages({
    "string.empty": `Please enter your first name`,
    "string.max": `First name can be max 25 characters long`,
  }),

  last_name: Joi.string().max(25).allow("").messages({
    "string.max": `Last name can be max 25 characters long`,
  }),

  email: Joi.string()
    .email({ tlds: { allow: false } })
    .max(50)
    .allow("")
    .messages({
      "string.email": `Please enter valid email`,
      "string.max": `Email can be max 50 characters long`,
    }),

  city: Joi.string().max(50).allow("").messages({
    "string.max": `City can be max 50 characters long`,
  }),

  country: Joi.string().max(50).allow("").messages({
    "string.max": `Country can be max 50 characters long`,
  }),

  website_url: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),

  // phone_number: Joi.string().min(8).allow("").messages({
  //   "string.max": `Phone number can be max 13 characters long`,
  //   "string.min": `Phone number must be 8 characters long`,
  // }),
  // phone_number: Joi.string()
  //   .pattern(
  //     new RegExp(
  //       /^(?!\b(0)\1+\b)(\+?\d{1,3}[. -]?)?\(?\d{3}\)?([. -]?)\d{3}\3\d{4}$/
  //     )
  //   )
  //   .allow("")
  //   .messages({
  //     "string.empty": `Please enter your phone number`,
  //     "string.pattern.base": `Please enter a valid phone number`,
  //   }),

  company: Joi.string().max(50).allow("").messages({
    "string.max": `Company can be max 50 characters long`,
  }),

  company_position: Joi.string().max(50).allow("").messages({
    "string.max": `Company position can be max 50 characters long`,
  }),
});

export default AddEditCardSchema;
