import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SecondLoader from "../SecondLoader";
import AddEmergencyContactSchema from "../../utils/SchemasValidation/AddEmergencyContactSchema";
import ValidateSchema from "../../helpers/ValidateSchema";
import classes from "../../assets/styles/CustomURLModal.module.css";
import { MdClose } from "react-icons/md";
import FormInput from "../../components/Forms/FormInput";
import PhoneInput from "react-phone-input-2";
import ImageUpload from "../../components/ImageUpload";
// import pictureicon from "../../assets/images/function_page_icons/pictureicon.svg";
import pictureicon from "../../assets/images/function_page_icons/picIcon.svg";
import contacticon from "../../assets/images/function_page_icons/contacticon.svg";
import _ from "lodash";
import { validatePhoneWithCountryCode } from "../../utils/functions";
import showToast from "../../helpers/showToast";
import { EMERGENCY_CONTACT_VCF_FILE_URL } from "../../utils/constants/apiUrl";
import { get, put, post } from "../../server";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import { useAtom } from "jotai";

const EmergencyAddEditModal = ({
  loading,
  data,
  setSelectedData,
  handleSaveShowModal,
  handleCloseModal,
  show,
  publicMode,
}) => {
  const [errors, setErrors] = useState({});
  const [imagePath, setImagePath] = useState("");
  const [emergencyContact, setEmergencyContact] = useState({});
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);

  useEffect(() => {
    if (publicMode && !data.isNew) {
      setEmergencyContact(data);
    }
  }, [publicMode, data]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSelectedData({ ...data, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(data, AddEmergencyContactSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;

    // Check if phone number is empty
    if (!data.phone_number) {
      setErrors({ ...errors, phone_number: "Phone number is required" });
      return false;
    }
    if (
      data.phone_number.length > data.country_phone.length ||
      data.phone_number.length === data.country_phone.length
    ) {
      let resultPhone = validatePhoneWithCountryCode(
        data.country_phone,
        data.phone_number
      );
      if (resultPhone) {
        setErrors({
          phone_number: resultPhone,
        });
        return false;
      }
    }
    return true;
  };

  const handleAddUrl = async (e) => {
    if (!validateForm()) return;

    let newData = _.cloneDeep(data);
    newData.image = imagePath;
    setSelectedData(newData);
    handleSaveShowModal(newData);
  };

  useEffect(() => {
    setImagePath(data?.image);
  }, [data.image]);

  const handleVcfFile = async () => {
    // setLoading(true);
    let { status, message, data } = await post(
      EMERGENCY_CONTACT_VCF_FILE_URL + `/${emergencyContact.uuid}`,
      emergencyContact,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      const link = document.createElement("a");
      link.download = "temp.vcf";
      link.href = data.url;
      link.click();
    } else {
      showToast(status, message);
    }
    // setLoading(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setImagePath("");
          handleCloseModal();
          setErrors({});
          setEmergencyContact({});
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>
            {publicMode ? "View" : data?.isNew ? "Add" : "Edit "} Emergency
            Contact
          </span>
          <div className="modalGradientCircle"></div>
          <MdClose
            onClick={() => {
              setImagePath("");
              handleCloseModal();
              setErrors({});
              setEmergencyContact({});
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>
        {loading ? (
          <SecondLoader />
        ) : (
          <>
            <Modal.Body>
              <div style={{ marginTop: "-140px" }}>
                <div className="d-flex align-items-center justify-content-center w-100 mb-3">
                  <ImageUpload
                    imageLink={true}
                    imageUrl={data.image}
                    imageType="emergency_contact"
                    setPath={setImagePath}
                    width="120px"
                    height="120px"
                    dummyImage={pictureicon}
                  />
                </div>

                <FormInput
                  type="text"
                  label="First Name"
                  placeholder="Enter first name"
                  name="first_name"
                  value={data.first_name}
                  onChange={handleChange}
                  error={errors?.first_name}
                  readOnly={publicMode ? true : false}
                />

                <FormInput
                  type="text"
                  label="Last Name"
                  placeholder="Enter last name"
                  name="last_name"
                  value={data.last_name}
                  onChange={handleChange}
                  error={errors?.last_name}
                  readOnly={publicMode ? true : false}
                />

                <label className={classes.label}>Phone Number</label>

                {publicMode ? (
                  <a
                    href={`tel: ${`+${data.country_phone}${data.phone_number}`}`}
                  >
                    <PhoneInput
                      country={"us"}
                      defaultCountry="us"
                      value={`${data.country_phone} ${data.phone_number}`}
                      inputStyle={{
                        border: `2px solid #333e56`,
                        cursor: "pointer !important",
                      }}
                      inputClass="phoneNumberInput"
                      inputProps={{ readOnly: true }}
                      disableDropdown={true}
                      onChange={(value, country, e, formattedValue) => {
                        setSelectedData({
                          ...data,
                          phone_number: value,
                          country_phone: `+${country.dialCode}`,
                        });
                      }}
                    />
                  </a>
                ) : (
                  <PhoneInput
                    country={"us"}
                    enableSearch={true}
                    countryCodeEditable={false}
                    inputClass={
                      errors.phone_number
                        ? "phoneNumberInput errorInput"
                        : "phoneNumberInput"
                    }
                    value={data.phone_number}
                    onChange={(value, country, e, formattedValue) => {
                      setSelectedData({
                        ...data,
                        phone_number: value,
                        country_phone: `+${country.dialCode}`,
                      });
                    }}
                  />
                )}

                <p className="errorText">{errors?.phone_number}</p>

                <label className={classes.label}>Description</label>
                <textarea
                  name="description"
                  id=""
                  readOnly={publicMode ? true : false}
                  cols="100"
                  rows="5"
                  value={data.description}
                  placeholder="Enter Description"
                  onChange={handleChange}
                  className={
                    errors?.description
                      ? "globalTextArea errorInput"
                      : "globalTextArea"
                  }
                />
                <p className="errorText">{errors?.description || ""}</p>
              </div>
            </Modal.Body>

            <Modal.Footer
              style={{ justifyContent: publicMode ? "center" : "flex-end" }}
            >
              {publicMode ? (
                <div
                  className={`text-center ${classes.savePhonebookBtnWrapper}`}
                >
                  <button onClick={handleVcfFile}>
                    <span>
                      <img src={contacticon} alt="" />
                    </span>
                    Save Contact
                  </button>
                </div>
              ) : (
                <div className={classes.btnWrapper}>
                  <Button
                    type="button"
                    onClick={() => {
                      setImagePath("");
                      handleCloseModal();
                      setErrors({});
                    }}
                    className={classes.cancelBtn}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="button"
                    onClick={handleAddUrl}
                    className={classes.saveBtn}
                  >
                    Save
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default EmergencyAddEditModal;
