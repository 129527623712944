const Joi = require("joi");

const ProfileSchema = Joi.object({
  first_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter your first name`,
    "string.max": `First name must be maximum 25 characters!`,
  }),

  last_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter your last name`,
    "string.max": `Last name must be maximum 25 characters!`,
  }),

  email: Joi.string()
    .email({ tlds: { allow: false } })
    .max(50)
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),
  // phone_number: Joi.string()
  //   .pattern(
  //     new RegExp(
  //       /^(?!\b(0)\1+\b)(\+?\d{1,3}[. -]?)?\(?\d{3}\)?([. -]?)\d{3}\3\d{4}$/
  //     )
  //   )
  //   .allow("")
  //   .allow("1")
  //   .messages({
  //     "string.empty": `Please enter your phone number`,
  //     "string.pattern.base": `Please enter a valid phone number`,
  //   }),

  // phone_number: Joi.string().min(8).max(13).required().messages({
  //   "string.empty": `Please enter your phone number`,
  //   "string.min": `Phone number must be 8 characters!`,
  //   "string.max": `Phone number must be maximum 13 characters`,
  // }),

  country: Joi.string().max(50).allow("").allow(null).messages({
    "string.empty": `Please enter your country`,
    "string.max": `Country must be maximum 50 characters`,
  }),

  city: Joi.string().max(50).allow("").allow(null).messages({
    "string.empty": `Please enter your city`,
    "string.max": `City must be maximum 50 characters!`,
  }),

  dob: Joi.date().allow("").allow(null).messages({
    "string.empty": `Please enter your date of birth`,
  }),

  gender: Joi.string().allow("").allow(null).messages({
    "string.empty": `Please enter your gender`,
  }),

  job_title: Joi.string().max(50).allow("").allow(null).messages({
    "string.empty": `Please enter your position`,
    "string.max": `Position must be maximum 50 characters!`,
  }),
});

export default ProfileSchema;
