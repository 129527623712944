import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import defaultImage from "../assets/images/not-image-found.png";
import classes from "../assets/styles/ImageUpload.module.css";
import camera from "../assets/images/camera.svg";
import { IMAGE_UPLOAD } from "../utils/constants/apiUrl";
import showToast from "../helpers/showToast";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import { post } from "../server";
import baseURL from "../utils/constants/baseUrl";
import CropImageModal from "./Modals/CropImageModal";

const ImageUpload = ({
  dummyImage,
  setPath,
  imageType,
  width,
  height,
  imageLink = false,
  imageUrl = "",
  enableClick = true,
}) => {
  const [imagePreview, setImagePreview] = useState(imageUrl);
  const [preview, setPreview] = useState(null);
  const [result, setResult] = useState(null);
  const [fileData, setFileData] = useState("");
  const [crop, setCrop] = useState();
  const [showModal, setShowModal] = useState(false);

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);

  const [isImageLink, setIsImageLink] = useState(imageLink);

  useEffect(() => {
    setImagePreview(imageUrl);
  }, [imageUrl]);

  useEffect(() => {
    setIsImageLink(imageLink);
  }, [imageLink]);

  const uploadImage = async (imageData) => {
    let formDataObj = new FormData();

    formDataObj.append("image_type", imageType);
    formDataObj.append("image", imageData);

    let { status, message, data } = await post(
      IMAGE_UPLOAD,
      formDataObj,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setPath(data);
    } else {
      showToast(status, message);
    }
  };

  const handleCloseModal = () => {
    setCrop();
    setShowModal(false);
  };

  const handleChange = (event, i) => {
    const selectedFile = event.target.files[0];
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

    if (selectedFile.size > maxSizeInBytes) {
      showToast(
        false,
        "The selected image size exceeds the maximum limit of 10MB."
      );
      return;
    }

    setImagePreview(selectedFile);
    setIsImageLink(false);
    setPath(selectedFile);

    if (selectedFile) {
      setShowModal(true);
      setPreview(URL.createObjectURL(selectedFile));
    }
  };

  useEffect(() => {
    if (result) {
      setPath(fileData);
    }
  }, [result]);

  return (
    <>
      {imagePreview ? (
        <div
          className={classes.inputImageWrapper}
          style={{
            width: width ? width : null,
            height: height ? height : null,
          }}
        >
          <Image
            className={`globalIMageFit ${classes.profileImage}`}
            src={
              typeof imagePreview != "object"
                ? `${baseURL.PUBLIC_URL}${imagePreview}`
                : result
                ? result
                : URL.createObjectURL(imagePreview)
            }
            width={width ? width : "120px"}
            height={height ? height : "120px"}
            roundedCircle={true}
          />
          {enableClick ? (
            <>
              <label htmlFor="profile" className={classes.cameraIcon}>
                <img src={camera} alt="" />
              </label>
              <input
                accept="image/*"
                type="file"
                id="profile"
                name="profile_image"
                style={{ display: "none" }}
                onChange={handleChange}
              />
            </>
          ) : null}
        </div>
      ) : (
        <div
          className={classes.imageWrapper}
          style={{
            width: width ? width : null,
            height: height ? height : null,
          }}
        >
          <Image
            className={classes.profileImage}
            src={dummyImage ? dummyImage : defaultImage}
            width={width ? width : "120px"}
            height={height ? height : "120px"}
            roundedCircle={true}
            onError={(e) => {
              e.currentTarget.src = dummyImage ? dummyImage : defaultImage;
            }}
          />
          {enableClick ? (
            <>
              <label htmlFor="profile" className={classes.cameraIcon}>
                <img src={camera} alt="" />
              </label>

              <input
                accept="image/*"
                type="file"
                id="profile"
                name="profile_image"
                style={{ display: "none" }}
                onChange={handleChange}
              />
            </>
          ) : null}
        </div>
      )}
      {preview && (
        <CropImageModal
          show={showModal}
          image={preview}
          handleCloseModal={handleCloseModal}
          result={result}
          setResult={setResult}
          setFileData={setFileData}
          crop={crop}
          setCrop={setCrop}
        />
      )}
    </>
  );
};

export default ImageUpload;
