import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PersonalCard from "../components/PageComponents/Home/PersonalCard";
import classes from "../assets/styles/Home.module.css";
import BusinessCard from "../components/PageComponents/Home/BusinessCard";
import AddNewCard from "../components/PageComponents/Home/AddNewCard";
import AddNewEmergencyContact from "../components/AddNewEmergencyContact";
import addcontact from "../assets/images/home_page_images/addcontact.svg";
import GetEmergencyContact from "../components/PageComponents/Home/GetEmergencyContact";
import ShowCustomURLS from "../components/PageComponents/Home/ShowCustomURLS";
import ShowFileUpload from "../components/PageComponents/Home/ShowFileUpload";
import ShowCashApp from "../components/PageComponents/Home/ShowCashApp";
import ShowVenmo from "../components/PageComponents/Home/ShowVenmo";
import ShowPayment from "../components/PageComponents/Home/ShowPayment";
import HeroSection from "../components/HeroSection";
import {
  Card_URL,
  EMERGENCY_CONTACT,
  PAYMENT,
  CUSTOME_URL,
  FILE_UPLOAD,
  HOMEDASHBOARD,
  APP_SETTING,
} from "../utils/constants/apiUrl";
import { get, deleteRecord, post, put } from "../server";
import { loggedInUser, globalAppSetting, teamIDCheck } from "../utils/store";
import { useAtom } from "jotai";
import Loader from "../components/Loader";
import showToast from "../helpers/showToast";
import ConfirmationModal from "../components/Modals/ConfirmationModal";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import _ from "lodash";
import CustomURLSAddEditModal from "../components/Modals/CustomURLSAddEditModal";
import CashAppAddEditModal from "../components/Modals/CashAppAddEditModal";
import VenmoAddEditModal from "../components/Modals/VenmoAddEditModal";
import FileUploadAddEditModal from "../components/Modals/FileUploadAddEditModal";
import { formatUrl } from "../utils/functions";
import uploadImage from "../helpers/uploadImage";
import EmptyListingBanner from "../components/EmptyListingBanner";
import handlePhoneNumber from "../helpers/handlePhoneNumber";
import PaymentAddEditModal from "../components/Modals/PaymentAddEditModal";
import useFunctionsCount from "../hooks/useFunctionsCount";
import functionTypes from "../utils/constants/functionTypes";

const emptyEmergencyContactData = {
  isNew: true,
  id: "",
  first_name: "",
  last_name: "",
  country_phone: "",
  phone_number: "",
  image: "",
  description: "",
};

const emptyCustomUrlData = {
  isNew: true,
  id: "",
  title: "",
  url_path: "",
};

const emptyPaymentData = {
  isNew: true,
  id: "",
  title: "",
  url_path: "",
  payment_type: "",
};

const emptyFilesData = {
  isNew: true,
  id: "",
  title: "",
  url_path: "",
};

const Home = () => {
  const [loggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [appSetting, setAppSetting] = useAtom(globalAppSetting);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [personalCards, setPersonalCards] = useState([]);
  const [businessCards, setBusinessCards] = useState([]);
  const [personalCardsCount, setPersonalCardsCount] = useState(0);
  const [businessCardsCount, setBusinessCardsCount] = useState(0);
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [customUrls, setCustomUrls] = useState([]);
  const [payment, setPayment] = useState([]);
  const [cashApps, setCashApps] = useState([]);
  const [venmos, setVenmos] = useState([]);
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cards, setCards] = useState([]);

  const [deleteId, setDeleteId] = useState(null);
  const [deleteType, setDeleteType] = useState(null);

  const [emergencyContactData, setEmergencyContactData] = useState(
    emptyEmergencyContactData
  );
  const [customUrlData, setCustomUrlData] = useState(emptyCustomUrlData);
  const [paymentData, setPaymentData] = useState(emptyPaymentData);
  const [paymentCount, setPaymentCount] = useState("");
  const [fileUploadData, setFileUploadData] = useState(emptyFilesData);

  const [showCustomUrlModal, setShowCustomUrlModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showEmergencyModal, setShowEmergencyModal] = useState(false);

  const { compareLimits, updateCount } = useFunctionsCount();

  const handleEmergencyOpenModal = () => {
    setShowEmergencyModal(true);
  };

  const handleCloseEmergencyModal = () => {
    setEmergencyContactData(emptyEmergencyContactData);
    setShowEmergencyModal(false);
  };

  const handlePaymentOpenModal = () => {
    const isLimitExceeded = compareLimits(functionTypes.PAYMENT);
    if (isLimitExceeded)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    setShowPaymentModal(true);
  };

  const handlePaymentCloseModal = () => {
    setPaymentData(emptyPaymentData);
    setShowPaymentModal(false);
  };
  const handleCustomUrlOpenModal = () => {
    const isLimitExceeded = compareLimits(functionTypes.CUSTOM_URL);
    if (isLimitExceeded)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    setShowCustomUrlModal(true);
  };

  const handleCustomUrlCloseModal = () => {
    setCustomUrlData(emptyCustomUrlData);
    setShowCustomUrlModal(false);
  };
  const handleFileUploadOpenModal = () => {
    const isLimitExceeded = compareLimits(functionTypes.FILE_UPLOAD);
    if (isLimitExceeded)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    setShowFileUploadModal(true);
  };

  const handleFileUploadCloseModal = () => {
    setFileUploadData(emptyFilesData);
    setShowFileUploadModal(false);
  };

  const handleDelete = (id, type) => {
    setDeleteId(id);

    setDeleteType(Card_URL);
    handleShowModal(true);
  };

  const handleEmergencyDelete = (id) => {
    setDeleteId(id);
    setDeleteType(EMERGENCY_CONTACT);
    handleShowModal(true);
  };

  const handleCustomDelete = (id) => {
    setDeleteId(id);
    setDeleteType(CUSTOME_URL);
    handleShowModal(true);
  };
  const handleFileUploadDelete = (id) => {
    setDeleteId(id);
    setDeleteType(FILE_UPLOAD);
    handleShowModal(true);
  };

  const handlePaymentDelete = (id) => {
    setDeleteId(id);
    setDeleteType(PAYMENT);
    handleShowModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseModal(true);
    setLoading(true);

    let { status, message } = await deleteRecord(
      `${deleteType}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (deleteType == Card_URL) {
        updateCount(functionTypes.CARD, functionTypes.DELETE);
      }
      if (deleteType == PAYMENT) {
        updateCount(functionTypes.PAYMENT, functionTypes.DELETE);
      }
      if (deleteType == CUSTOME_URL) {
        updateCount(functionTypes.CUSTOM_URL, functionTypes.DELETE);
      }
      if (deleteType == FILE_UPLOAD) {
        updateCount(functionTypes.FILE_UPLOAD, functionTypes.DELETE);
      }
      if (deleteType == EMERGENCY_CONTACT) {
        updateCount(functionTypes.EMERGENCY_CONTACT, functionTypes.DELETE);
      }

      await getHomeDashboardData();
      showToast(status, message);
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  const handlePersonalBusinessCard = (data) => {
    let personalCardsData = [];
    let businessCardsData = [];
    data.forEach((card) => {
      if (card.card_type === "personal") {
        personalCardsData.push(card);
      } else {
        businessCardsData.push(card);
      }

      setPersonalCards(personalCardsData);
      setBusinessCards(businessCardsData);
    });
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const getHomeDashboardData = async () => {
    setLoading(true);
    let queryParams = "";

    if (userTeamId) {
      queryParams = `?team_id=${userTeamId}`;
    }
    let { status, data, message } = await get(
      `${HOMEDASHBOARD}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (Object.keys(data).length > 0) {
        setPersonalCardsCount(data.personalCardCount);
        setBusinessCardsCount(data.businessCardCount);

        if (data.cards.length > 0) {
          setCards(data.cards);

          let personalCardsData = [];
          let businessCardsData = [];

          data.cards.forEach((card) => {
            if (card.card_type === "personal") {
              personalCardsData.push(card);
            } else {
              businessCardsData.push(card);
            }

            setPersonalCards(personalCardsData);
            setBusinessCards(businessCardsData);
          });
        } else {
          setCards([]);
          setPersonalCards([]);
          setBusinessCards([]);
        }

        setCustomUrls(data.customUrls);
        setEmergencyContacts(data.emergencyContacts);
        setPayment(data.onlyPayments);
        setPaymentCount(
          data.cashAppCount + data.customPaymentCount + data.venmoCount
        );
        setFiles(data.fileUploads);
      }
    } else {
      // error message
      showToast(status, message);
    }
    setLoading(false);
  };

  const handleSavePayment = async () => {
    setModalLoading(true);

    let payload = _.cloneDeep(paymentData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};

    if (payload.id) {
      result = await put(
        PAYMENT + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        PAYMENT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      if (!payload.id) {
        updateCount(functionTypes.PAYMENT, functionTypes.ADD);
      }
      await getHomeDashboardData();
      handleCloseModal();
    }

    showToast(status, message);
    setModalLoading(false);
  };

  const handleSaveCustomUrl = async () => {
    setModalLoading(true);
    let payload = _.cloneDeep(customUrlData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};

    if (payload.id) {
      result = await put(
        CUSTOME_URL + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        CUSTOME_URL,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      if (!payload.id) {
        updateCount(functionTypes.CUSTOM_URL, functionTypes.ADD);
      }
      await getHomeDashboardData();
      handleCloseModal();
    }

    showToast(status, message);
    setModalLoading(false);
  };
  const handleSaveFileUpload = async () => {
    setModalLoading(true);

    let payload = _.cloneDeep(fileUploadData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};

    if (payload.id) {
      result = await put(
        FILE_UPLOAD + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        FILE_UPLOAD,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      if (!payload.id) {
        updateCount(functionTypes.FILE_UPLOAD, functionTypes.ADD);
      }
      await getHomeDashboardData();
      handleCloseModal();
    }

    showToast(status, message);
    setModalLoading(false);
  };

  const handleSaveEmergencyContact = async (newData) => {
    setModalLoading(true);
    let payload = _.cloneDeep(newData);
    handlePhoneNumber(payload);

    if (typeof payload.image == "object" && payload.image) {
      const { status, message, data } = await uploadImage(
        "emergency_contact",
        payload.image,
        loggedUser?.token
      );

      if (status) {
        payload.image = data;
      } else {
        setModalLoading(false);
        showToast(status, message);
        return;
      }
    }

    let result = {};

    if (payload.id) {
      result = await put(
        EMERGENCY_CONTACT + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        EMERGENCY_CONTACT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      if (!payload.id) {
        updateCount(functionTypes.EMERGENCY_CONTACT, functionTypes.ADD);
      }
      handleCloseEmergencyModal();
      await getHomeDashboardData();
      setEmergencyContactData(emptyEmergencyContactData);
    } else {
      setModalLoading(false);
    }
    showToast(status, message);
    setModalLoading(false);
    setLoading(false);
  };

  const handlePersonalScrollLeft = () => {
    document.getElementById("personalCardWrapper").scrollLeft -= 220;
  };

  const handlePersonalScrollRight = () => {
    document.getElementById("personalCardWrapper").scrollLeft += 220;
  };

  const handleBusinessScrollLeft = () => {
    document.getElementById("businessCardWrapper").scrollLeft -= 220;
  };

  const handleBusinessScrollRight = () => {
    document.getElementById("businessCardWrapper").scrollLeft += 220;
  };

  const getAppSetting = async () => {
    let { status, data, message } = await get(
      APP_SETTING,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (Object.keys(data).length > 0) setAppSetting(data[0]);
    } else {
      showToast(status, message);
    }
  };

  useEffect(() => {
    getHomeDashboardData();
    getAppSetting();
  }, [userTeamId]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={`${classes.mainWrapper}`}>
            <HeroSection />
            {loggedUser?.role?.includes("customer") && (
              <>
                {cards.length === 0 &&
                emergencyContacts.length === 0 &&
                customUrls.length === 0 &&
                venmos.length === 0 &&
                files.length === 0 &&
                payment.length === 0 ? (
                  <EmptyListingBanner
                    headLineText="function"
                    title="Create Function"
                    link="/function"
                  />
                ) : (
                  <>
                    {/* Cards Section */}
                    <div className={`${classes.cardsMainWrapper}`}>
                      <h6 className={classes.title}>Cards</h6>
                      <div className={classes.cardsWrapper}>
                        <div className={classes.linkViewAllWrapper}>
                          <p>Personal Card ({personalCardsCount})</p>
                          <div>
                            <Link
                              to="/function/cards"
                              className={classes.linkViewAll}
                            >
                              View All Cards
                            </Link>
                            <FiChevronLeft
                              className={classes.arrowBtn}
                              onClick={handlePersonalScrollLeft}
                            />
                            <FiChevronRight
                              className={classes.arrowBtn}
                              onClick={handlePersonalScrollRight}
                            />
                          </div>
                        </div>
                        <div
                          className={classes.boxWrapper}
                          id="personalCardWrapper"
                        >
                          <AddNewCard />
                          {personalCards.map((card) => {
                            return (
                              <PersonalCard
                                key={`personalCard${card.id}`}
                                {...card}
                                handleDelete={handleDelete}
                                cardListing={personalCards}
                                setCardListing={setPersonalCards}
                              />
                            );
                          })}
                        </div>

                        <div className={classes.linkViewAllWrapper}>
                          <p className={classes.totalBusinessCardsTitle}>
                            Business Card ({businessCardsCount})
                          </p>
                          <div>
                            <FiChevronLeft
                              className={classes.arrowBtn}
                              onClick={handleBusinessScrollLeft}
                            />
                            <FiChevronRight
                              className={classes.arrowBtn}
                              onClick={handleBusinessScrollRight}
                            />
                          </div>
                        </div>

                        <div
                          className={classes.boxWrapper}
                          id="businessCardWrapper"
                        >
                          {businessCards.map((card) => {
                            return (
                              <BusinessCard
                                key={`businessCard${card.id}`}
                                {...card}
                                handleDelete={handleDelete}
                                cardListing={businessCards}
                                setCardListing={setBusinessCards}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    {/* Emergency Contact and Custom URL'S  Section*/}
                    <div className="row my-4 g-xl-3 gy-4 flex-xl-row flex-column">
                      <div className="col-xl-6 col-12">
                        <div className={classes.emergencyContactsWrapper}>
                          <h6 className={classes.title}>Emergency Contacts</h6>
                          <div className={classes.emergencyContentWrapper}>
                            <div className={classes.linkViewAllWrapper}>
                              <p>Contacts ({emergencyContacts.length})</p>

                              <Link
                                to="/function/emergencycontacts"
                                className={classes.linkViewAll}
                              >
                                view all Contacts
                              </Link>
                            </div>

                            <div style={{ marginTop: "20px" }}>
                              <AddNewEmergencyContact
                                loading={modalLoading}
                                show={showEmergencyModal}
                                emptyEmergencyContactData={
                                  emptyEmergencyContactData
                                }
                                handleSaveShowModal={handleSaveEmergencyContact}
                                selectedData={emergencyContactData}
                                setSelectedData={setEmergencyContactData}
                                handleOpenModel={handleEmergencyOpenModal}
                                handleCloseModal={handleCloseEmergencyModal}
                              />
                              <div
                                className={classes.emergencyContactCardsWrapper}
                              >
                                {emergencyContacts.map((contact) => {
                                  return (
                                    <GetEmergencyContact
                                      key={`emergencyContact${contact.id}`}
                                      {...contact}
                                      emptyEmergencyContactData={
                                        emptyEmergencyContactData
                                      }
                                      handleSaveShowModal={
                                        handleSaveEmergencyContact
                                      }
                                      selectedData={emergencyContactData}
                                      setSelectedData={setEmergencyContactData}
                                      handleEmergencyDelete={
                                        handleEmergencyDelete
                                      }
                                      loading={modalLoading}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-6 col-12">
                        <div className={classes.emergencyContactsWrapper}>
                          <h6 className={classes.title}>Custom URL’s</h6>
                          <div className={classes.emergencyContentWrapper}>
                            <div className={classes.linkViewAllWrapper}>
                              <p>Custom URL's ({customUrls.length})</p>
                              <Link
                                to="/function/customurlslisting"
                                className={classes.linkViewAll}
                              >
                                view all URL's
                              </Link>
                            </div>

                            <div>
                              <div
                                className={classes.addContentWrapper}
                                onClick={handleCustomUrlOpenModal}
                              >
                                <img src={addcontact} alt="" />
                                <p>Add New</p>
                              </div>
                              <div
                                className={classes.emergencyContactCardsWrapper}
                              >
                                {customUrls.map((custom) => {
                                  return (
                                    <ShowCustomURLS
                                      key={`custom${custom.id}`}
                                      {...custom}
                                      emptyCustomUrlData={emptyCustomUrlData}
                                      handleSaveShowModal={handleSaveCustomUrl}
                                      selectedData={customUrlData}
                                      setSelectedData={setCustomUrlData}
                                      handleCustomDelete={handleCustomDelete}
                                      loading={modalLoading}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*Payment, File Upload*/}
                    <div className="row my-4 g-xl-3 gy-4 flex-xl-row flex-column">
                      <div className="col-xl-6 col-12">
                        <div className={classes.emergencyContactsWrapper}>
                          <h6 className={classes.title}>Payment Services</h6>
                          <div className={classes.emergencyContentWrapper}>
                            <div className={classes.linkViewAllWrapper}>
                              <p>Payments Services ({paymentCount})</p>

                              <Link
                                to="/function/paymentListing"
                                className={classes.linkViewAll}
                              >
                                view all payment services
                              </Link>
                            </div>

                            <div>
                              <div
                                className={classes.addContentWrapper}
                                onClick={handlePaymentOpenModal}
                              >
                                <img src={addcontact} alt="" />
                                <p>Add New</p>
                              </div>

                              <div
                                className={classes.emergencyContactCardsWrapper}
                              >
                                {payment.map((single) => {
                                  return (
                                    // <ShowCashApp
                                    //   key={`cash${single.id}`}
                                    //   {...single}
                                    //   emptyCashAppData={emptyCashAppData}
                                    //   handleSaveShowModal={handleSaveCashApp}
                                    //   selectedData={cashAppData}
                                    //   setSelectedData={setCashAppData}
                                    //   handleCashAppDelete={handleCashAppDelete}
                                    // />
                                    <ShowPayment
                                      key={`payment${single.id}`}
                                      {...single}
                                      emptyPaymentData={emptyPaymentData}
                                      handleSaveShowModal={handleSavePayment}
                                      selectedData={paymentData}
                                      setSelectedData={setPaymentData}
                                      handlePaymentDelete={handlePaymentDelete}
                                      loading={modalLoading}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-6 col-12">
                        <div className={classes.emergencyContactsWrapper}>
                          <h6 className={classes.title}>File Upload URL</h6>
                          <div className={classes.emergencyContentWrapper}>
                            <div className={classes.linkViewAllWrapper}>
                              <p>File Upload URL ({files.length})</p>
                              <Link
                                to="/function/fileupload"
                                className={classes.linkViewAll}
                              >
                                view File Upload
                              </Link>
                            </div>

                            <div>
                              <div
                                className={classes.addContentWrapper}
                                onClick={handleFileUploadOpenModal}
                              >
                                <img src={addcontact} alt="" />
                                <p>Add New</p>
                              </div>
                              <div
                                className={classes.emergencyContactCardsWrapper}
                              >
                                {files.map((file) => {
                                  return (
                                    <ShowFileUpload
                                      key={`custom${file.id}`}
                                      {...file}
                                      emptyFilesData={emptyFilesData}
                                      handleSaveShowModal={handleSaveFileUpload}
                                      selectedData={fileUploadData}
                                      setSelectedData={setFileUploadData}
                                      handleFileUploadDelete={
                                        handleFileUploadDelete
                                      }
                                      loading={modalLoading}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          <CustomURLSAddEditModal
            loading={modalLoading}
            show={showCustomUrlModal}
            data={customUrlData}
            setSelectedData={setCustomUrlData}
            handleSaveShowModal={async () => {
              await handleSaveCustomUrl();
              handleCustomUrlCloseModal();
            }}
            handleCloseModal={handleCustomUrlCloseModal}
          />
          <FileUploadAddEditModal
            loading={modalLoading}
            show={showFileUploadModal}
            data={fileUploadData}
            setSelectedData={setFileUploadData}
            handleSaveShowModal={async () => {
              await handleSaveFileUpload();
              handleFileUploadCloseModal();
            }}
            handleCloseModal={handleFileUploadCloseModal}
          />

          <PaymentAddEditModal
            loading={modalLoading}
            show={showPaymentModal}
            data={paymentData}
            setSelectedData={setPaymentData}
            handleSaveShowModal={async () => {
              await handleSavePayment();
              handlePaymentCloseModal();
            }}
            handleCloseModal={handlePaymentCloseModal}
          />

          <ConfirmationModal
            show={showModal}
            handleCloseModal={handleCloseModal}
            handleConfirmDelete={handleConfirmDelete}
          />
        </>
      )}
    </>
  );
};

export default Home;
