import React, { useState, useEffect, useRef } from "react";
import classes from "../../assets/styles/Pages/Function/ViewEmergencyContact.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import headingicon from "../../assets/images/function_page_icons/headingicon.svg";
import contacticon from "../../assets/images/function_page_icons/contacticon.svg";
import Loader from "../../components/Loader";
import Image from "react-bootstrap/Image";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import html2canvas from "html2canvas";
import { GiShare } from "react-icons/gi";
import { QRCode } from "react-qrcode-logo";
import { MdClose } from "react-icons/md";
import { Modal } from "react-bootstrap";
import qrCodeBorder from "../../assets/images/qr-code-border.svg";

import {
  EMERGENCY_CONTACT,
  USER_EMERGENCY_CONTACT,
  EMERGENCY_CONTACT_VCF_FILE_URL,
} from "../../utils/constants/apiUrl";
import { get, put, post } from "../../server";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import baseURL from "../../utils/constants/baseUrl";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import FormInput from "../../components/Forms/FormInput";
import personicon from "../../assets/images/global_icons/personicon.svg";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";
import EmergencyAddEditModal from "../../components/Modals/EmergencyAddEditModal";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import showToast from "../../helpers/showToast";
import _ from "lodash";
import handlePhoneNumber from "../../helpers/handlePhoneNumber";
import uploadImage from "../../helpers/uploadImage";
import { mobileViewCheck } from "../../utils/store";
import QRCodeModal from "../../components/Modals/QRCodeModal";
import formatPhoneNumber from "../../helpers/formatPhoneNumber";

const ViewEmergencyContact = () => {
  const target = useRef(null);
  const navigate = useNavigate();
  const { id, uuid, teamId, userId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");

  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [modalLoading, setModalLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedData, setSelectedData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [mobileView] = useAtom(mobileViewCheck);
  const [emergencyContact, setEmergencyContact] = useState({});
  const [showFullText, setShowFullText] = useState(false);
  const displayText = showFullText
    ? emergencyContact?.description
    : emergencyContact?.description?.length > 60
    ? emergencyContact?.description?.slice(0, 60) + "..."
    : emergencyContact?.description;

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEmergencyContact({ ...emergencyContact, [name]: value });
  };

  const handleOpenModal = () => {
    let newObj = _.cloneDeep(emergencyContact);

    if (newObj && Object.keys(newObj).length) {
      if (newObj.phone_number) {
        newObj.phone_number = `${
          newObj.country_phone ? newObj.country_phone : ""
        }${newObj.phone_number}`;
      }

      if (newObj.country_phone) {
        newObj.country_phone = `+${newObj.country_phone}`;
      }
    }

    setSelectedData(newObj);
    setShowModal(true);
  };

  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handleCloseModal = () => {
    setSelectedData({});
    setShowModal(false);
  };

  const handleQrOpen = (uuid) => {
    setSelectedData(uuid);
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleSaveShowModal = async (newData) => {
    setLoading(true);

    let payload = _.cloneDeep(newData);

    handlePhoneNumber(payload);

    if (payload.image && typeof payload.image == "object") {
      const { status, message, data } = await uploadImage(
        "emergency_contact",
        payload.image,
        loggedUser?.token
      );

      if (status) {
        payload.image = data;
      } else {
        showToast(status, message);
        setLoading(false);
        return;
      }
    }

    let result = {};
    if (selectedData.id) {
      result = await put(
        EMERGENCY_CONTACT + `/${selectedData.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }
    let { status, message, data } = result;

    if (status) {
      setEmergencyContact(payload);
    }
    setLoading(false);
    handleCloseModal();
    showToast(status, message);
  };

  const loadEmergencyContact = async () => {
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }

    // if (userId || user_id) {
    //   queryParams += `${queryParams ? "&" : "?"}user_id=${userId || user_id}`;
    // }
    let { status, message, data } = await get(
      `${USER_EMERGENCY_CONTACT}/${id}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (Object.keys(data).length > 0) {
        let objData = data;
        objData = { ...objData, user_id: loggedUser.id };
        setEmergencyContact(objData);
      }
    } else {
      // error message
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadEmergencyContact();
  }, []);

  const handleVcfFile = async () => {
    // setLoading(true);

    let { status, message, data } = await post(
      EMERGENCY_CONTACT_VCF_FILE_URL + `/${emergencyContact.uuid}`,
      emergencyContact,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const link = document.createElement("a");
      link.download = "temp.vcf";
      link.href = data.url;
      link.click();
    } else {
      showToast(status, message);
    }
    // setLoading(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    let { status, message, data } = await put(
      EMERGENCY_CONTACT + `/${id}`,
      emergencyContact,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    // error message

    setLoading(false);
  };

  const handleGoBack = () => {
    navigate(-2);
    // if (teamId && userId) {
    //   navigate(-2);
    // } else {
    //   navigate("/function");
    // }
  };

  return (
    <>
      {!loading ? (
        <div className={classes.mainWrapper}>
          <h6 className={classes.title} style={{ textAlign: "left" }}>
            {teamId && userId ? (
              <Link onClick={handleGoBack} style={{ color: "#ffffff" }}>
                Functions
              </Link>
            ) : (
              <Link to="/function" style={{ color: "#ffffff" }}>
                Functions
              </Link>
            )}

            {` > Emergency Contact`}
          </h6>
          <div className={classes.cardWrapper}>
            <div
              className={classes.profileCardWrapper}
              // style={{ background: "#333e56" }}
            >
              <div className={classes.imgWrapper}>
                <Image
                  className="globalIMageFit"
                  src={
                    emergencyContact.image
                      ? `${baseURL.PUBLIC_URL}${emergencyContact.image}`
                      : dummyImage
                  }
                  alt="img"
                  roundedCircle={true}
                  loading="lazy"
                  decoding="async"
                />
              </div>
              <div className={classes.detailWrapper}>
                <h6>{`${emergencyContact.first_name || ""} ${
                  emergencyContact.last_name || ""
                }`}</h6>

                <p>{`+${emergencyContact.country_phone}${formatPhoneNumber(
                  emergencyContact.phone_number
                )}`}</p>
                <p className={classes.description}>
                  {displayText}

                  {emergencyContact?.description?.length > 60 && (
                    <button onClick={toggleText}>
                      {showFullText ? "Show Less" : "Read More"}
                    </button>
                  )}
                </p>
              </div>

              <div
                className={` text-center ${classes.savePhonebookBtnWrapper}`}
              >
                <a
                  href={`tel: ${`+${emergencyContact.country_phone}${emergencyContact.phone_number}`}`}
                >
                  <button>Call Now</button>
                </a>
                <button onClick={handleVcfFile}>
                  <span>
                    <img src={contacticon} alt="" />
                  </span>
                  Save Contact
                </button>
              </div>
              <div className={`mt-2  ${classes.savePhonebookBtnWrapper}`}>
                <div className="d-flex align-items-center justify-content-center gap-3">
                  <button onClick={handleOpenModal} className={classes.btn}>
                    Edit
                  </button>
                  <button
                    onClick={() => handleQrOpen(emergencyContact.uuid)}
                    className={classes.btn}
                  >
                    Download QR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      {showModal && (
        <EmergencyAddEditModal
          show={showModal}
          data={selectedData}
          setSelectedData={setSelectedData}
          handleSaveShowModal={handleSaveShowModal}
          handleCloseModal={handleCloseModal}
          loading={loading}
        />
      )}

      {qrOpen && (
        <QRCodeModal
          qrOpen={qrOpen}
          target={target}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleDownload}
          functionType="emergencyContact"
          functionUuid={selectedData}
        />
      )}

      <Overlay
        target={target.current}
        show={showCopyPopup}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default ViewEmergencyContact;
