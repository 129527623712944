import React from "react";
import classes from "../assets/styles/SecondLoader.module.css";
const SecondLoader = ({ shortModal }) => {
  return (
    <>
      <div
        className={`${classes.loaderWrapper}`}
        style={{
          marginBottom: shortModal ? "0px" : "100px",
          minHeight: shortModal && "200px",
        }}
      >
        <div className={classes.loader}></div>
      </div>
    </>
  );
};

export default SecondLoader;
