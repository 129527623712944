import React, { useState, useEffect } from "react";
import notificationsImage from "../assets/images/navbar_icons/notifications.svg";
import email from "../assets/images/navbar_icons/email.svg";
import classes from "../assets/styles/AllNotifications.module.css";
import { get } from "../server";
import { USER_NOTIFICATION } from "../utils/constants/apiUrl";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import showToast from "../helpers/showToast";
import { formatDate } from "../utils/functions";

const AllNotifications = ({ showNotification, setShowNotification }) => {
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [loggedUser] = useAtom(loggedInUser);

  useEffect(() => {
    const handler = () => setShowNotification(false);

    window.addEventListener("click", handler);

    return () => {
      window.removeEventListener("click", handler);
    };
  });

  useEffect(() => {
    loadNotification();
  }, []);

  const loadNotification = async () => {
    let { status, data, message } = await get(
      USER_NOTIFICATION,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (Object.keys(data).length > 0) setNotifications(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };
  return (
    <>
      <div
        className={classes.notificationsMainWrapper}
        onBlur={() => setShowNotification(false)}
      >
        <div className={classes.notificationHeader}>
          <p className={classes.title}>Notifications</p>
        </div>
        {loading ? (
          // Loader divs
          <div className={`${classes.loaderWrapper}`}>
            <div className={classes.loader}></div>
          </div>
        ) : (
          <>
            {notifications.length > 0 ? (
              <>
                {notifications.map((notification) => {
                  return (
                    <div
                      className={classes.contentWrapper}
                      onClick={(e) => e.stopPropagation()}
                      key={notification.id}
                    >
                      <p className={classes.date}>
                        {formatDate(new Date(notification.created_at))}
                      </p>
                      <div className={classes.notificationsWrapper}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img src={notificationsImage} alt="" />
                            <h6 className={classes.notificationTitle}>
                              {notification.title}
                            </h6>
                          </div>
                        </div>
                        <p className={classes.notificationDetail}>
                          {notification.description}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className={classes.noRecordFound}>
                <p>No Notifications Found </p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AllNotifications;
