const Joi = require("joi");

const AddEmergencyContactSchema = Joi.object({
  id: Joi.allow(null),

  first_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter first name`,
    "string.max": `First name must be maximum 25 characters!`,
  }),

  last_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter first name`,
    "string.max": `Last name must be maximum 25 characters!`,
  }),
  // phone_number: Joi.string()
  //   .pattern(
  //     new RegExp(
  //       /^(?!\b(0)\1+\b)(\+?\d{1,3}[. -]?)?\(?\d{3}\)?([. -]?)\d{3}\3\d{4}$/
  //     )
  //   )
  //   .required()
  //   .messages({
  //     "string.empty": `Please enter your phone number`,
  //     "string.pattern.base": `Please enter a valid phone number`,
  //   }),

  // phone_number: Joi.string().min(10).required().messages({
  //   "string.empty": `Please enter phone number`,
  //   "string.min": `Phone number must be 10 characters long`,
  // }),
});

export default AddEmergencyContactSchema;
