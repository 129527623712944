import React, { useState, useEffect, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { useAtom } from "jotai";
import classes from "../../../assets/styles/Contact.module.css";
import { PRICING, PACKAGE } from "../../../utils/constants/apiUrl";
import { get, deleteRecord } from "../../../server";
import showToast from "../../../helpers/showToast";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";

const AllPackageContacts = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [PackageContact, setPackageContact] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filterText, setFilterText] = useState("");

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const loadPackageContacts = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      `${PACKAGE}/allPackageContactForm`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0) {
        setPackageContact(data);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPackageContacts();
  }, []);

  const filteredItems = useMemo(() => {
    const filtered = PackageContact.filter(
      (item) =>
        `${item.title || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.description || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.email || ""}`.includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, PackageContact]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: #21293B",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Title",
      cell: (row) => (
        <div className="profileWrapper">
          <p className="globalDetail">{row.title}</p>
        </div>
      ),
    },
    {
      name: "Email",
      cell: (row) => <div className={"globalDetail"}>{row.email}</div>,
    },
    {
      name: "Description",
      cell: (row) => <div className={"globalDetail"}>{row.description}</div>,
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link className="dropdown-item" to={`/packageContacts/${row.id}`}>
                View Contact
              </Link>
            </li>
            {/* <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li> */}
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${PRICING}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await loadPackageContacts();
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Enterprise Package Contacts{" "}
                <span>({PackageContact.length})</span>
              </p>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />

                {/* <Link
                  to="/PackageContact/addEditPricing"
                  // style={{ marginLeft: "10px" }}
                >
                  <button className="primary_button">Add</button>
                </Link> */}
              </div>
            </div>
          </div>

          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              theme="solarized"
            />
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AllPackageContacts;
